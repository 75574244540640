import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { R_AddCcsInstance, R_CcsBook, R_CreateCcsProject, R_DeleteCcsInstance, R_DeleteCcsProject, R_EditCcsInjectivity, R_EditCcsProject, R_EditCcsStorage, R_EditCcsTagValue, R_GetCcsInjectivity, R_GetCcsProject, R_GetCcsProjectNames, R_GetCcsStorageResource, R_GetCcsStorageRisk, R_GetMapPopupInfo, R_GetSrmsBookings, R_editBookValue, R_CcsTransportShip, R_GetCcsOptions } from "api/ccs/service";
import { addNotification } from "store/notifications/slice";
import { T_CcsState, T_TH_AddCcsInstance, T_TH_AddPotentialCCSStorage, T_TH_CreateCcsProject, T_TH_DeleteCcsInstance, T_TH_DeleteCcsProject, T_TH_EditCcsInjectivity, T_TH_EditCcsProject, T_TH_EditCcsStorage, T_TH_EditCcsTagValue, T_TH_GetCcsInjectivity, T_TH_GetCcsProject, T_TH_GetCcsProjectNames, T_TH_GetCcsRiskStorage, T_TH_GetCcsStorageResource, T_TH_GetMapPopupInfo, T_TH_GetSrmsBookings } from "./types";
import { RootState } from "store/store";
import { ccsSlice } from "./slice";
import { handleRequestErrors } from "helpers/functions/commons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (err: AxiosError, { dispatch, rejectWithValue }: BaseThunkAPI<any, any, any, any>) => {
    if (!err.response) {
        throw err
    }
    const message = handleRequestErrors(err)
    dispatch(addNotification({ type: 'error', message }))
    return rejectWithValue(message)
}

export const TH_GetCcsProjectNames = createAsyncThunk<T_TH_GetCcsProjectNames['return'], T_TH_GetCcsProjectNames['args']>(
    'ccs/getProjectNames',
    async ({ workSpaceId, selectedId }, thunkAPI) => {
        try {
            const response = await R_GetCcsProjectNames({ workSpaceId })
            thunkAPI.dispatch(ccsSlice.actions.setCcsProjectNames(response))

            if (selectedId && response.projects.allIds.includes(selectedId)) {
                const projectInfo = await thunkAPI.dispatch(TH_GetCcsProject({
                    workSpaceId,
                    id: selectedId
                }))
                return { ...response, ...projectInfo }
            }
            return response
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_CreateCcsProject = createAsyncThunk<T_TH_CreateCcsProject['return'], T_TH_CreateCcsProject['args']>(
    'ccs/createCssProject',
    async (data, thunkAPI) => {
        try {
            const response = await R_CreateCcsProject(data)
            return response
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_EditCcsProject = createAsyncThunk<T_TH_EditCcsProject['return'], T_TH_EditCcsProject['args']>(
    'ccs/editCssProject',
    async (data, thunkAPI) => {
        try {
            const response = await R_EditCcsProject(data)
            return { ...response, ...data }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_DeleteCcsProject = createAsyncThunk<T_TH_DeleteCcsProject['return'], T_TH_DeleteCcsProject['args']>(
    'ccs/deleteCssProject',
    async (data, thunkAPI) => {
        try {
            await R_DeleteCcsProject(data)
            const { id } = data
            const { projects: { allIds, selectedId }, workSpaceId } = (thunkAPI.getState() as RootState).ccsSlice as T_CcsState

            if (selectedId !== id) return id

            const nextSelectedProjectId = allIds.length > 1 ? (allIds[allIds.indexOf(selectedId) + 1] ?? allIds[0]) : ''

            if (!nextSelectedProjectId) return id

            await thunkAPI.dispatch(TH_GetCcsProject({
                workSpaceId,
                id: nextSelectedProjectId
            }))

            return id
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_GetCcsProject = createAsyncThunk<T_TH_GetCcsProject['return'], T_TH_GetCcsProject['args']>(
    'ccs/getCssProject',
    async (data, thunkAPI) => {
        try {
            const response = await R_GetCcsProject(data)

            return { ...response, id: data.id }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_AddCcsInstance = createAsyncThunk<T_TH_AddCcsInstance['return'], T_TH_AddCcsInstance['args']>(
    'ccs/addCcsInstance',
    async (data, thunkAPI) => {
        try {
            const response = await R_AddCcsInstance(data)
            data.onSuccess?.()

            return {
                data: response,
                type: data.requestType
            }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_DeleteCcsInstance = createAsyncThunk<T_TH_DeleteCcsInstance['return'], T_TH_DeleteCcsInstance['args']>(
    'ccs/deleteCcsInstance',
    async (data, thunkAPI) => {
        try {
            const response = await R_DeleteCcsInstance(data)
            return {
                data: response,
                type: data.requestType
            }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_EditCcsTagValue = createAsyncThunk<T_TH_EditCcsTagValue['return'], T_TH_EditCcsTagValue['args']>(
    'ccs/editCcsSourceValue',
    async (data, thunkAPI) => {
        try {
            const response = await R_EditCcsTagValue(data)
            data.onSuccess?.()

            return {
                data: response,
                key: data.key,
                tagKey: data.tagKey,
                type: data.requestType
            }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)


export const TH_CcsBookValue = createAsyncThunk<T_TH_EditCcsTagValue['return'], T_TH_EditCcsTagValue['args']>(
    'ccs/editCcsBookValue',
    async (data, thunkAPI) => {
        try {
            const response = await R_CcsBook(data)
            data.onSuccess?.()

            return {
                data: response,
                key: data.key,
                tagKey: data.tagKey,
                type: data.requestType
            }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_CcsTransportShipValue = createAsyncThunk<T_TH_EditCcsTagValue['return'], T_TH_EditCcsTagValue['args'] & { transportViaShip: boolean }>(
    'ccs/editCcsTransportShipValue',
    async (data, thunkAPI) => {
        try {
            const response = await R_CcsTransportShip(data)
            data.onSuccess?.()

            return {
                data: response,
                key: data.key,
                tagKey: data.tagKey,
                type: data.requestType
            }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_GetStorageRiskTags = createAsyncThunk<T_TH_GetCcsRiskStorage['return'], T_TH_GetCcsRiskStorage['args']>(
    'ccs/getStorageRisks',
    async (data, thunkAPI) => {
        try {
            const response = await R_GetCcsStorageRisk(data)
            return {
                data: response,
            }
        } catch (err) {
            console.error(err);

            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

// export const TH_GetAllTypes = createAsyncThunk(
//     'ccs/allTags',
//     async () => {
//         const { data: response } = await api.get<ITagAttribute[]>('/TagAttribute/GetAll');
//         return response
//     }
// )

export const TH_GetCcsStorageResource = createAsyncThunk<T_TH_GetCcsStorageResource['return'], T_TH_GetCcsStorageResource['args']>(
    'ccs/getCcsStorageResource',
    async (data, thunkAPI) => {
        try {
            const response = await R_GetCcsStorageResource(data)
            return { ...response }
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_EditCcStorage = createAsyncThunk<T_TH_EditCcsStorage['return'], T_TH_EditCcsStorage["args"]>(
    "ccs/editCssStorageResource",
    async (data, thunkAPI) => {
        try {
            const response = await R_EditCcsStorage(data)
            return response
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    })

export const TH_getMapPopupInfo = createAsyncThunk<T_TH_GetMapPopupInfo['return'], T_TH_GetMapPopupInfo['args']>(
    'ccs/mapPopup',
    async (data, thunkAPI) => {
        const state = thunkAPI.getState()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const workspaceId = (state as any).workspaceSlice.workspaceId
        const response = await R_GetMapPopupInfo({ workspaceId: workspaceId, popupInfo: data })
        return response
    }
)


export const TH_GetSrmsBookings = createAsyncThunk<T_TH_GetSrmsBookings['return'], T_TH_GetSrmsBookings['args']>(
    'ccs/booking',
    async (data) => {
        const response = await R_GetSrmsBookings(data)
        return response
    }
)

export const TH_editBookingValue = createAsyncThunk(
    'ccs/editBookValue',
    async ({ workspaceId, ccsProjectId, data }: T_TH_GetSrmsBookings['args']) => {
        const response = await R_editBookValue({ workspaceId, ccsProjectId, data })
        return response
    }
)

export const TH_GetCcsInjectivity = createAsyncThunk<T_TH_GetCcsInjectivity['return'], T_TH_GetCcsInjectivity['args']>(
    'ccs/getCcsInjectivity',
    async (data, thunkAPI) => {
        try {
            const response = await R_GetCcsInjectivity(data)
            return response
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_EditCcInjectivity = createAsyncThunk<T_TH_EditCcsInjectivity['return'], T_TH_EditCcsInjectivity["args"]>(
    "ccs/editCssInjectivity",
    async (data, thunkAPI) => {
        try {
            const response = await R_EditCcsInjectivity(data)
            return response
        } catch (err) {
            return handleError(err as AxiosError, thunkAPI)
        }
    }
)

export const TH_GetCcsOptions = createAsyncThunk(
    'ccs/AddPotentialOptions',
    async (data: T_TH_AddPotentialCCSStorage['args'], thunkApi) => {
        try {
            const response = await R_GetCcsOptions(data)
            return response
        } catch (e) {
            return handleError(e as AxiosError, thunkApi)
        }
    }
)
