import { createSlice } from "@reduxjs/toolkit";
import { UserDataState } from "./types";
import { T_addDataset, T_deleteDataset, T_editDatasetValues, T_getDataset, T_getDatasetsList, T_importDataset, T_shareDataset, T_unshareDataset } from "./thunk";

const initialState: UserDataState = {
    datasetsList: [],
    loading: false,
    error: null,
    isFetched: false,
    isDatasetChanged: false
  };
  
  export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
      setIsDatasetChanged: (state, { payload }) => {
        state.isDatasetChanged = payload
      }
    },
    extraReducers: {
      [T_addDataset.pending.type]: (state) => {
        state.loading = true;
      },

      [T_addDataset.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_addDataset.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_getDatasetsList.pending.type]: (state) => {
        state.loading = true;
      },
      [T_getDatasetsList.fulfilled.type]: (state, action) => {
        state.loading = false;
        state.datasetsList = action.payload.datasets;
        
      },

      [T_getDatasetsList.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_getDataset.pending.type]: (state) => {
        state.loading = true;
      },

      [T_getDataset.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_getDataset.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_editDatasetValues.pending.type]: (state) => {
        state.loading = true;
      },

      [T_editDatasetValues.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_editDatasetValues.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_deleteDataset.pending.type]: (state) => {
        state.loading = true;
      },

      [T_deleteDataset.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_deleteDataset.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_unshareDataset.pending.type]: (state) => {
        state.loading = true;
      },

      [T_unshareDataset.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_unshareDataset.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_shareDataset.pending.type]: (state) => {
        state.loading = true;
      },

      [T_shareDataset.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_shareDataset.rejected.type]: (state) => {
        state.loading = false;
      },

      [T_importDataset.pending.type]: (state) => {
        state.loading = true;
      },

      [T_importDataset.fulfilled.type]: (state) => {
        state.loading = false;
      },

      [T_importDataset.rejected.type]: (state) => {
        state.loading = false;
      },

      
    }
})

export default userDataSlice.reducer