import { Portal } from 'components/Portal/Portal'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React from 'react'
import { workspaceSlice } from 'store/workspace/slice'


export default function NoPermissionPopup() {
    const dispatch = useAppDispatch()
    const restrictionPopup = useAppSelector(store=>store?.workspaceSlice?.restrictionPopup)

    const closePortal = () => {
        dispatch(workspaceSlice.actions.handleRestrictionPopup({open: false}))
    }

    return (
        <Portal
            opened={restrictionPopup?.open}
            closePortal={closePortal}
        >
            <div className={'portal'}>
                <div className='portal-header'>
                    <h4>{''}</h4>
                    <button className='close-btn' onClick={closePortal} />
                </div>
                <div className='portal-custom-content'>
                    <strong>{restrictionPopup?.message}</strong>
                </div>

            </div>
        </Portal>
    )
}