import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDatasets, getHomeMapData, getHomeNotifications, getLinkedInFollowers, getLinkedInPosts, getUsers, setNotificationsRead, setSharedUsers, unshareWorkspaceWithMe } from 'api/home/service';
import { CancelTokenSource } from 'axios';

export const getHomeMapDataThunk = createAsyncThunk('home/getHomeMapData', async (thunkAPI) => {
    const response = await getHomeMapData(thunkAPI);
    return response;
});

export const getHomeNotificationsThunk = createAsyncThunk('home/getHomeNotifications', async (data: {pageNumber: number, pageSize: number, isLast: boolean}, thunkAPI) => {
    const response = await getHomeNotifications(data, thunkAPI);
    return response;
});

export const setNotificationsReadThunk = createAsyncThunk('home/setNotificationsRead', async (notifications: string[], thunkAPI) => {
    const response = await setNotificationsRead(notifications, thunkAPI);
    return response;
});

export const getUsersThunk = createAsyncThunk('home/getUsers', async (thunkAPI) => {
    const response = await getUsers(thunkAPI);
    return response;
});

export const getDatasetsThunk = createAsyncThunk('home/getDatasetsThunk', async (thunkAPI) => {
    const response = await getDatasets(thunkAPI);
    return response;
});

export const setSharedUsersThunk = createAsyncThunk('home/setSharedUsers', async (data: {workspaceId: string, users: { sharedWith: string[]} }, thunkAPI) => {
    const response = await setSharedUsers(data, thunkAPI);
    return response;
});

export const unshareWorkspaceWithMeThunk = createAsyncThunk('home/unshareWorkspaceWithMe', async (workspaceId: string, thunkAPI) => {
    const response = await unshareWorkspaceWithMe(workspaceId, thunkAPI);
    return response;
});

export const getLinkedInPostsThunk = createAsyncThunk('home/getLinkedInPosts', async (data: {pageNumber: number, pageSize: number, isLast: boolean, source?: CancelTokenSource}, thunkAPI) => {
    const response = await getLinkedInPosts(data, thunkAPI);
    return response;
});

export const getLinkedInFollowersThunk = createAsyncThunk('home/getLinkedInFollowers', async (thunkAPI) => {
    const response = await getLinkedInFollowers(thunkAPI);
    return response;
});



