import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createUserThunk, getUserThunk, getAllUsersThunk, updateUserThunk, deleteUserThunk, blockUserThunk } from './thunk'
import { UserState } from './types';


const initialState: UserState = {
  users: [],
  isLoading: false,
  error: null,
  totalRecords: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: {
    [createUserThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [createUserThunk.fulfilled.type]: (state, action) => {
      action.meta.arg.id = action.payload;
      state.users = [action.meta.arg, ...state.users];
      state.isLoading = false;

    },

    [createUserThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getUserThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getUserThunk.fulfilled.type]: (state) => {
      state.isLoading = false;

    },

    [getUserThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getAllUsersThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getAllUsersThunk.fulfilled.type]: (state, action) => {
      state.users = action.payload.collection
      state.totalRecords = action.payload.totalRecords
      state.isLoading = false;

    },

    [getAllUsersThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateUserThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [updateUserThunk.fulfilled.type]: (state, action) => {
      const idx = state.users.findIndex(i => i.id === action.meta.arg.id);
      if (idx !== -1) {
        state.users[idx] = action.meta.arg;
      }
      state.isLoading = false;
    },

    [updateUserThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteUserThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [deleteUserThunk.fulfilled.type]: (state, action) => {
      const newArr = state.users.filter(i => i.id !== action.meta.arg);
      state.isLoading = false;
      state.users = newArr
      state.isLoading = false;
    },

    [deleteUserThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [blockUserThunk.fulfilled.type]: (state, action) => {
      const idx = state.users.findIndex(i => i.id === action.meta.arg.id);
      if (idx !== -1) {
        state.users[idx].isActive = !action.meta.arg.isActive;
      }
      state.isLoading = false;
    }
  },
})

export default userSlice.reducer