const AppConstants = { 
    roles: {
        ADM: 'ADM',
        CAD: 'CAD',
        ATU: 'ATU',
        REU: 'REU',
        USR: 'USR'
    },
    roleDropDown: [
        { text: 'Global Admin', value: 'Global Admin' },
        { text: 'Client Admin', value: 'Client Admin' },
        { text: 'User', value: 'User' }
    ],
    storage: {
        EMAIL: 'emailAddress_store',
        ROLE: 'role_store',
        REFRESH_TOKEN: 'refreshToken_store',
        ID: 'id_store',
        USER: 'user'
    }
    
}

export const ROUTES = {
    dashboard: '/projects/ccs/dashboard',
    storageResource: '/projects/ccs/storage-resource',
    injectivity: '/projects/ccs/injectivity',
    storageRisk: '/projects/ccs/storage-risk',
    booking: '/projects/ccs/booking',
    explore: '/Explore',
    exploreMap: '/Explore',
    home: '/home',
    data: '/data',
    admin: '/Admin',
}

export const USER_ACTION_TYPES = {
    dashboard: 'CCSProjectDashboardView',
    storageResource: 'CCSProjectStorageResourceView',
    injectivity: 'CCSProjectInjectivityView',
    storageRisk: 'CCSProjectStorageRiskView',
    booking: 'CCSProjectSRMSBookingView',
    explore: 'PlotView',
    exploreMap: 'MapView',
    home: 'HomepageView',
    data: 'DataView',
    admin: 'AdminView',
}
 
export const USER_ACTION_ENUM = [
    'MapView', 
    'PlotView', 
    'CCSProjectDashboardView', 
    'CCSProjectStorageResourceView', 
    'CCSProjectInjectivityView', 
    'CCSProjectStorageRiskView', 
    'CCSProjectSRMSBookingView', 
    'HomepageView', 
    'DataView', 
    'AdminView'
]

export enum SCREEN_RESOLUTIONS {
    'DESKTOP' = 1920,
    'LAPTOP' = 1336,
    'TABLET' = 1024
}

export const LICENSE_TYPES = {
    free: 1,
    global: 0
}


export default AppConstants