import { CCS_FIELD_INPUT_TYPES, CCS_INSTANCE_TYPES } from "helpers/constants/ccs";
import { normalizeByKey } from "helpers/functions/commons";
import { T_CcsOptionsDropdownItemFormat } from "helpers/types/ccs";
import { T_CcsInstance, T_CcsInstanceGroup, T_CcsState } from "store/ccs/types";
import { T_ResponseCcsInjectivity, T_ResponseCcsInstance, T_ResponseCcsInstances, T_ResponseCcsStorageResource, T_ResponseGetCcsProject, T_ResponseGetProjectNames } from "./types";

export const processCcsProjectNames = (data: T_ResponseGetProjectNames): T_CcsState['projects'] => {
    const normalized = normalizeByKey(data.ccsProjects)
    return {
        ...normalized,
        selectedId: normalized.allIds[0]
    }
}

export const processCcsProject = (data: T_ResponseGetCcsProject): Pick<T_CcsState, 'distance' | 'sources' | 'storages' | 'transports' | 'transportViaShip'> => {
    
    // At first we normalize each source's tags 
    const sources = data.cO2Sources.map(source => processCcsInstance(source, 'key'))
    const storages = data.cO2Storages.map(storage => processCcsInstance(storage, 'key'))
    const transports = data.cO2Transports.map(transport => processCcsInstance(transport, 'key'))

    // Then we return normalized sources with already normalized tags inside of them  
    const sourcesGroup = normalizeByKey(sources)
    const storagesGroup = normalizeByKey(storages)
    const transportsGroup = normalizeByKey(transports) 

    return {
        distance: data?.dashboard?.interestDistance ?? 250,
        sources: sourcesGroup,
        storages: storagesGroup,
        transports: transportsGroup,
        transportViaShip: data?.dashboard?.transportViaShip ?? false
    }
}

export const processCcsOptions = (data: T_ResponseCcsInstances['identifierFields'], type: keyof typeof CCS_INSTANCE_TYPES): T_CcsOptionsDropdownItemFormat[] => {
    const groupedData: { [key: string]: T_CcsOptionsDropdownItemFormat } = {}
    data.forEach(field => {
        // const [country, tag] = field.tags
        const keys = {
            [CCS_INSTANCE_TYPES.sources]: {groupKey: 'Country', tagKey: 'Name'},
            [CCS_INSTANCE_TYPES.storages]: {groupKey: 'Country', tagKey: 'Name'},
            [CCS_INSTANCE_TYPES.transports]: {groupKey: 'Pipeline_StartCountry', tagKey: 'Name'}
        }
        const country = field.tags.find(item=>item.key === keys[type].groupKey) 
        const tag = field.tags.find(item=>item.key === keys[type].tagKey) 
        if(!country) return
        if(!groupedData[country.value]) {
            groupedData[country.value] = {
                id: field.id,
                text: country.value,
                filterable: !tag,

            }
        }
        
        if(!groupedData[country.value].items) groupedData[country.value].items = []
        if(tag) {
            groupedData[country.value].items?.push({
                id: field.id,
                text: tag.value,
                filterable: true,
            })
        }
    })
    
    return (Object.values(groupedData)).sort((a, b) => {
        if(a.text > b.text) return 1
        if(a.text < b.text) return -1
        return 0
    })
}

export const processCcsInstance = (data: T_ResponseCcsInstance, key?: string): T_CcsInstance => {
    const tags = normalizeByKey(data.tags, key ?? 'id')
    tags.allIds.forEach(id => {
        tags.byId[id].isNumeric = tags.byId[id].type === CCS_FIELD_INPUT_TYPES.numeric
        delete tags.byId[id].type
    })
    
    return {
        id: data.id,
        originIdentifierFieldId: data.originIdentifierFieldId,
        tags,
        book: data.book
    }
}


export const processCcsStorageResources = (data: T_ResponseCcsStorageResource): Pick<T_CcsState, 'storageResources'> => {
    
    const oilGroup = data.oilFields.map(field => processCcsInstance(field, 'key'))
    const gasGroup =  data.gasFields.map(field => processCcsInstance(field, 'key'))
    const aquiferGroup = data.aquiferFields.map(field => processCcsInstance(field, 'key'))

    const oilFields = normalizeByKey(oilGroup)
    const gasFields = normalizeByKey(gasGroup)
    const aquiferFields = normalizeByKey(aquiferGroup)

    return {
        storageResources: {
            loading: false,
            oilFields,
            gasFields,
            aquiferFields
        }
    }
   
}

export const processCcsInjectivity = (data: T_ResponseCcsInjectivity): Pick<T_CcsState, 'injectivity'> => {

    const storagesGroup = data.storages.map(field => processCcsInstance(field, 'key'))
    const storages = normalizeByKey(storagesGroup)

    const rankingGroup: T_CcsInstanceGroup = {
        byId: {},
        allIds: []
    };

    const estimationGroup: T_CcsInstanceGroup = {
        byId: {},
        allIds: []
    };

    storages.allIds.forEach(field => {
        estimationGroup.allIds.push(field)
        estimationGroup.byId[field] = storages.byId[field]

        rankingGroup.allIds.push(field)
        rankingGroup.byId[field] = storages.byId[field]
    })

    return {
        injectivity: {
            loading: false,
            ranking: rankingGroup,
            estimation: estimationGroup
        }
    }
   
}