import React, { useEffect, useState } from "react";
import './PlotOptions.scss';
import { Button } from "@progress/kendo-react-buttons";
import { DropDown, Tooltip } from "components/SideBarItems/DropDown";
import { InputCharactersCounter, NumericInput, TextInput } from "components/SideBarItems/Input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getPlotOptionsThunk, getQuickPlotsThunk, saveQuickPlotThunk, saveSidebarThunk } from "store/sidebar/thunk";
import { KendoCheckbox } from "components/SideBarItems/Checkbox";
import { sideBarTreeSlice } from "store/sidebar/slice";
import { getPlotThunk } from "store/explore/thunk";
import { IElementType } from "components/SideBarItems/types";
import { selectSelectedTab } from "store/workspace/slice";
import sortAlphabetically from 'helpers/functions/sortAlphabetically';
import { LICENSE_TYPES } from "helpers/constants/app";
import WithRole from "components/userRoles/withRole/WithRole";
import { T_getDatasetsList } from "store/userData/thunk";
import DropdownTreeFilter from "components/dropdownTreeFilter";
import { data } from "../dropdownTreeFilter/tree-data";
import { transformArrayToTreeData } from "components/dropdownTreeFilter/tree-data-operations";
import { DropdownTreeFilterSidebar, TooltipTreeFilter } from "components/SideBarItems/DropDownTreeFilter";
import { IDropDownTreeFilterData } from "store/sidebar/types";

export const PlotOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const [opened, setOpened] = useState<boolean>(true);
    const [value, setValue] = useState(null)
    const selectedTab = useAppSelector(selectSelectedTab);
    const { userDataLegends } = useAppSelector(state => state.exploreSlice);
    const userData = useAppSelector(store => store.userData.datasetsList)
    const { workspaceId, plotId: plotIdW } = useAppSelector(state => state.workspaceSlice);
    const { plotOptions, quickPlots, category1TagAttributesValues, numericTagAttributesValues } = useAppSelector(state => state.sideBarTreeSlice);
    const plotId = selectedTab?.id || plotIdW
    const plotTypes = [
        { id: '1', name: "CrossPlot" },
        { id: '2', name: "Histogram" },
        { id: '3', name: "Statistics" },
    ];

    useEffect(() => {
        dispatch(T_getDatasetsList())
    }, [selectedTab])


    useEffect(() => {
        if (plotId && workspaceId && selectedTab?.type === 1) {
            dispatch(getPlotOptionsThunk({ workspaceId, plotId: selectedTab.id }));
        }
    }, [plotId, workspaceId, selectedTab]);

    const applyPlotAnalysis = async () => {
        await dispatch(saveSidebarThunk({ plotOptions, plotId, workspaceId }));
        await dispatch(getPlotThunk({ plotId, workspaceId }));
    };

    const saveAsQuickPlot = async () => {
        dispatch(sideBarTreeSlice.actions.setPlotOptionsChanged(false));
        await dispatch(saveQuickPlotThunk(plotId));
        await dispatch(getQuickPlotsThunk());
    }

    const handleChangeValue = (obj: { element?: IElementType, value?: unknown, group?: string | null, index?: number }): void => {
        dispatch(sideBarTreeSlice.actions.setPlotOptionItem(obj));
    }

    const handleSaveValues = async (element?: IElementType, group?: string | null) => {
        if (group !== 'serverSideOptions') {
            await dispatch(saveSidebarThunk({ plotOptions, plotId, workspaceId }));
        }
        if (element === 'xTagAttributeId' || element === 'yTagAttributeId')
            await dispatch(getPlotThunk({ plotId, workspaceId }));
    };

    return (
        <div className="plotOptions">
            <div className="contentVisibilityHandler">
                <h3><span onClick={() => setOpened(!opened)} className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Plot Options</h3>
            </div>
            <div className={opened ? "content" : "content closed"}>
                <InputCharactersCounter
                    label="Plot Title"
                    element="title"
                    max={250}
                    value={plotOptions.title}
                    group={null}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                {/* <DropDown
                    items={plotTypes}
                    label="Plot Type"
                    element="type"
                    group={null}
                    value={plotOptions.type + ''}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                /> */}
                <DropDown
                    items={quickPlots.filter(item=>item.type === selectedTab?.type)}
                    label="QuickPlot"
                    element="quickPlotId"
                    group={null}
                    value={plotOptions.quickPlotId}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <Button themeColor={"primary"} onClick={saveAsQuickPlot}>Save To Quick Plot</Button>
                
                <DropdownTreeFilterSidebar
                    items={sortAlphabetically(numericTagAttributesValues, 'name')}
                    label="X Axis"
                    element="xTagAttributeId"
                    group={null}
                    value={plotOptions.xTagAttributeId}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <DropdownTreeFilterSidebar
                    items={sortAlphabetically(numericTagAttributesValues, 'name')}
                    label="Y Axis"
                    element="yTagAttributeId"
                    group={null}
                    value={plotOptions.yTagAttributeId}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="X Min"
                    element="xMin"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.xMin}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="X Max"
                    element="xMax"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.xMax}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="Y Min"
                    element="yMin"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.yMin}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="Y Max"
                    element="yMax"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.yMax}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="X Gridlines"
                    element="xGridlines"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.xGridlines}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Gridlines"
                    element="yGridlines"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.yGridlines}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="X Logarithmic"
                    element="xLogarithmic"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.xLogarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Logarithmic"
                    element="yLogarithmic"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.yLogarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="X Invert"
                    element="xInvert"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.xInvert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Invert"
                    element="yInvert"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.yInvert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Legends</h4>
                <KendoCheckbox
                    label="Show Filters"
                    group="clientSideOptions"
                    element="showFilters"
                    value={plotOptions.crossPlotOptions.showFilters}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Show User Data"
                    group="clientSideOptions"
                    element="showUserData"
                    disabled={!userData.length}
                    value={plotOptions.crossPlotOptions.showUserData}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Show Regression Equations"
                    group="clientSideOptions"
                    element="showRegressionEquations"
                    value={plotOptions.crossPlotOptions.showRegressionEquations}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Labels</h4>
                <KendoCheckbox
                    disabled={!userData.length}
                    label="Show User Data Labels"
                    element="showUserDataLabels"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.showUserDataLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <WithRole roles={[LICENSE_TYPES.global]}>
                    <KendoCheckbox
                        label="Show Global Database Labels"
                        element="globalDatabaseLabels"
                        group="clientSideOptions"
                        value={plotOptions.crossPlotOptions.globalDatabaseLabels}
                        setFunction={handleChangeValue}
                        saveFunction={handleSaveValues}
                    />
                </WithRole>
                <KendoCheckbox
                    label="Show Axis Labels"
                    element="showAxisLabels"
                    group="clientSideOptions"
                    value={plotOptions.crossPlotOptions.showAxisLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <div className="hr" />
                <h4>Plot Analysis</h4>
                <h4>Regression</h4>
                <KendoCheckbox
                    label="Lin Regression"
                    group="serverSideOptions"
                    element="linRegression"
                    value={plotOptions.crossPlotOptions.linRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Log Regression"
                    group="serverSideOptions"
                    element="logRegression"
                    value={plotOptions.crossPlotOptions.logRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Exp Regression"
                    group="serverSideOptions"
                    element="expRegression"
                    value={plotOptions.crossPlotOptions.expRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Pow Regression"
                    group="serverSideOptions"
                    element="powRegression"
                    value={plotOptions.crossPlotOptions.powRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="Y Intcp Value"
                    group="serverSideOptions"
                    element="intcpValue"
                    value={plotOptions.crossPlotOptions.intcpValue}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Intcp"
                    group="serverSideOptions"
                    element="intcp"
                    value={plotOptions.crossPlotOptions.intcp}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Add point"
                    group="serverSideOptions"
                    element="addPoint"
                    value={plotOptions.crossPlotOptions.addPoint}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="X Value"
                    group="serverSideOptions"
                    element="xValue"
                    value={plotOptions.crossPlotOptions.xValue}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <TextInput
                    label="Label"
                    group="serverSideOptions"
                    element="label"
                    value={plotOptions.crossPlotOptions.label}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Tooltips</h4>
                {new Array(5).fill(null).map((_, i) => (
                    <TooltipTreeFilter
                        key={i}
                        items={sortAlphabetically(category1TagAttributesValues, 'name')}
                        element="tooltips"
                        index={i}
                        group="serverSideOptions"
                        value={plotOptions.crossPlotOptions.tooltips[i]}
                        setFunction={handleChangeValue}
                        saveFunction={handleSaveValues}
                    />
                ))}
                <Button themeColor={"primary"} onClick={applyPlotAnalysis}>Apply Plot Analysis</Button>
                <div className="hr" />
            </div>
        </div>
    )
}

