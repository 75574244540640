import axios from 'axios';
import { removeWorkSpaceFromLS } from 'helpers/functions/workspaceHelpers';
import { AppStore } from 'store/store';
import { workspaceSlice } from 'store/workspace/slice';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const applyInterceptors = (store: AppStore) => {
  api.interceptors.request.use(
    (config) => config,
  )
  
  api.interceptors.response.use(
    (config) => (config),
    (error) => {
      const state = store.getState()
    if(error?.response?.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('mfaVerified');
      removeWorkSpaceFromLS()
      state.userStateSlice.isSignedIn === true && window.location.reload()
    }
    else if(error?.response?.status === 423) {
        store.dispatch(workspaceSlice.actions.handleRestrictionPopup({open: true, message: 'Another user is currently working on the workspace.'}))
        return error.response
    }
    else if(error?.response?.status === 403) {
      const state = store.getState()
      const selectedTab = state.workspaceSlice.tabs.find(item=>item.isSelected)
      if(selectedTab?.type === 4 && window.location.pathname.includes('/Explore')){
        store.dispatch(workspaceSlice.actions.handleRestrictionPopup({open: true, message: 'Sorry, you are not authorized to view a Data Grid tab. Please contact your company administrator for further assistance.'}))
      }
    }
    return Promise.reject(error);
  })
}

api.defaults.headers.common['content-type'] = 'application/json'
