import React, { useEffect, useState } from "react";
import './benchmarkOptions.scss';
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectSelectedTab } from "store/workspace/slice";
import { DropDownTree, DropDownTreeChangeEvent, ItemProps, MultiSelectTreeExpandEvent, ValueHolderProps } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState } from "components/AddPlotPopup/multiselecttree-data-operations";
import { T_changeBenchmark, T_getBenchmarkData, T_getBenchmarkOptions, T_getBenchmarkTypes } from "store/explore/thunk";
import { BenchmarkPlotOptions } from "./BenchmarkPlotOptions";


const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "title";
const selectField = 'selected';

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

export const BenchmarkOptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState<boolean>(true);
  const { benchmark: {isFetchedBenchmarkTypes, benchmarkTypes, benchmarkOptions}, isLoading } = useAppSelector(state => state.exploreSlice);
  const benchmarkPlotOptions = useAppSelector(store=>store.sideBarTreeSlice.benchmarkPlotOptions.benchmarkPlotOptions)
  const { workspaceId } = useAppSelector(state => state.workspaceSlice);
  const selectedTab = useAppSelector(selectSelectedTab)

  const [benchmarkType, setBenchmarkType] = useState<{ id: string } | null>(null)
  const [benchmarkTypeError, setBenchmarkTypeError] = useState<boolean>(false)
  const [openBenchmarkTypeDropdown, setOpenBenchmarkTypeDropdown] = useState<boolean>(false)
  const [expanded, setExpanded] = React.useState([]);
  const treeDataBenchmark = React.useMemo(
    () => processMultiSelectTreeData(benchmarkTypes, { expanded, value: benchmarkType, ...fields }),
    [expanded, benchmarkType, benchmarkTypes]
  );

  const selectBenchmarkType = async (e: DropDownTreeChangeEvent) => {
    if(e?.value?.isDisabled) return
    if (e.value.items.length) return    
    if (!e.value) {
      setBenchmarkType(null)
      return
    }
    setBenchmarkType(e.value)
    setBenchmarkTypeError(false)
    if (selectedTab?.id) {
      await dispatch(T_changeBenchmark({
        workspaceId,
        type: e.value.id,
        plotId: selectedTab?.id,
        benchmarkPlotOptions
      }))
      await dispatch(T_getBenchmarkData({ workspaceId, id: selectedTab?.id }))
    }
  }


  useEffect(() => {
    if (selectedTab?.id) {
        dispatch(T_getBenchmarkOptions({ workspaceId, id: selectedTab?.id }))
    }
}, [selectedTab?.id])

  useEffect(() => {
    if (benchmarkOptions.benchmark) {
      const type = { ...benchmarkOptions.benchmark, title: benchmarkOptions.benchmark?.trainingRegion }
      setBenchmarkType(type || null)
      if(type) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: kendo provide FocusEvent<undefined> as type but FocusEvent is not generic
        setExpanded([type?.fluidType+type?.fluidType, type?.predictedParameter+type?.fluidType])
      }
    }
  }, [benchmarkOptions.benchmark, benchmarkTypes])

  const onExpandChange = React.useCallback(
    (event: MultiSelectTreeExpandEvent) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: kendo provide FocusEvent<undefined> as type but FocusEvent is not generic
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  useEffect(() => {
    const getBenchmarkTypes = async () => {
      await dispatch(T_getBenchmarkTypes({}))
    }
    if (!isFetchedBenchmarkTypes) {
      getBenchmarkTypes()
    }
  }, [isFetchedBenchmarkTypes])

  const Item = (props: ItemProps) => {
    return (
      <span className={`dropdownItem ${props.item.isDisabled ? 'disabledItem' : ''} ${benchmarkType?.id === props.item.id ? 'selectedDropdownValue' : ''}`}>
        {props.item[textField]}
      </span>
    );
  };

  const ValueHolder = (props: ValueHolderProps) => {    
    return <React.Fragment>
      <span className={'selectedDropdownValueHolder'}>
        {props?.item?.fluidType} - {props?.item?.predictedParameter} - {props.children}
      </span>
    </React.Fragment>
  };


  return (
    <div className="benchmarkOptions">
      <div className="contentVisibilityHandler">
        <h3><span onClick={() => setOpened(!opened)} className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Benchmark Options</h3>
      </div>
      <div className={opened ? "content" : "content closed"}>
        <div className={"dropDownListBlock"}>
          <DropDownTree
            data={treeDataBenchmark}
            dataItemKey={dataItemKey}
            style={{ border: `2px solid ${benchmarkTypeError ? "crimson" : "#EAEAEA"}` }}
            textField={textField}
            popupSettings={{
              popupClass: 'benchmarkPopup'
            }}
            subItemsField={subItemsField}
            className="benchmarkType"
            loading={!isFetchedBenchmarkTypes || isLoading}
            expandField={expandField}
            selectField={selectField}
            onClose={() => setOpenBenchmarkTypeDropdown(false)}
            onChange={selectBenchmarkType}
            value={benchmarkType}
            opened={openBenchmarkTypeDropdown}
            onOpen={() => setOpenBenchmarkTypeDropdown(true)}
            placeholder="Select"
            onExpandChange={onExpandChange}
            item={Item}
            valueHolder={ValueHolder}
          />
        </div>
      </div>
      <BenchmarkPlotOptions />
    </div>
  )
}

