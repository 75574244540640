import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import logoBig from '../../assets/images/logoBig.png';
import bMarkLoginBackground from '../../assets/images/bMarkLoginBackground.png';
import './Registration.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { registrationThunk } from '../../store/registration/thunk';
import { handleEmailChange } from '../../store/registration/slice';
import * as yup from 'yup';
import { Portal } from 'components/Portal/Portal';
import TermsNConditions from 'components/termsNConditions/termsNConditions';
import { useNavigate } from 'react-router-dom';

const Registration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email } = useAppSelector(state => state.registrationSlice);
  const [validationError, setValidationError] = useState<string>('');
  const [emailSent, setEmailSent] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)


  const verificationSchema = {
    email: yup.string().required('Enter email').email('Invalid email'),
  }

  const schema = yup.object().shape(verificationSchema);

  const handleRegistrationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await schema.validate({ email })
      setValidationError('')
      const resp: unknown = await dispatch(registrationThunk(email));
      const response = resp as { error: string | undefined };
      if (response.error) throw response
      else setEmailSent(true)
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const validationMessage = e as { payload: any, message: string };
      return setValidationError(validationMessage.payload ?? validationMessage.message)
    }
  };


  const handleShowAgeement = () => {
    setTermsOpen(true)
  }

  const handleSignIn = () => {
    navigate('/signin', { replace: true })
  }

  return (
    <div id='Registration'>
      <Portal opened={termsOpen} closePortal={() => setTermsOpen(prev => !prev)} >
        <>
          <div className='portal-header termsheader'>
            <button className='close-btn' onClick={() => setTermsOpen(false)} />
          </div>
          <TermsNConditions />
        </>
      </Portal>
      <div className='formBlock'>
        <img src={logoBig} className='login-logo' />
        {emailSent ?
          <h3>Email sent successfully, please check your mailbox</h3>
          :
          <form onSubmit={handleRegistrationSubmit}>
            <h2>Please enter your email</h2>
            <div className="inputBlock">
              <Input type='text' placeholder='Email' onChange={(e) => dispatch(handleEmailChange(e.value))}
                value={email} />
            </div>
            <span className='validationError'>{validationError}</span>
            <div className='agreementCont' >
              <Checkbox value={termsAccepted} onChange={() => setTermsAccepted(prev => !prev)} style={{ border: '1px solid black' }} />
              <div className='text'>
                By clicking Continue you agree to the <button type='button' onClick={handleShowAgeement} >bMark User Agreement</button>
              </div>
            </div>
            <Button disabled={!termsAccepted} type='submit' className='registrationButton' themeColor="primary">Continue</Button>
            <p className='caption' >Already have an account? <button type='button' className='hype' onClick={handleSignIn} >Sign In here</button></p>
          </form>}
        <span className='copyRight'>© bMark {new Date().getFullYear()}</span>
      </div>
      <div className='backgroundBlock'>
        <img src={bMarkLoginBackground} />
      </div>
    </div>
  );
};

export default Registration;