import { Dispatch } from 'react';
import { api } from '../root';
import { License, LicenseGridInterface } from '../../store/license/types';
import { addNotification } from '../../store/notifications/slice';
import { handleRequestErrors } from 'helpers/functions/commons';

export const createLicense = async (credentials: License, thunkAPI: unknown) => {
    try {
        const response = await api.post<License>('/License', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getAllLicenses = async (credentials: {
    pageNumber:number,
    pageSize:number
}, thunkAPI: unknown) => {
    try {
        const response = await api.get<LicenseGridInterface>(`/License/${credentials.pageNumber}`, {
            params: {
                pageSize: credentials.pageSize
            }
        });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const updateLicense = async (credentials: Partial<License>, thunkAPI: unknown) => {
    try {
        const response = await api.put<License>('/License', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const deleteLicense = async (credentials: string, thunkAPI: unknown) => {
    try {
        const response = await api.delete<License>(`/License/${credentials}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}



