import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from './types';
import { T_getUserInfo } from './thunk';


const initialState: UserState = {
  isSignedIn: 'pending',
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  userName: '',
  licenseType: 0,
  licenseInfo: {
    viewDatabase: true,
    ccsModule: false,
  }
};

export const userStateSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserStatus: (state, { payload }) => {
        state.isSignedIn = payload
    }
  },
  extraReducers: {
    [T_getUserInfo.pending.type]: (state, action) => {
      state.isSignedIn = 'pending';
      state.id = '';
      state.name = '';
      state.firstName = '';
      state.lastName = '';
      state.userName = '';
    },
    [T_getUserInfo.fulfilled.type]: (state, action) => {
      state.isSignedIn = true;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.userName = action.payload.userName;
      state.licenseType = action.payload.licenseInfo.licenseType
      state.licenseInfo = action.payload.licenseInfo
      
    },
    [T_getUserInfo.rejected.type]: (state) => {
      state.isSignedIn = false
    }
  },
})

export default userStateSlice.reducer