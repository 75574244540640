import React, { useEffect } from 'react';
import {
    Notification,
} from '@progress/kendo-react-notification';

import './notifications.scss'

import { useDispatch } from 'react-redux';
import { removeNotification } from '../../store/notifications/slice';
import { notificationObjInterface } from '../../store/notifications/types';

export default function SingleNotification(props: notificationObjInterface) {
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(removeNotification({ id: props.id }))
        }, 5000);
    }, [])

    return (
        props.message ? (
            <Notification
                className={`notification__${props.type}`}
                key={props.id}
                type={{ style: props.type, icon: true }}
                closable={true}
                onClose={() => dispatch(removeNotification({ id: props.id }))}
            >
                <span>{props.message.length > 730 ? `${props.message.substring(0, 730)}...` : props.message}</span>
            </Notification>
        ) : <></>
    );
}