import React, { FC, useEffect } from 'react'
import { PORTAL_ROOT_ID } from 'helpers/constants/commons';
import { createPortal } from 'react-dom';
import './portal.scss'

interface IProps {
    opened: boolean
    closePortal: () => void
    children?: JSX.Element
}

export const Portal: FC<IProps> = ({ opened, closePortal, children }) => {
    const element = document.getElementById(PORTAL_ROOT_ID) as HTMLElement;

    useEffect(() => {
        document.body.style.overflowY = opened ? 'hidden' : 'auto'
    }, [opened])

    if (!opened) return null

    return createPortal((
        <>
            <div className='portal-overlay' onClick={closePortal}></div>
            <div className='portal-content'>
                {children}
            </div>
        </>
    ), element);
}