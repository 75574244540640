/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { v4 as uuidv4 } from 'uuid';
import { ITab } from '../../api/workspace/types';
import { createSelector, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { getAllWorkspacesThunk, getWorkspaceByIdThunk, savePlotOptionsThunk, T_addWorkspace, T_deleteWorkspace, T_editWorkspace, T_GetUserActions, T_getProjectView, T_editWorkspaceFilters, saveMapOptionsThunk, T_getStyleSettings, T_changeStyleSettings, T_getAxisStyleSettings } from './thunk';
import { workspaceState, IWorkspaceItem, IPlot, IWorkspace, IFilterLegend, IProjectViewOption, MapOptions, IFilterOptions, IStyleSettings, IAxisStyleSettings } from './types';
import { IFilter, IOptions, ITagAttribute } from '../sidebar/types';
import { ICategorizeOption, ITagAttributeAllowedValue, ITagAttributeAllowedValueItem, TreeViewDataItem } from 'store/sidebar/types';
import { getSideBarFiltersThunk, GetTagAttributeAllowedValuesThunk } from 'store/sidebar/thunk';
import { checkFilterActiveStatus } from 'helpers/functions/sidebar';
import { RootState, store } from '../store';
import { saveWorkSpaceToLS } from 'helpers/functions/workspaceHelpers';
import { IUserActions } from 'api/userAction/types';
import { deepClone, normalizeByKey } from 'helpers/functions/commons';
import { setSharedUsersThunk } from 'store/home/thunk';

function getNestedValueClone(tree: RootState['workspaceSlice']['tree'] | RootState['workspaceSlice']['filteredFilters'], keyString: string) {
    const indexes = keyString.split('_');
    const keyStringWithItems = indexes.join('_items_')
    const keys = keyStringWithItems.split('_')
    return keys.reduce((nestedTree: any, key: any) => {
        if (nestedTree && typeof nestedTree === 'object' && key in nestedTree) {
            return nestedTree[key];
        } else {
            return undefined;
        }
    }, deepClone(tree));
}

function getNestedValue(tree: RootState['workspaceSlice']['tree'] | RootState['workspaceSlice']['filteredFilters'], keyString: string) {
    const indexes = keyString.split('_');
    const keyStringWithItems = indexes.join('_items_')
    const keys = keyStringWithItems.split('_')
    return keys.reduce((nestedTree: any, key: any) => {
        if (nestedTree && typeof nestedTree === 'object' && key in nestedTree) {
            return nestedTree[key];
        } else {
            return undefined;
        }
    }, tree[0].items);
}

const getItemByHierarchicalIndex = (itemHierarchicalIndex: 'string', state: RootState['workspaceSlice']['tree'] | RootState['workspaceSlice']['filteredFilters']) => {
    const value = getNestedValueClone(state[0].items, itemHierarchicalIndex)
    return value
}

export const initialStyleSettings: IStyleSettings = {
    color: "rgba(0, 0, 0, 1)",
    font: { name: "Arial", family: 'Arial' },
    shape: { id: "Circle", url: `${window.location.origin}/circle.png` },
    sizeFont: { name: 'Small', value: '12px' },
    sizeShape: 'x1',
    position: { label: 'Center', value: 'center' },
    border: false,
    optionId: ''
}

export const initialAxisStyleSettings: IAxisStyleSettings = {
    title: {
        font: { name: "Arial", family: 'Arial' },
        sizeFont: { name: 'Meidum', value: '16px' },
    },
    x: {
        font: { name: "Arial", family: 'Arial' },
        sizeFont: { name: 'Small', value: '12px' },
    },
    y: {
        font: { name: "Arial", family: 'Arial' },
        sizeFont: { name: 'Small', value: '12px' },
    },
    y2: {
        font: { name: "Arial", family: 'Arial' },
        sizeFont: { name: 'Small', value: '12px' },
    }

}

const clearFilter = (items: TreeViewDataItem[]) => {
    if (!items.length) return
    items.forEach(filter => {
        if (!('filterApplied' in filter)) return clearFilter(filter.items)
        filter.filterApplied = false
    })
}

const initialState: workspaceState = {
    tree: [],
    hiddenSidebar: true,
    restrictionPopup: {
        open: false,
        message: ''
    },
    filtersSearchText: '',
    filtersTreeView: [
        {
            name: 'Filters',
            element: 'filters',
            type: 'title',
            category: 'title',
            expanded: false,
            id: uuidv4(),
            items: [],
        },
    ],
    loadAllowedValues: false,
    projectViewTreeView: {
        name: 'Project view options',
        element: 'projectViewOptions',
        category: 'title',
        type: 'title',
        expanded: true,
        id: uuidv4(),
        items: [
            {
                category: 'projectView', element: 'projectViewoption', type: 'select',
                options: [],
                selected: { name: '', value: '', id: uuidv4(), type: '' },
                id: uuidv4(),
                items: []
            },
        ]
    },
    plots: [],
    categoriseOptions: [],
    stringOptions: [],
    numericOptions: [],
    projectViewOptions: [],
    mapOptions: {
        mapType: 0,
        showUserData: false,
        showGlobalDatabase: false,
        showFilters: false,
        showUserDataLegends: false,
        subsurfaceAssets: false,
        ccsProjects: false,
        cO2Emitters: false,
        globalPipelineNetworks: false,
        tooltips: []
    },
    workspace: null,
    workspaceId: '',
    plotId: '',
    workspaces: [],
    isWorkspaceListFetched: false,
    workspaceListLoading: false,
    workspaceEdited: false,
    activeFiltersLegend: [],
    deletePlot: false,
    tagAttributeAllowedValues: [],
    userActions: {
        workspaceId: '',
        action: 0,
        entityId: '',
        actionName: ''
    },
    filters: {
        id: '',
        title: '',
        type: 1,
        xTagAttributeId: '',
        yTagAttributeId: '',
        quickPlotId: '',
        plotOptions: {
            axisOptions: {
                xMin: null,
                xMax: null,
                yMin: null,
                yMax: null,
                xGridlines: true,
                yGridlines: true,
                xLogarithmic: false,
                yLogarithmic: false,
                xInvert: false,
                yInvert: false
            },
            regressions: {
                addPoint: false,
                expRegression: false,
                label: '',
                linRegression: false,
                logRegression: false,
                powRegression: false,
                xLogLines: false,
                xValue: null,
                yLogLines: false,
                zeroIntercept: false,
                zeroInterceptValue: 0,
            },
            legends: {
                showFilters: true,
                showUserData: false,
                showRegressionEquations: true
            },
            labels: {
                showUserDataLabels: false,
                globalDatabaseLabels: false,
                showAxisLabels: true
            },
            tooltips: []
        },
        categorizeOptions: {
            tagAttributeId: null,
            enabled: false,
            logScale: false,
            applyToUserData: false
        },
        filterOptions: {
            applyToUserData: false,
            applyToBenchmarkData: false,
            applyToProductionData: false,
            isEnabled: true,
            filters: []
        },
        labelTemplateHover: '</br><b>#value</b></br>',
        categoryTemplateHover: '<b>#key:</b> #value</br>',
        tooltipTemplateHover: '<b>#key:</b> #value</br>',
        extraTemplateHover: '<extra></extra>',
    },
    filteredFilters: [],
    tabs: [],
    isLoading: false,
    error: null,
    styleSettings: initialStyleSettings,
    axisStyleSettings: initialAxisStyleSettings,
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setFilterOptionItem: (state, action) => {
      const { element, value } = action.payload

      if (element === "isEnabled") state.tree[0].name = state.filters.filterOptions.filters.length && state.filters.filterOptions.isEnabled ? "Filters (DISABLED)" : "Filters"

      state.filters.filterOptions[element as keyof IFilterOptions] = value
    },
    setFiltersSearchText: (state, action) => {
      state.filtersSearchText = action.payload
    },
    setStyleSettings: (state, action: PayloadAction<IStyleSettings>) => {
      state.styleSettings = action.payload
    },
    setAxisStyleSettings: (state, action: PayloadAction<Partial<IAxisStyleSettings>>) => {
      state.axisStyleSettings = { ...state.axisStyleSettings, ...action.payload }
    },
    setMapOptionItem: (state, action) => {
      const { element, value, index } = action.payload

      if (element.includes("tooltip")) {
        if (index > -1) {
          if (!state.mapOptions.tooltips[index]) {
            state.mapOptions.tooltips = new Array(5).fill(null).map((item, i) => (i === index ? value : state.mapOptions.tooltips[i] || null))
          } else {
            state.mapOptions.tooltips[index].id = value.id
            state.mapOptions.tooltips[index].text = value.text
          }
        }
      } else {
        state.mapOptions[element as keyof MapOptions] = value as never
      }
    },

    handleRestrictionPopup: (state, action) => {
      state.restrictionPopup.open = action.payload.open
      state.restrictionPopup.message = action.payload.message
    },

    changePlotTitle: (state, action) => {
      const selectedTab = state.tabs.find((e: ITab) => e.id === action.payload.id)
      if (selectedTab) selectedTab.title = action.payload.value
    },

    setTree: (state, action) => {
      state.tree = action.payload
      state.filtersTreeView = action.payload
    },

    setFilteredFilters: (state, action) => {
      state.filteredFilters = action.payload
    },

    hideSidebar: (state, action) => {
      state.hiddenSidebar = action.payload
    },

    setDeletePlot: (state, action) => {
      state.deletePlot = action.payload
    },

    showPlotOptions: (state) => {
      state.tree = state.tree.filter((item) => item.element === "filterOptions" || item.element === "filters")
    },

    hidePlotOptions: (state) => {
      state.tree = state.tree.filter((item) => item.element !== "plotOptions")
    },

    hideMapOptions: (state) => {
      state.tree = state.tree.filter((item) => item.element !== "mapOptions")
    },

    showProjectViewOptions: (state) => {
      state.tree = state.tree.filter((item) => item.element === "filterOptions" || item.element === "filters")
      if (state.tree.every((item) => item.element !== "projectViewOptions")) {
        state.tree.push(state.projectViewTreeView)
      }
    },

    hideProjectViewOptions: (state) => {
      state.tree = state.tree.filter((item) => item.element !== "projectViewOptions")
    },

    setAxis: (state, action) => {
      const { x, y, xAxis, yAxis } = action.payload
      const currentX = state.tree[2].items.find((item: TreeViewDataItem) => item.name === "X Axis")
      const currentY = state.tree[2].items.find((item: TreeViewDataItem) => item.name === "Y Axis")
      if (currentX) currentX.selected = { name: xAxis, id: x, type: "" }
      if (currentY) currentY.selected = { name: yAxis, id: y, type: "" }
    },

    checkItem: (state, action) => {
      const {
        props: { itemHierarchicalIndex },
        value,
      } = action.payload
      const current = getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree)
      current.checked = value
      current.filterApplied = checkFilterActiveStatus(current)
    },

    setQuickPlotId: (state, action) => {
      state.tabs.find((tab: ITab) => {
        if (tab.isSelected) tab.quickPlotId = action.payload
      })
    },

    selectItem: (state, action) => {
      state.tree.find((product) => {
        if (product.items) {
          const current = product.items.find((item) => item.id === action.payload.props.item.id)
          if (current) {
            current.selected = action.payload.value
            current.filterApplied = checkFilterActiveStatus(current)
          }
        }
      })
    },

    changeInputValue: (state, action) => {
      const { itemHierarchicalIndex, value } = action.payload
      const item = getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree)
      item.inputValue = value
      item.filterApplied = checkFilterActiveStatus(item)
    },

    changePlotOptions: (state, action) => {
      const { name, value } = action.payload
      const filters = state.filters
      const options = filters.plotOptions

      if (name === "plotTitle") {
        filters.title = value
      }

      if (name === "plotType") {
        const type = state.tree[2].items.find((i) => i.element === "plotType")
        if (type) type.selected = value
        filters.type = value.name === "Histogram" ? 2 : value.name === "Statistics" ? 3 : 1
      }

      if (name.includes("Axis")) {
        name === "xAxis" ? (filters.xTagAttributeId = value) : (filters.yTagAttributeId = value)
      }

      if (name === "tooltip") {
        options?.tooltips?.push(value.id)
      }

      const plotCategories = Object.keys(options ?? {})

      plotCategories.map((category) => {
        const field = category as keyof typeof options
        const optionField = options?.[field] || {}
        if (name in optionField) {
          ;(optionField[name as keyof typeof optionField] as string) = value
        }
      })
    },

    changeNumberInputValue: (state, action) => {
      const {
        props: { itemHierarchicalIndex },
        filtersSearched,
      } = action.payload
      const indexes = itemHierarchicalIndex.split("_")
      const item = !filtersSearched ? getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree) : getItemByHierarchicalIndex(itemHierarchicalIndex, state.filteredFilters)
      if (action.payload.input === "firstConditionInput") item.firstConditionInput = action.payload.value
      else if (action.payload.input === "secondConditionInput") item.secondConditionInput = action.payload.value
    },

    setCategoriseTagAttributeId: (state, action) => {
      state.filters.categorizeOptions.tagAttributeId = action.payload
    },

    setCategoriseEnabled: (state, action) => {
      state.filters.categorizeOptions.enabled = action.payload
    },

    setCategoriseLogScale: (state, action) => {
      state.filters.categorizeOptions.logScale = action.payload
    },

    setCategoriseUserData: (state, action) => {
      state.filters.categorizeOptions.applyToUserData = action.payload
    },

    clearAllFilters: (state) => {
      state.activeFiltersLegend = []
      state.filters.filterOptions.filters = []
      state.tree[0].name = "Filters"
    },

    enableDisableFilters: (state) => {
      state.tree[0].name = state.filters.filterOptions.filters.length && state.filters.filterOptions.isEnabled ? "Filters (DISABLED)" : "Filters"
      state.filters.filterOptions.isEnabled = !state.filters.filterOptions.isEnabled
    },

    switchApplyToUserData: (state) => {
      state.filters.filterOptions.applyToUserData = !state.filters.filterOptions.applyToUserData
    },

    applyFilter: (state, action) => {
      try {
        const { firstConditionInput, firstConditionList, logicalOperationList, secondConditionInput, secondConditionList, name, values, id } = action.payload.props.item
        const LegendValueFromConditionInputs = [
          { list: firstConditionList, input: firstConditionInput },
          { list: secondConditionList, input: secondConditionInput },
        ].reduce((acc, item) => {
          if (item.input) {
            acc += `${acc ? ` ${logicalOperationList.name}` : ""} ${item.list.name} ${item.input}`
          }
          return acc
        }, "")
        const currentActiveFiltersLegend = state.activeFiltersLegend.find((filterLegend: IFilterLegend) => filterLegend.filter === name)
        const finalValues: string[] = []
        if (values.length) {
          finalValues.push(...values)
        }
        if (LegendValueFromConditionInputs) {
          finalValues.push(LegendValueFromConditionInputs)
        }

        if (currentActiveFiltersLegend) {
          currentActiveFiltersLegend.values = finalValues
        } else {
          state.activeFiltersLegend.push({
            filter: name,
            values: finalValues,
          })
        }

        const { filters } = state.filters.filterOptions

        const index = filters.findIndex((fil: IFilter) => fil.tagAttributeId === id)
        // try !checkFilterActiveStatus(action.payload.props.item)
        const {
          props: { itemHierarchicalIndex },
          filtersSearched,
        } = action.payload
        const indexes = itemHierarchicalIndex.split("_")
        const item = !filtersSearched ? getNestedValue(state.tree, itemHierarchicalIndex) : getNestedValue(state.filteredFilters, itemHierarchicalIndex)
        item.filterApplied = checkFilterActiveStatus(item, { firstConditionInput, secondConditionInput })

        item.firstConditionInput = firstConditionInput
        item.secondConditionInput = secondConditionInput
        item.firstConditionList = firstConditionList
        item.secondConditionList = secondConditionList
        item.logicalOperationList = logicalOperationList

        if (values.length === 0 && !firstConditionInput && !secondConditionInput) {
          // remove filter if there is no values and filter words
          if (index > -1) {
            filters.splice(index, 1)
          }
          return
        }

        const filterModel: IFilter = {
          tagAttributeId: id,
          partOne: {
            value: firstConditionInput,
            ignoreCase: true,
            operationId: firstConditionList.id,
            operationNameNumeric: firstConditionList.name,
            operationNameString: firstConditionList.name,
          },
          booleanOperationId: logicalOperationList.id,
          partTwo: {
            value: secondConditionInput,
            ignoreCase: true,
            operationId: secondConditionList.id,
            operationNameNumeric: secondConditionList.name,
            operationNameString: secondConditionList.name,
          },
          values,
        }

        // update filter if it exists or add new filter
        if (index > -1) {
          filters[index] = filterModel
        } else {
          filters.push(filterModel)
        }
        if (!filters.length) {
          state.tree[0].name = "Filters"
        }
      } catch (error) {
        console.log(error)
      }
    },

    clearFiltersApplied: (state) => {
      clearFilter(state.tree)
      if (!state.filters.filterOptions.filters.length) {
        state.tree[0].name = "Filters"
      }
    },

    checkFilter: (state, action) => {
      const {
        props: { itemHierarchicalIndex },
        filtersSearched,
      } = action.payload
      const item = !filtersSearched ? getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree) : getItemByHierarchicalIndex(itemHierarchicalIndex, state.filteredFilters)
      const values = item.values
      if (action.payload.value) {
        values?.push(action.payload.filter.text)
      } else {
        values?.splice(values.indexOf(action.payload.filter.text), 1)
      }

      const currentFilters: ITagAttributeAllowedValue | undefined = state.tagAttributeAllowedValues.find((e: ITagAttributeAllowedValue) => e.tagId === action.payload.id)
      const currentFilter: ITagAttributeAllowedValueItem | undefined = currentFilters?.values.find((filter: ITagAttributeAllowedValueItem) => filter.text === action.payload.filter.text)

      item.filterApplied = !!item.values?.length
      if (currentFilter) currentFilter.checked = action.payload.value

      let itemToChange = getNestedValue(state.tree, itemHierarchicalIndex)
      itemToChange = { ...JSON.parse(JSON.stringify(item)) }

      const indexes = itemHierarchicalIndex.split("_")
      const keyStringWithItems = indexes.join("_items_")
      const keys = keyStringWithItems.split("_")

      keys.reduce(
        (nestedTree: any, key: any, index: number) => {
          if (nestedTree && typeof nestedTree === "object" && key in nestedTree) {
            if (index === keys.length - 1) {
              nestedTree[key] = item
            }
            return nestedTree[key]
          } else {
            return undefined
          }
        },
        filtersSearched ? state.filteredFilters[0].items : state.tree[0].items,
      )
    },

    firstConditionList: (state, action) => {
      const {
        props: { itemHierarchicalIndex },
        filtersSearched,
      } = action.payload
      const indexes = itemHierarchicalIndex.split("_")
      const item = !filtersSearched ? getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree) : getItemByHierarchicalIndex(itemHierarchicalIndex, state.filteredFilters)
      item.firstConditionList = action.payload.value
      item.filterApplied = checkFilterActiveStatus(item)
    },

    logicalOperationList: (state, action) => {
      const {
        props: { itemHierarchicalIndex },
        filtersSearched,
      } = action.payload
      const indexes = itemHierarchicalIndex.split("_")
      const item = !filtersSearched ? getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree) : getItemByHierarchicalIndex(itemHierarchicalIndex, state.filteredFilters)
      item.logicalOperationList = action.payload.value
      item.filterApplied = checkFilterActiveStatus(item)
    },

    secondConditionList: (state, action) => {
      const {
        props: { itemHierarchicalIndex },
        filtersSearched,
      } = action.payload
      const indexes = itemHierarchicalIndex.split("_")
      const item = !filtersSearched ? getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree) : getItemByHierarchicalIndex(itemHierarchicalIndex, state.filteredFilters)
      item.secondConditionList = action.payload.value
      item.filterApplied = checkFilterActiveStatus(item)
    },

    clearFilter: (state, action) => {
      const {
        props: {
          itemHierarchicalIndex,
          item: { element },
        },
        filtersSearched,
      } = action.payload
      const indexes = itemHierarchicalIndex.split("_")

      state.tagAttributeAllowedValues.forEach((tag: ITagAttributeAllowedValue) => {
        tag.values.forEach((value: ITagAttributeAllowedValueItem) => {
          value.checked = false
        })
      })

      const current = !filtersSearched ? getNestedValue(state.tree, itemHierarchicalIndex) : getNestedValue(state.filteredFilters, itemHierarchicalIndex)
      const currentIndex = state.activeFiltersLegend.findIndex((filterLegend: IFilterLegend) => filterLegend.filter === current.name)
      state.activeFiltersLegend.splice(currentIndex, 1)

      current.firstConditionList = element === "filter" ? { id: 3, name: "Contains", defaultPriority: 1 } : { id: 5, name: "Is greater than", defaultPriority: 1 }
      current.firstConditionInput = ""
      current.logicalOperationList = { id: 1, name: "And", defaultPriority: 1 }
      current.secondConditionList = element === "filter" ? { id: 4, name: "Does not contain", defaultPriority: 2 } : { id: 3, name: "Is less than", defaultPriority: 2 }
      current.secondConditionInput = ""
      current.values = []
      current.filterApplied = false
      const { id } = action.payload.props.item
      state.filters.filterOptions.filters.find((filter: IFilter, index: number) => {
        if (filter?.tagAttributeId === id) {
          // need to understand why filter is undefined when refresh page with filters applied
          state.filters.filterOptions.filters.splice(index, 1)
        }
      })
      if (!state.filters.filterOptions.filters.length) {
        state.tree[0].name = "Filters"
      }
    },

    getDropdownData: (state, action) => {
      const indexes = action.payload.itemHierarchicalIndex.split("_")
      const options = JSON.parse(JSON.stringify(state.numericOptions))
      getItemByHierarchicalIndex(action.payload.itemHierarchicalIndex, state.tree).options = options
    },

    setSelectedTab: (state, { payload }: PayloadAction<ITab>) => {
      const activeTab = state.tabs.find((tab: ITab) => tab.isSelected)
      if (activeTab) activeTab.isSelected = false

      const selectedTab = state.tabs.find((tab: ITab) => tab.id === payload.id)

      if (selectedTab) {
        selectedTab.isSelected = true
        state.plotId = selectedTab.id
      }

      if (payload.type === "mapView") {
        state.plotId = ""
        state.tree.length === 3 && state.tree.pop()
        return
      }

      // Cross Plot
      if (selectedTab) {
        const xAxis = state.numericOptions.find((option: ICategorizeOption) => option.id === selectedTab.xTagAttributeId)
        const yAxis = state.numericOptions.find((option: ICategorizeOption) => option.id === selectedTab.yTagAttributeId)

        selectedTab.xAxis = xAxis?.name
        selectedTab.yAxis = yAxis?.name
      }

      const filtersTree = state.tree.find((node: TreeViewDataItem) => node.name === "Filters")
      const filters: IFilterLegend[] = []
      // filtersTree?.items.forEach((node: TreeViewDataItem) => {
      //     node.items.forEach((item: TreeViewDataItem) => {
      //         if (item.filterApplied) {
      //             const LegendValueFromConditionInputs = [{ list: item.firstConditionList, input: item.firstConditionInput }, { list: item.secondConditionList, input: item.secondConditionInput }].reduce((acc, filterItem) => {
      //                 if (filterItem.input) {
      //                     acc += `${acc ? ` ${item.logicalOperationList?.name}` : ''} ${filterItem?.list?.name} ${filterItem?.input}`
      //                 }
      //                 return acc
      //             }, '')
      //             const finalValues: string[] = []
      //             if (item.values?.length) {
      //                 finalValues.push(...item.values)
      //             }
      //             if (LegendValueFromConditionInputs) {
      //                 finalValues.push(LegendValueFromConditionInputs)
      //             }

      //             filters.push({
      //                 filter: item.name,
      //                 values: finalValues,
      //             });
      //         }
      //     });
      // })
      state.filtersTreeView.forEach((filterItem) => {
        filterItem?.items.forEach((node: TreeViewDataItem) => {
          node.items.forEach((item) => {
            if (item.filterApplied) {
              const LegendValueFromConditionInputs = [
                { list: item.firstConditionList, input: item.firstConditionInput },
                { list: item.secondConditionList, input: item.secondConditionInput },
              ].reduce((acc, filterItem) => {
                if (filterItem.input) {
                  acc += `${acc ? ` ${item.logicalOperationList?.name}` : ""} ${filterItem?.list?.name} ${filterItem?.input}`
                }
                return acc
              }, "")
              const finalValues: string[] = []
              if (item.values?.length) {
                finalValues.push(...item.values)
              }
              if (LegendValueFromConditionInputs) {
                finalValues.push(LegendValueFromConditionInputs)
              }

              filters.push({
                filter: item.name,
                values: finalValues,
              })
            }
            item.items.forEach((item: TreeViewDataItem) => {
              if (item.filterApplied) {
                const LegendValueFromConditionInputs = [
                  { list: item.firstConditionList, input: item.firstConditionInput },
                  { list: item.secondConditionList, input: item.secondConditionInput },
                ].reduce((acc, filterItem) => {
                  if (filterItem.input) {
                    acc += `${acc ? ` ${item.logicalOperationList?.name}` : ""} ${filterItem?.list?.name} ${filterItem?.input}`
                  }
                  return acc
                }, "")
                const finalValues: string[] = []
                if (item.values?.length) {
                  finalValues.push(...item.values)
                }
                if (LegendValueFromConditionInputs) {
                  finalValues.push(LegendValueFromConditionInputs)
                }
                filters.push({
                  filter: item.name,
                  values: finalValues,
                })
              }
            })
          })
        })
      })
      state.activeFiltersLegend = filters
    },
    updateTabOrder: (state, action) => {
    const plotIdAndTabOrder = action.payload

    const newTabs = [...state.tabs]

    plotIdAndTabOrder.forEach((item: { plotId: string; order: number }) => {
        const tab = newTabs.find((tab) => tab.id === item.plotId)
        if (tab) {
            tab.order = item.order
        }
    })

    newTabs.sort((a, b) => (a.order !== undefined && b.order !== undefined) ? a.order - b.order : 0)

    state.tabs = newTabs
    },
  },
  extraReducers: {
    [saveMapOptionsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },

    [T_GetUserActions.fulfilled.type]: (state, action: PayloadAction<IUserActions>) => {
      state.userActions = action.payload
    },

    [T_GetUserActions.rejected.type]: (state) => {
      state.isLoading = false
    },

    [getAllWorkspacesThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [getAllWorkspacesThunk.fulfilled.type]: (state, action: PayloadAction<IWorkspaceItem[]>) => {
      state.workspaces = action.payload
      state.isLoading = false
      state.isWorkspaceListFetched = true
      state.error = null
    },

    [getAllWorkspacesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
      state.isWorkspaceListFetched = true
    },

    [T_addWorkspace.pending.type]: (state) => {
      state.workspaceListLoading = true
    },
    [T_addWorkspace.fulfilled.type]: (state) => {
      state.workspaceListLoading = false
    },

    [T_editWorkspace.pending.type]: (state) => {
      state.workspaceListLoading = true
    },

    [T_editWorkspace.fulfilled.type]: (state, action) => {
      const workspace = state.workspaces.find((workspace: IWorkspaceItem) => workspace.id === action.payload.id)
      if (workspace) workspace.title = action.payload.title
      state.workspaceListLoading = false
    },

    [T_editWorkspaceFilters.rejected.type]: (state) => {
      state.workspaceListLoading = false
      state.workspaceEdited = false
    },

    [T_editWorkspaceFilters.pending.type]: (state) => {
      state.workspaceListLoading = true
    },

    [T_editWorkspaceFilters.fulfilled.type]: (state) => {
      state.workspaceListLoading = false
      state.workspaceEdited = true
    },

    [getWorkspaceByIdThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [getWorkspaceByIdThunk.fulfilled.type]: (
      state,
      action: PayloadAction<{ tabs: ITab[]; workspace: IWorkspace; allTags: (ITagAttribute & IOptions)[]; projectOptions: IProjectViewOption[]; tabId?: string }>,
    ) => {
      const { id, plots, filterOptions, categorizeOptions } = action.payload.workspace
      state.filters.categorizeOptions = categorizeOptions
      state.workspaceId = id
      state.tabs = action.payload.tabs
      state.plots = action.payload.workspace.plots
      state.isLoading = false
      state.error = null
      saveWorkSpaceToLS(id)
      state.mapOptions = action.payload.workspace.mapViewOptions
      const selectedTab = action.payload.tabs.find((tab: ITab) => tab.isSelected)
      const selectedPlot = plots.find((plot: IPlot) => plot.id === selectedTab?.id) || plots[0] // in the future, it will be removed

      if (selectedPlot && selectedPlot.type === 1) {
        state.filters = { ...state.filters, ...selectedPlot }
        state.filters.id = selectedPlot.id
        state.filters.title = selectedPlot.title
        state.filters.xTagAttributeId = selectedPlot.xTagAttributeId
        state.filters.yTagAttributeId = selectedPlot.yTagAttributeId
      }

      if (filterOptions?.filters?.length) {
        state.filters.filterOptions = { ...state.filters.filterOptions, ...filterOptions }
      } else {
        state.filters.filterOptions = {
          ...state.filters.filterOptions,
          filters: [],
        }
      }

      const filtersTree = state.tree.find((node: TreeViewDataItem) => node.name === "Filters")
      const filters: IFilterLegend[] = []
      filtersTree?.items.forEach((node: TreeViewDataItem) => {
        node.items.forEach((item: TreeViewDataItem) => {
          if (item.filterApplied) {
            const LegendValueFromConditionInputs = [
              { list: item.firstConditionList, input: item.firstConditionInput },
              { list: item.secondConditionList, input: item.secondConditionInput },
            ].reduce((acc, filterItem) => {
              if (filterItem.input) {
                acc += `${acc ? ` ${item.logicalOperationList?.name}` : ""} ${filterItem?.list?.name} ${filterItem?.input}`
              }
              return acc
            }, "")
            const finalValues: string[] = []
            if (item.values?.length) {
              finalValues.push(...item.values)
            }
            if (LegendValueFromConditionInputs) {
              finalValues.push(LegendValueFromConditionInputs)
            }

            filters.push({
              filter: item.name,
              values: finalValues,
            })
          }
        })
      })
      state.activeFiltersLegend = filters
    },

    [getWorkspaceByIdThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },

    [getSideBarFiltersThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [getSideBarFiltersThunk.fulfilled.type]: (
      state,
      action: PayloadAction<{
        filtersTree: TreeViewDataItem[]
        numericOptions: ICategorizeOption[]
        stringOptions: ICategorizeOption[]
      }>,
    ) => {
      state.isLoading = false
      state.error = null
      const { filtersTree, numericOptions, stringOptions } = action.payload
      state.filtersTreeView[0].items = filtersTree
      state.numericOptions = numericOptions
      state.stringOptions = stringOptions
      state.categoriseOptions = [...stringOptions, ...numericOptions].sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      state.filtersTreeView[0].items = state.filtersTreeView[0].items.map((item: TreeViewDataItem) => {
        item.items.map((subItem: TreeViewDataItem) => {
          if (subItem.values) {
            const filter: IFilter | undefined = state.filters.filterOptions.filters.find((filter: IFilter) => filter.tagAttributeId === subItem.id)
            if (filter) {
              subItem.values = filter.values
              subItem.filterApplied = true
              subItem.firstConditionInput = filter.partOne?.value
              subItem.secondConditionInput = filter.partTwo?.value
              // todo fix it with typings
              subItem.firstConditionList!.id = filter.partOne!.operationId as number
              subItem.firstConditionList!.name = (subItem.type === "numeric" ? filter.partOne!.operationNameNumeric : filter.partOne!.operationNameString) as string
              subItem.secondConditionList!.id = filter.partTwo!.operationId as number
              subItem.secondConditionList!.name = (subItem.type === "numeric" ? filter.partTwo!.operationNameNumeric : filter.partTwo!.operationNameString) as string
              subItem.logicalOperationList!.id = filter.booleanOperationId as number
            } else {
              subItem.filterApplied = false
            }
            return subItem
          } else {
            return subItem.items.map((nestedSubitem) => {
              const filter: IFilter | undefined = state.filters.filterOptions.filters.find((filter: IFilter) => filter.tagAttributeId === nestedSubitem.id)
              if (filter) {
                nestedSubitem.values = filter.values
                nestedSubitem.filterApplied = true
                nestedSubitem.firstConditionInput = filter.partOne?.value
                nestedSubitem.secondConditionInput = filter.partTwo?.value
                // todo fix it with typings
                nestedSubitem.firstConditionList!.id = filter.partOne!.operationId as number
                nestedSubitem.firstConditionList!.name = (nestedSubitem.type === "numeric" ? filter.partOne!.operationNameNumeric : filter.partOne!.operationNameString) as string
                nestedSubitem.secondConditionList!.id = filter.partTwo!.operationId as number
                nestedSubitem.secondConditionList!.name = (nestedSubitem.type === "numeric" ? filter.partTwo!.operationNameNumeric : filter.partTwo!.operationNameString) as string
                nestedSubitem.logicalOperationList!.id = filter.booleanOperationId as number
              } else {
                nestedSubitem.filterApplied = false
              }
              return nestedSubitem
            })
          }
        })

        return item
      })

      // state.filtersTreeView[0].items = state.filtersTreeView[0].items.map((item: TreeViewDataItem) => {
      //     item.items.map((subItem: TreeViewDataItem) => {
      //         const filter: IFilter | undefined = state.filters.filterOptions.filters.find((filter: IFilter) => filter.tagAttributeId === subItem.id);
      //         if (filter) {
      //             subItem.values = filter.values

      //             subItem.filterApplied = true;
      //             subItem.firstConditionInput = filter.partOne?.value;
      //             subItem.secondConditionInput = filter.partTwo?.value;
      //             // todo fix it with typings
      //             subItem.firstConditionList!.id = filter.partOne!.operationId as number;
      //             subItem.secondConditionList!.id = filter.partTwo!.operationId as number;
      //             subItem.logicalOperationList!.id = filter.booleanOperationId as number;
      //         } else {

      //             subItem.filterApplied = false;
      //         }
      //         return subItem;
      //     });

      //     return item;
      // });

      const filtersTreeClone = state.filtersTreeView.find((node: TreeViewDataItem) => node.name === "Filters")
      const filters: IFilterLegend[] = []
      state.filtersTreeView.forEach((filterItem) => {
        filterItem?.items.forEach((node: TreeViewDataItem) => {
          if (node.element === "EntryType") {
            node.items.forEach((item: TreeViewDataItem) => {
              if (item.filterApplied) {
                const LegendValueFromConditionInputs = [
                  { list: item.firstConditionList, input: item.firstConditionInput },
                  { list: item.secondConditionList, input: item.secondConditionInput },
                ].reduce((acc, filterItem) => {
                  if (filterItem.input) {
                    acc += `${acc ? ` ${item.logicalOperationList?.name}` : ""} ${filterItem?.list?.name} ${filterItem?.input}`
                  }
                  return acc
                }, "")
                const finalValues: string[] = []
                if (item.values?.length) {
                  finalValues.push(...item.values)
                }
                if (LegendValueFromConditionInputs) {
                  finalValues.push(LegendValueFromConditionInputs)
                }

                filters.push({
                  filter: item.name,
                  values: finalValues,
                })
              }
            })
          } else {
            node.items.forEach((item) => {
              if (item.filterApplied) {
                const LegendValueFromConditionInputs = [
                  { list: item.firstConditionList, input: item.firstConditionInput },
                  { list: item.secondConditionList, input: item.secondConditionInput },
                ].reduce((acc, filterItem) => {
                  if (filterItem.input) {
                    acc += `${acc ? ` ${item.logicalOperationList?.name}` : ""} ${filterItem?.list?.name} ${filterItem?.input}`
                  }
                  return acc
                }, "")
                const finalValues: string[] = []
                if (item.values?.length) {
                  finalValues.push(...item.values)
                }
                if (LegendValueFromConditionInputs) {
                  finalValues.push(LegendValueFromConditionInputs)
                }

                filters.push({
                  filter: item.name,
                  values: finalValues,
                })
              }
              item.items.forEach((item: TreeViewDataItem) => {
                if (item.filterApplied) {
                  const LegendValueFromConditionInputs = [
                    { list: item.firstConditionList, input: item.firstConditionInput },
                    { list: item.secondConditionList, input: item.secondConditionInput },
                  ].reduce((acc, filterItem) => {
                    if (filterItem.input) {
                      acc += `${acc ? ` ${item.logicalOperationList?.name}` : ""} ${filterItem?.list?.name} ${filterItem?.input}`
                    }
                    return acc
                  }, "")
                  const finalValues: string[] = []
                  if (item.values?.length) {
                    finalValues.push(...item.values)
                  }
                  if (LegendValueFromConditionInputs) {
                    finalValues.push(LegendValueFromConditionInputs)
                  }

                  filters.push({
                    filter: item.name,
                    values: finalValues,
                  })
                }
              })
            })
          }
        })
      })
      state.activeFiltersLegend = filters
      state.tree[0] = state.filtersTreeView[0]
    },

    [getSideBarFiltersThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },

    [GetTagAttributeAllowedValuesThunk.pending.type]: (state) => {
      state.loadAllowedValues = true
    },

    [GetTagAttributeAllowedValuesThunk.fulfilled.type]: (state, action) => {
      state.loadAllowedValues = false
      state.error = null
      const { tagId, values } = action.payload
      // todo remove tagAttributeAllowedValues from state and move it on treeview
      state.tagAttributeAllowedValues = [
        {
          tagId,
          values: values.map((text: string) => {
            const filter = state.filters.filterOptions.filters.find((filter) => filter.tagAttributeId === tagId)
            return {
              text,
              checked: filter ? filter.values?.includes(text) : false,
            }
          }),
        },
      ]
      const itemHierarchicalIndex = action.meta.arg.props.itemHierarchicalIndex

      const item = getItemByHierarchicalIndex(itemHierarchicalIndex, state.tree)
      if (item) {
        item.values = state.tagAttributeAllowedValues?.[0]?.values?.filter((value) => value?.checked)?.map((value) => value?.text)
      }
    },

    [GetTagAttributeAllowedValuesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadAllowedValues = false
      state.error = action.payload
    },

    [savePlotOptionsThunk.pending.type]: (state) => {
      state.error = null
    },

    [savePlotOptionsThunk.fulfilled.type]: (state) => {
      state.isLoading = false
      state.error = null
    },

    [savePlotOptionsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },

    [T_getProjectView.pending.type]: (state) => {
      state.isLoading = true
    },

    [T_getProjectView.fulfilled.type]: (state, action) => {
      const selectedTab = state.tabs.find((item) => item.isSelected)
      if (selectedTab && action.payload.tags) {
        const currentPlot = state.plots.findIndex((plot: IPlot) => plot.id === selectedTab.id)
        const normalized = normalizeByKey(action.payload.tags, "key")
        if (currentPlot > -1) {
          state.plots[currentPlot] = { ...state.plots[currentPlot], identifierFieldId: action.payload.id, ...normalized }
        }
        const projectViewOptionsItems = state.projectViewTreeView.items.find((item) => item.element === "projectViewoption")
        if (projectViewOptionsItems) {
          const selected = projectViewOptionsItems.options?.find((item) => item.parentId === action.payload.id)
          const selectedItemFromFull = state.projectViewOptions
          projectViewOptionsItems.selected = selected || (selectedItemFromFull as unknown as IOptions)
          const tabIndex = state.tabs.findIndex((item) => item.id === action.payload.plotId)
          if (tabIndex > -1) {
            state.tabs[tabIndex].id = action.payload.plotId
            if (selected) state.tabs[tabIndex].parentId = selected?.parentId
          }
        }
      }
      state.filters = { ...state.filters, ...action.payload }
      state.isLoading = false
      state.error = null
    },

    [T_getProjectView.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },

    [setSharedUsersThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [setSharedUsersThunk.fulfilled.type]: (state, action) => {
      const currentWorkspaceIndex = state.workspaces.findIndex((workspace: IWorkspaceItem) => workspace.id == action.payload.id)
      state.workspaces[currentWorkspaceIndex] = action.payload
      state.isLoading = false
    },

    [setSharedUsersThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [T_getStyleSettings.pending.type]: (state) => {
      state.error = null
    },
    [T_getStyleSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    [T_getStyleSettings.fulfilled.type]: (state, action: PayloadAction<IStyleSettings>) => {
      state.error = null
      state.styleSettings = action.payload
    },

    [T_changeStyleSettings.pending.type]: (state) => {
      state.isLoading = true
      state.error = null
    },
    [T_changeStyleSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [T_changeStyleSettings.fulfilled.type]: (state, action: PayloadAction<IStyleSettings>) => {
      state.isLoading = false
      state.error = null
      // state.styleSettings = action.payload
    },

    [T_getAxisStyleSettings.pending.type]: (state) => {
      state.isLoading = true
      state.error = null
    },
    [T_getAxisStyleSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [T_getAxisStyleSettings.fulfilled.type]: (state, action: PayloadAction<IAxisStyleSettings>) => {
      state.isLoading = false
      state.error = null
      state.axisStyleSettings = action.payload
    },
  },
})

export default workspaceSlice.reducer;


const selectSelectedTabUncached = (state: RootState) => state.workspaceSlice.tabs.find((tab: ITab) => tab.isSelected);
// need to be sure that caching works
export const selectSelectedTab = createSelector(
    selectSelectedTabUncached,
    (state: RootState) => state,
    (tab) => tab
);