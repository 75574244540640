import { createAsyncThunk } from '@reduxjs/toolkit';
import { Company } from './types';
import { createCompany, getAllCompanies, updateCompany, deleteCompany } from '../../api/company/companyService';
import { PaginationInterface } from 'store/license/types';

export const createCompanyThunk = createAsyncThunk('company/create', async (credentials: Company, thunkAPI) => {
    const response = await createCompany(credentials, thunkAPI)
    return response
});

export const getAllCompaniesThunk = createAsyncThunk('company/getAllUsers', async (credentials:PaginationInterface, thunkAPI,) => {
    const response = await getAllCompanies(credentials, thunkAPI)
    return response
});

export const updateCompanyThunk = createAsyncThunk('company/update', async (credentials: Company, thunkAPI) => {
    const response = await updateCompany(credentials, thunkAPI)
    return response
});

export const deleteCompanyThunk = createAsyncThunk('company/delete', async (credentials: string, thunkAPI) => {
    const response = await deleteCompany(credentials, thunkAPI)
    return response
});


