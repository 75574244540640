import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBooleanOperationsThunk, getNumericOperationsThunk, getStringOperationsThunk, TH_GetAllTypes, getPlotOptionsThunk, getQuickPlotsThunk, deleteQuickPlotThunk, saveQuickPlotThunk, setQuickPlotThunk, saveSidebarThunk, getCategory1TagAttributesValuesThunk, getNumericTagAttributesValuesThunk, getHistogramOptionsThunk, saveSidebarHistogramThunk, selectQuickPlotThunk, T_getProjectViewOptions, getProductionTagAttributesValuesThunk, T_getProducrtionOptions, T_getProductionPlotIndentifierOptions, T_addFilterset, T_getFilterset, T_deleteFilterset, getBenchmarkPlotOptionsThunk } from './thunk';
import { ICondition, ITagAttribute, sideBarTreeState, PlotOptions, IOptions, TagAttributeValue, IHistogramOptions, CrossPlotOptions, IProductionPlotOptions, IProductionOptions, IDropDownData, IProductionDropdownData, IFilterSet, IBenchmarkPlotOptions } from './types';

const initialState: sideBarTreeState = {
  isLoading: false,
  error: null,
  plotOptionsChanged: false,
  isSidebarOpen: false,
  filtersSearchText: '',
  stringOperations: [],
  numericOperations: [],
  booleanOperations: [],
  tagAttributes: [],
  quickPlots: [],
  category1TagAttributesValues: [],
  productionTagAttributesValues: [],
  numericTagAttributesValues: [],
  filterSets: {
    selected: null,
    list: [],
    loading: false,
    error: null
  },
  histogramOptions: {
    id: '',
    title: '',
    quickPlotId: '',
    type: null,
    xTagAttributeId: '',
    xMin: null,
    xMax: null,
    xGridlines: false,
    yGridlines: false,
    xLogarithmic: false,
    yLogarithmic: false,
    yInvert: false,
    showFilters: false,
    showAxisLabels: false,
    bins: 10,
    statsPrecision: 2,
    histogramOptions:{}
  },
  plotOptions: {
    id: '',
    title: '',
    quickPlotId: null,
    type: 1,
    xTagAttributeId: '',
    yTagAttributeId: '',
    crossPlotOptions: {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null,
      xGridlines: false,
      yGridlines: false,
      xLogarithmic: false,
      yLogarithmic: false,
      xInvert: false,
      yInvert: false,
      linRegression: false,
      logRegression: false,
      expRegression: false,
      powRegression: false,
      intcp: false,
      intcpValue: null,
      addPoint: false,
      xValue: null,
      label: '',
      showFilters: false,
      showUserData: false,
      showRegressionEquations: false,
      showUserDataLabels: false,
      showUserBackgroundLabels: false,
      globalDatabaseLabels: false,
      showAxisLabels: false,
      tooltips: []
    }
  },
  benchmarkPlotOptions: {
    id: null,
    benchmarkPlotOptions: {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null,
      xGridlines: false,
      yGridlines: false,
      xLogarithmic: false,
      yLogarithmic: false,
      xInvert: false,
      yInvert: false,
      linRegression: false,
      logRegression: false,
      expRegression: false,
      powRegression: false,
      intcp: false,
      intcpValue: null,
      addPoint: false,
      xValue: null,
      label: '',
      showFilters: false,
      showUserData: false,
      showRegressionEquations: false,
      showUserDataLabels: false,
      showUserBackgroundLabels: false,
      globalDatabaseLabels: false,
      showAxisLabels: false,
    }
  },
  productionPlot: {
    id: '',
    title: '',
    xTagAttributeId: '',
    yTagAttributeId: '',
    y2TagAttributeId: '',
    productionPlotOptions: {
      // plot options
      xMin: null,
      xMax: null,
      xMinDate: null,
      xMaxDate: null,
      yMin: null,
      yMax: null,
      y2Min: null,
      y2Max: null,
      xGridlines: false,
      yGridlines: false,
      xLogarithmic: false,
      yLogarithmic: false,
      y2Logarithmic: false,
      xInvert: false,
      yInvert: false,
      y2Invert: false,
      cascadeFieldsAcrossAllProfiles: false,
      // legends
      showUserData: false,
      showUserDataLabels: false,
      showUserBackgroundLabels: false,
      globalDatabaseLabels: false,
      showAxisLabels: false,

      showFilters: false,
      showSeries: false,
      showRegressionEquations: false,
      // regression
      xStart: null,
      xEnd: null,
      xForecast: null,
      linRegression: false,
      logRegression: false,
      expRegression: false,
      powRegression: false,
      addPoint: false,
      intcp: false,
      intcpValue: null,
      xValue: null,
      xForecastDate: null,
      xStartDate: null,
      xEndDate: null,
      xValueDate: null,
      yValue: null,
      label: '',
      productionPlotSeries: [],
      productionInfoColumns: []
    },
    plotAxisTitleOptions: {}
  },
  productionPlotIndetifiers: {
    options: [],
    isLoading: false,
    error: null
  },
  projectViewOptions: {
    items: [],
    value: null
  }
}

export const sideBarTreeSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlotOptionsChanged: (state: sideBarTreeState, action: PayloadAction<boolean>) => {
      state.plotOptionsChanged = action.payload;
    },

    setSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload
    },

    setFiltersSearchText: (state, action) => {
      state.filtersSearchText = action.payload
    },

    setPlotOptionItem: (state, action) => {
      const { element, group, value, index } = action.payload;
      if (group) {
        if (element.includes('tooltip')) {
          if (index > -1) {
            if (!state.plotOptions.crossPlotOptions.tooltips[index]) {
              state.plotOptions.crossPlotOptions.tooltips = new Array(5).fill(null).map((item, i) => (
                i === index ? value : state.plotOptions.crossPlotOptions.tooltips[i] || null
              ))
            } else {
              state.plotOptions.crossPlotOptions.tooltips[index].id = value.id;
              state.plotOptions.crossPlotOptions.tooltips[index].text = value.text;
            }
          }
          // state.plotOptions.crossPlotOptions.tooltips[element as keyof Tooltips] = value as never;
        } else {
          state.plotOptions.crossPlotOptions[element as keyof CrossPlotOptions] = value as never;
        }
      } else {

        state.plotOptions[element as keyof PlotOptions] = element === "type" ? +value as never : value as never;
      }
      state.plotOptionsChanged = true;
    },
    setBenchmarkPlotOptionItem: (state, action) => {
      const { element, group, value, index } = action.payload;
      if (group) {
          state.benchmarkPlotOptions.benchmarkPlotOptions[element as keyof IBenchmarkPlotOptions['benchmarkPlotOptions']] = value as never;
      } else {
        state.benchmarkPlotOptions.benchmarkPlotOptions[element as keyof IBenchmarkPlotOptions['benchmarkPlotOptions']] = element === "type" ? +value as never : value as never;
      }
      state.plotOptionsChanged = true;
    },
    setProjectViewOptions: (state, action) => {
      state.projectViewOptions.value = action.payload
    },
    changeProductionPlotSeriesCheckbox: (state, action) => {
      const { index } = action.payload
      if (state.productionPlot?.productionPlotOptions?.productionPlotSeries?.[index]) {
        const value = state.productionPlot.productionPlotOptions.productionPlotSeries[index]?.isChecked
        state.productionPlot.productionPlotOptions.productionPlotSeries[index].isChecked = !value
      }
    },
    changeProductionPlotColumns: (state, action) => {
      state.productionPlot.productionPlotOptions.productionInfoColumns = action.payload
    },
    changeProductionPlotSeries: (state, action) => {
      state.productionPlot.productionPlotOptions.productionPlotSeries = action.payload
    },
    setProductionPlotOptionItem: (state, action) => {
      const { element, group, value, index, tagsCount } = action.payload;

      if (group) {
        if (element.includes('tag')) {
          if (index > -1) {
            if (!state.productionPlot.productionPlotOptions.productionPlotSeries[index]) {
              state.productionPlot.productionPlotOptions.productionPlotSeries = new Array(tagsCount).fill(null).map((item, i) => (
                i === index ? { identifierFieldId: value as string, isChecked: true } : state.productionPlot.productionPlotOptions.productionPlotSeries[i] || null
              )).filter(item => item)
            } else {
              state.productionPlot.productionPlotOptions.productionPlotSeries[index] = { identifierFieldId: value as string, isChecked: true };
            }
          }
          // state.productionPlot.productionPlotOptions.tooltips[element as keyof Tooltips] = value as never;
        } else {
          state.productionPlot.productionPlotOptions[element as keyof IProductionPlotOptions] = value as never;
        }
      } else {
        if (element === 'title') {
          state.productionPlot.title = value as never;
        }
        state.productionPlot[element as keyof IProductionOptions] = element === "type" ? +value as never : value as never;
      }
    },
    setHistogramOptionItem: (state, action) => {
      const { element, value } = action.payload;
      state.histogramOptions[element as keyof IHistogramOptions] = element === "type" ? +value as never : value as never;
      state.plotOptionsChanged = true;
    },
    setSelectedFilterSet: (state, action) => {
      state.filterSets.selected = action.payload;
    }
  },
  extraReducers: {
    [getStringOperationsThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getStringOperationsThunk.fulfilled.type]: (state, action: PayloadAction<ICondition[]>) => {
      state.stringOperations = action.payload;
      state.isLoading = false;
    },

    [getStringOperationsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getCategory1TagAttributesValuesThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getCategory1TagAttributesValuesThunk.fulfilled.type]: (state, action: PayloadAction<TagAttributeValue[]>) => {
      state.category1TagAttributesValues = action.payload;
      state.isLoading = false;
    },

    [getCategory1TagAttributesValuesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getProductionTagAttributesValuesThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getProductionTagAttributesValuesThunk.fulfilled.type]: (state, action: PayloadAction<IProductionDropdownData[]>) => {
      state.productionTagAttributesValues = action.payload;
      state.isLoading = false;
    },

    [getProductionTagAttributesValuesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },


    [getNumericTagAttributesValuesThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getNumericTagAttributesValuesThunk.fulfilled.type]: (state, action: PayloadAction<TagAttributeValue[]>) => {
      state.numericTagAttributesValues = action.payload;
      state.isLoading = false;
    },

    [getNumericTagAttributesValuesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getNumericOperationsThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getNumericOperationsThunk.fulfilled.type]: (state, action: PayloadAction<ICondition[]>) => {
      state.numericOperations = action.payload;
      state.isLoading = false;
    },

    [getNumericOperationsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getBooleanOperationsThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getBooleanOperationsThunk.fulfilled.type]: (state, action: PayloadAction<ICondition[]>) => {
      state.booleanOperations = action.payload;
      state.isLoading = false;
    },

    [getBooleanOperationsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [TH_GetAllTypes.pending.type]: (state) => {
      state.isLoading = true;
    },

    [TH_GetAllTypes.fulfilled.type]: (state, action: PayloadAction<ITagAttribute[]>) => {
      state.tagAttributes = action.payload;
      state.isLoading = false;
    },

    [TH_GetAllTypes.rejected.type]: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isLoading = false;
    },

    [getPlotOptionsThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getPlotOptionsThunk.fulfilled.type]: (state, action: PayloadAction<PlotOptions>) => {
      state.plotOptions = action.payload;
      state.isLoading = false;
    },

    [getPlotOptionsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getBenchmarkPlotOptionsThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getBenchmarkPlotOptionsThunk.fulfilled.type]: (state, action: PayloadAction<IBenchmarkPlotOptions>) => {
      state.benchmarkPlotOptions.benchmarkPlotOptions = action.payload.benchmarkPlotOptions;
      state.benchmarkPlotOptions.id = action.payload.id;
      state.benchmarkPlotOptions.benchmark = action.payload.benchmark;
      state.isLoading = false;
    },

    [getBenchmarkPlotOptionsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    

    [getHistogramOptionsThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getHistogramOptionsThunk.fulfilled.type]: (state, action: PayloadAction<IHistogramOptions>) => {
      state.histogramOptions = action.payload;
      state.isLoading = false;
    },

    [getHistogramOptionsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getQuickPlotsThunk.fulfilled.type]: (state, action: PayloadAction<IOptions[]>) => {
      state.isLoading = false
      state.error = null
      state.quickPlots = action.payload
    },
    [getQuickPlotsThunk.rejected.type]: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    [saveQuickPlotThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [saveQuickPlotThunk.fulfilled.type]: (state) => {
      state.isLoading = false
      state.error = null
    },

    [saveQuickPlotThunk.rejected.type]: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    [setQuickPlotThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [setQuickPlotThunk.fulfilled.type]: (state, action) => {
      state.isLoading = false
      const field: 'histogramOptions' | 'plotOptions' = action.payload.field || 'plotOptions'
      state[field] = action.payload.data;
      state[field].quickPlotId = action.payload.data.quickPlotId
    },

    [setQuickPlotThunk.pending.type]: (state) => {
      state.isLoading = false
    },

    [deleteQuickPlotThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [deleteQuickPlotThunk.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.quickPlots = state.quickPlots.filter(o => o.id !== action.payload);
      if (state.plotOptions.quickPlotId === action.payload) {
        state.plotOptions.quickPlotId = null
      }
    },

    [saveSidebarThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [saveSidebarThunk.fulfilled.type]: (state) => {
      state.isLoading = false
      state.error = null
    },

    [saveSidebarThunk.rejected.type]: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    [saveSidebarHistogramThunk.pending.type]: (state) => {
      state.isLoading = true
    },

    [saveSidebarHistogramThunk.fulfilled.type]: (state) => {
      state.isLoading = false
      state.error = null
    },

    [saveSidebarHistogramThunk.rejected.type]: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    [selectQuickPlotThunk.pending.type]: state => {
      state.isLoading = true
    },

    [selectQuickPlotThunk.fulfilled.type]: (state, { payload }) => {
      state.plotOptions.xTagAttributeId = payload.xTagAttributeId
      state.plotOptions.yTagAttributeId = payload.yTagAttributeId
      state.plotOptions.quickPlotId = payload.quickPlotId
      state.isLoading = false;
    },

    [selectQuickPlotThunk.rejected.type]: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },

    [T_getProjectViewOptions.pending.type]: state => {
      state.isLoading = true
    },

    [T_getProjectViewOptions.fulfilled.type]: (state, { payload }) => {
      state.projectViewOptions.items = payload
      state.projectViewOptions.value = null
      state.isLoading = false;
    },

    [T_getProjectViewOptions.rejected.type]: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },

    [T_getProducrtionOptions.pending.type]: (state) => {
      state.isLoading = true;
    },

    [T_getProducrtionOptions.fulfilled.type]: (state, action: PayloadAction<IProductionOptions>) => {
      state.productionPlot = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    [T_getProducrtionOptions.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },


    [T_getProductionPlotIndentifierOptions.pending.type]: (state) => {
      state.productionPlotIndetifiers.isLoading = true;
    },

    [T_getProductionPlotIndentifierOptions.fulfilled.type]: (state, action: PayloadAction<(IDropDownData & { labelName: string, identifierFieldId: string, type: number })[]>) => {
      state.productionPlotIndetifiers.options = action.payload;
      state.productionPlotIndetifiers.isLoading = false;
      state.productionPlotIndetifiers.error = null;
    },

    [T_getProductionPlotIndentifierOptions.rejected.type]: (state, action: PayloadAction<string>) => {
      state.productionPlotIndetifiers.isLoading = false;
      state.productionPlotIndetifiers.error = action.payload;
    },


    [T_addFilterset.pending.type]: (state) => {
      state.filterSets.loading = true;
      state.filterSets.error = null;
    },

    [T_addFilterset.fulfilled.type]: (state, action: PayloadAction<(IDropDownData & { labelName: string, identifierFieldId: string })[]>) => {
      state.filterSets.loading = false;
      state.filterSets.error = null;
    },

    [T_addFilterset.rejected.type]: (state, action: PayloadAction<string>) => {
      state.filterSets.loading = false;
      state.filterSets.error = action.payload;
    },


    [T_getFilterset.pending.type]: (state) => {
      state.filterSets.loading = true;
      state.filterSets.error = null;
    },

    [T_getFilterset.fulfilled.type]: (state, action: PayloadAction<{ filterSets: IFilterSet[], selectedFilterSetId: string, selectedFilterSetTitle: string }>) => {
      state.filterSets.list = action.payload.filterSets;
      if (action.payload.selectedFilterSetTitle && action.payload.selectedFilterSetId) {
        state.filterSets.selected = { title: action.payload.selectedFilterSetTitle, id: action.payload.selectedFilterSetId };
      } else {
        state.filterSets.selected = null
      }

      state.filterSets.loading = false;
      state.filterSets.error = null;
    },

    [T_getFilterset.rejected.type]: (state, action: PayloadAction<string>) => {
      state.filterSets.loading = false;
      state.filterSets.error = action.payload;
    },



    [T_deleteFilterset.pending.type]: (state) => {
      state.filterSets.loading = true;
      state.filterSets.error = null;
    },

    [T_deleteFilterset.fulfilled.type]: (state, action: PayloadAction<(IDropDownData & { labelName: string, identifierFieldId: string })[]>) => {
      state.filterSets.loading = false;
      state.filterSets.error = null;
    },

    [T_deleteFilterset.rejected.type]: (state, action: PayloadAction<string>) => {
      state.filterSets.loading = false;
      state.filterSets.error = action.payload;
    },
  }
});

export default sideBarTreeSlice.reducer;

