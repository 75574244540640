import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PROJECTS_CCS_PATHS, PROJECTS_PATHS } from 'helpers/constants/ccs';
import Notifications from './components/Notifications/Notification';
import PrivateRoutes from './components/PrivateRoute'
import Layout from './components/Layout';
import SuspensedPage from 'components/SuspensedPage/SuspensedPage';
import PublicRoutes from 'components/PublicRoute';
import Registration from 'pages/Registration/Registration';
import Private2FARoutes from 'components/PrivateRoute/Routes2FA';
import 'assets/styles/commons.scss'
import NoPermissionPopup from 'components/noPermission/noPermissionPopup';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectActiveUser, selectActiveUserStatus } from 'store/userState/selectors';
import Loading from 'components/Loading/Loading';
import { T_getUserInfo } from 'store/userState/thunk';
import { initGA, setUserToGA } from 'helpers/functions/GoogleAnalytics/GA';
import { getActiveUser } from 'helpers/auth';


const SignIn = lazy(() => import('pages/SignIn/SignIn'))
const ForgotPassword = lazy(() => import('pages/ForgotPassword/ForgotPassword'))
const SignUp = lazy(() => import('pages/SignUp/SignUp'))
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPassword'))
const SignInMFA = lazy(() => import('pages/SignInMFA/SignInMFA'))
const ChangePassword = lazy(() => import('pages/ChangePassword/ChangePassword'))
const AdminSection = lazy(() => import('pages/AdminSection/AdminSection'))
const Home = lazy(() => import('pages/Home/Home'))
const DataPage = lazy(() => import('pages/Data/DataPage'))
const Explore = lazy(() => import('pages/Explore/Explore'))
const Projects = lazy(() => import('pages/Projects/Main'))
const LearnPage = lazy(() => import('pages/Learn/LearnPage'))
const DashboardPage = lazy(() => import('pages/Projects/ccs/dashboard/Main'))
const OilGasPage = lazy(() => import('pages/Projects/oilGas/Main'))
const EorPage = lazy(() => import('pages/Projects/eor/Main'))
const Ccs = lazy(() => import('pages/Projects/ccs/Main'))
const H2Page = lazy(() => import('pages/Projects/h2/Main'))
const GeothermalPage = lazy(() => import('pages/Projects/geothermal/Main'))
const StorageResourcesPage = lazy(() => import('pages/Projects/ccs/storageResources/Main'))
const InjectivityPage = lazy(() => import('pages/Projects/ccs/injectivity/Main'))
const StorageRiskPage = lazy(() => import('pages/Projects/ccs/storageRisk/Main'))
const BookingPage = lazy(() => import('pages/Projects/ccs/booking/Main'))

function App() {
  const dispatch = useAppDispatch()
  const activeUserStore = useAppSelector(selectActiveUser)
  const isSignedIn = useAppSelector(selectActiveUserStatus)
  const activeUser = getActiveUser()
  const [isGAIDSetted, setIsGAIDSetted] = useState(false)
  const [initialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const getUserInfo = async () => {
      setUserToGA(activeUserStore.id)
      setIsGAIDSetted(true)
    }
    if (activeUserStore.id && !isGAIDSetted && initialized) {
      getUserInfo()
    }
  }, [activeUserStore, initialized, isGAIDSetted])

  useEffect(() => {
    const init = async () => {
      await dispatch(T_getUserInfo())
      initGA()
      setIsInitialized(true)
    }
    init()
  }, [])

  return (
    <div className="App">
      {(isSignedIn === 'pending' && activeUser) && <div className='fullScreen' >
        <Loading />
      </div>}
      <Notifications />
      <NoPermissionPopup />
      <Routes>
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/changePassword' element={<SuspensedPage><ChangePassword /></SuspensedPage>} />
          <Route path='/SignInMFA' element={<SuspensedPage><SignInMFA /></SuspensedPage>} />
          <Route path='/' element={<Private2FARoutes />}>
            <Route element={<Layout />}>
              <Route path='home' element={<SuspensedPage><Home /></SuspensedPage>} />
              <Route path='data' element={<SuspensedPage><DataPage /></SuspensedPage>} />
              <Route path='explore' element={<SuspensedPage><Explore /></SuspensedPage>} />
              <Route path="Projects" element={<SuspensedPage><Projects /></SuspensedPage>}>
                <Route index element={<Navigate to={PROJECTS_PATHS.ccs} />} />
                <Route path={PROJECTS_PATHS.ccs} element={<SuspensedPage><Ccs /></SuspensedPage>} >
                <Route path={PROJECTS_PATHS.oilGas} element={<SuspensedPage><OilGasPage /></SuspensedPage>} />
                <Route path={PROJECTS_PATHS.eor} element={<SuspensedPage><EorPage /></SuspensedPage>} />
                  <Route index element={<Navigate to={PROJECTS_CCS_PATHS.dashboard} />} />
                  <Route path={PROJECTS_CCS_PATHS.dashboard} element={<SuspensedPage><DashboardPage /></SuspensedPage>} />
                  <Route path={PROJECTS_CCS_PATHS.storageResource} element={<SuspensedPage><StorageResourcesPage /></SuspensedPage>} />
                  <Route path={PROJECTS_CCS_PATHS.booking} element={<SuspensedPage><BookingPage /></SuspensedPage>} />
                  <Route path={PROJECTS_CCS_PATHS.injectivity} element={<SuspensedPage><InjectivityPage /></SuspensedPage>} />
                  <Route path={PROJECTS_CCS_PATHS.storageRisk} element={<SuspensedPage><StorageRiskPage /></SuspensedPage>} />
                  <Route path={PROJECTS_CCS_PATHS.booking} element={<SuspensedPage><BookingPage /></SuspensedPage>} />
                  <Route path='*' element={<Navigate to={PROJECTS_CCS_PATHS.dashboard} />} />
                </Route>
                <Route path={PROJECTS_PATHS.h2} element={<SuspensedPage><H2Page /></SuspensedPage>} />
                <Route path={PROJECTS_PATHS.geothermal} element={<SuspensedPage><GeothermalPage /></SuspensedPage>} />
                <Route path='*' element={<Navigate to={PROJECTS_PATHS.ccs} />} />
              </Route>
              <Route path='/booking' element={<SuspensedPage><BookingPage /></SuspensedPage>} />
              <Route path='/learn' element={<SuspensedPage><LearnPage /></SuspensedPage>} />
              {/* admin section */}
              <Route path='/admin' element={<SuspensedPage><AdminSection /></SuspensedPage>} />
            </Route>
          </Route>
        </Route>
        <Route path='/' element={<PublicRoutes />}>
          <Route index element={<Navigate to='/signin' />} />
          <Route path='/forgotPassword' element={<SuspensedPage><ForgotPassword /></SuspensedPage>} />
          <Route path='/resetPassword' element={<SuspensedPage><ResetPassword /></SuspensedPage>} />
          <Route path='/signIn' element={<SuspensedPage><SignIn /></SuspensedPage>} />
          <Route path='/registration' element={<SuspensedPage><Registration /></SuspensedPage>} />
          {/* <Route path='/signUp/:token' element={<CheckToken />} /> */}
          <Route path='/signUp' element={<SuspensedPage><SignUp /></SuspensedPage>} />
          <Route path='*' element={<Navigate to={'/signIn'} />} />
        </Route>
        {/* todo crate notfound page */}
        {/* <Route path="*" element={<Dashboard/>}/> */}
      </Routes>
    </div>
  )
}

export default App;
