import Loading from 'components/Loading/Loading';
import React, { Suspense, FC } from 'react';

interface IProps {
    children: JSX.Element
}

const SuspensedPage: FC<IProps> = ({ children }) => {
    return (
        <Suspense fallback={<Loading />}>
            {children}
        </Suspense>
    )
}

export default SuspensedPage