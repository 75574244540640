import { T_CcsState, T_TH_GetCcsProject, T_TH_GetCcsProjectNames, T_TH_CreateCcsProject, T_TH_EditCcsProject, T_TH_DeleteCcsProject, T_TH_EditCcsTagValue, T_TH_DeleteCcsInstance, T_TH_AddCcsInstance, T_CcsProject, T_CcsTag, T_TH_GetCcsRiskStorage, T_TH_GetCcsStorageResource } from "./types";
import { TH_AddCcsInstance, TH_CreateCcsProject, TH_DeleteCcsInstance, TH_DeleteCcsProject, TH_EditCcsProject, TH_EditCcsTagValue, 
  // TH_GetAllTypes, 
  TH_GetCcsProject, TH_GetCcsProjectNames, TH_GetStorageRiskTags, TH_GetCcsStorageResource, TH_EditCcStorage, TH_getMapPopupInfo, TH_CcsBookValue, TH_GetSrmsBookings, TH_editBookingValue, TH_GetCcsInjectivity, TH_CcsTransportShipValue } from "./thunk";
import { T_CcsInjectivityInstanceTypes, T_CcsInstanceTypes, T_CcsStorageResourcesInstanceTypes, T_CcsTabs } from "helpers/types/ccs";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalizeByKey } from "helpers/functions/commons";
import { TH_GetAllTypes } from "store/sidebar/thunk";

// const TEMP_WORKSPACE_ID = '4d3d503b-7b9a-4490-8539-2ad9c6749d76'

const initialState: T_CcsState = {
  workSpaceId: '',
  allTags: [],
  projects: {
    isFetched: false,
    byId: {},
    allIds: [],
    selectedId: ''
  },
  sources: {
    byId: {},
    allIds: []
  },
  storages: {
    byId: {},
    allIds: []
  },
  transports: {
    byId: {},
    allIds: []
  },
  storageResources: {
    loading: false,
    gasFields: {
      byId: {},
      allIds: [],
    },
    oilFields: {
      byId: {},
      allIds: []
    },
    aquiferFields: {
      byId: {},
      allIds: []
    },
  },
  injectivity: {
    loading: false,
    ranking: {
      byId: {},
      allIds: []
    },
    estimation: {
      byId: {},
      allIds: []
    }
  },
  map:{
    locatedId: '',
    loading: false,
    popupData: {
      label: '',
      attributesValues: [],
    }
  },
  booking: {
    isLoading: false,
    srmsBookings: [],
    selectedProject: null
  },
  riskStorage: {
    isLoading: false,
    storages: {
      byId: {},
      allIds: []
    },
    plot: [],
    radarData: []
  },
  distance: 250,
  transportViaShip: false,
  isFetching: false
};

export const ccsSlice = createSlice({
  name: 'ccs',
  initialState,
  reducers: {
    setCcsCurrentWorkSpaceId: (state, action: PayloadAction<T_CcsProject['id']>) => {
      state.distance = initialState.distance
      state.projects = initialState.projects
      state.sources = initialState.sources
      state.storages = initialState.storages
      state.transports = initialState.transports
      state.workSpaceId = action.payload
    },
    setCcsProjectNames: (state, action: PayloadAction<T_TH_GetCcsProjectNames['return']>) => {
      state.projects = action.payload.projects
    },
    sortCcsInstances: (state, action: PayloadAction<{ tagId: T_CcsTag['id'], isAscending: boolean, type: T_CcsInstanceTypes, key?: T_CcsTabs }>) => {
      const { tagId, isAscending, type, key } = action.payload
      const { allIds, byId } = key && type === 'storages' ? state[key][type] : state[type]
      const multiplier = isAscending ? -1 : 1
      allIds.sort((instanceId1, instanceId2) => {
        const tag1Value = byId[instanceId1].tags.byId[tagId]?.value ?? ''
        const tag2Value = byId[instanceId2].tags.byId[tagId]?.value ?? ''
        if (tag1Value > tag2Value) return 1 * multiplier
        if (tag1Value < tag2Value) return -1 * multiplier
        return 0
      })
    },
    sortCcsBookingInstances: (state, action: PayloadAction<{ tagId: T_CcsTag['id'], isAscending: boolean }>) => {
      const { tagId, isAscending } = action.payload
      const multiplier = isAscending ? -1 : 1
      state.booking.srmsBookings.sort((instanceId1, instanceId2) => {
        const tag1Value = instanceId1[tagId as keyof typeof instanceId1] ?? ''
        const tag2Value = instanceId2[tagId as keyof typeof instanceId2]  ?? ''
        if (tag1Value > tag2Value) return 1 * multiplier
        if (tag1Value < tag2Value) return -1 * multiplier
        return 0
      })
    },
    sortCssStorageResources:(state, action: PayloadAction<{ tagId: T_CcsTag['id'], isAscending: boolean, type: T_CcsStorageResourcesInstanceTypes}>) => {
      const { tagId, isAscending, type } = action.payload
      const { allIds, byId } = state.storageResources[type]
      const multiplier = isAscending ? -1 : 1
      allIds.sort((instanceId1 : string, instanceId2: string) => {
        const tag1Value = byId[instanceId1].tags.byId[tagId]?.value ?? ''
        const tag2Value = byId[instanceId2].tags.byId[tagId]?.value ?? ''
        if (tag1Value > tag2Value) return 1 * multiplier
        if (tag1Value < tag2Value) return -1 * multiplier
        return 0
      })
    },
    sortCcsInjectivity:(state, action: PayloadAction<{ tagId: T_CcsTag['id'], isAscending: boolean, type: T_CcsInjectivityInstanceTypes}>) => {
      const { tagId, isAscending, type } = action.payload
      const { allIds, byId } = state.injectivity[type]
      const multiplier = isAscending ? -1 : 1
      allIds.sort((instanceId1 : string, instanceId2: string) => {
        const tag1Value = byId[instanceId1].tags.byId[tagId]?.value ?? ''
        const tag2Value = byId[instanceId2].tags.byId[tagId]?.value ?? ''
        if (tag1Value > tag2Value) return 1 * multiplier
        if (tag1Value < tag2Value) return -1 * multiplier
        return 0
      })
    },
    locateMapItem: (state, action) => {
      if (state.map) state.map.locatedId = action.payload.locatedId
    },
    setSelectedBookedProject: (state, action) => {
      state.booking.selectedProject = action.payload
    },
    restoresrmsBooking: (state)=>{
      state.booking.isLoading = false
      state.booking.srmsBookings = []
    },
  },
  extraReducers: {
    [TH_GetCcsProject.pending.type]: (state) => {
      state.isFetching = true
    },
    [TH_GetCcsProject.fulfilled.type]: (state, action: PayloadAction<T_TH_GetCcsProject['return']>) => {
      state.sources = action.payload.sources
      state.storages = action.payload.storages
      state.transports = action.payload.transports
      state.distance = action.payload.distance
      state.transportViaShip = action.payload.transportViaShip
      state.projects.selectedId = action.payload.id
      state.isFetching = false
    },
    [TH_GetCcsProject.rejected.type]: (state)=> {
      state.isFetching = false
    },
    [TH_GetCcsProjectNames.pending.type]: (state) => {
      state.isFetching = true
    },
    [TH_GetCcsProjectNames.fulfilled.type]: (state) => {
      state.isFetching = false
      state.projects.isFetched = true
    },
    [TH_CreateCcsProject.fulfilled.type]: (state, action: PayloadAction<T_TH_CreateCcsProject['return']>) => {
      const { id } = action.payload
      state.projects.allIds.push(id)
      state.projects.byId[id] = action.payload
      state.projects.selectedId = id

      state.distance = initialState.distance
      state.sources = initialState.sources
      state.storages = initialState.storages
      state.transports = initialState.transports
    },
    [TH_EditCcsProject.fulfilled.type]: (state, action: PayloadAction<T_TH_EditCcsProject['return']>) => {
      const { id, title } = action.payload
      state.projects.byId[id].title = title
    },
    [TH_DeleteCcsProject.fulfilled.type]: (state, action: PayloadAction<T_TH_DeleteCcsProject['return']>) => {
      const idToDelete = action.payload
      delete state.projects.byId[idToDelete]
      state.projects.allIds = state.projects.allIds.filter(id => id !== idToDelete)

      if (!state.projects.allIds.length) {
        state.distance = initialState.distance
        state.projects = initialState.projects
        state.sources = initialState.sources
        state.storages = initialState.storages
        state.transports = initialState.transports
      }
    },
    [TH_AddCcsInstance.fulfilled.type]: (state, action: PayloadAction<T_TH_AddCcsInstance['return']>) => {
      const { data, type } = action.payload
      const currentState = state[type]
      currentState.allIds = [...currentState.allIds, ...data.allIds]
      currentState.byId = { ...currentState.byId, ...data.byId }
    },
    [TH_EditCcsTagValue.fulfilled.type]: (state, action: PayloadAction<T_TH_EditCcsTagValue['return']>) => {
      const { data, type, } = action.payload
      const { id, tags } = data
      tags.find(item => item.key === 'StorageQualityIndex')
      const tagsNormilized = normalizeByKey(tags, 'key')
      
      state[type].byId[id].tags = tagsNormilized
    },
    [TH_CcsBookValue.fulfilled.type]: (state, action: PayloadAction<T_TH_EditCcsTagValue['return']>) => {
      const { data, type } = action.payload
      const { id, book } = data
      state[type].byId[id].book = book
    },
    [TH_CcsTransportShipValue.fulfilled.type]: (state, action: PayloadAction<T_TH_EditCcsTagValue['return']>) => {
      const { data } = action.payload
      state.transportViaShip = data?.dashboard?.transportViaShip ?? false
    },
    [TH_DeleteCcsInstance.fulfilled.type]: (state, action: PayloadAction<T_TH_DeleteCcsInstance['return']>) => {
      const { data, type } = action.payload
      state[type].allIds = state[type].allIds.filter(instanceId => instanceId !== data.id)
      delete state[type].byId[data.id]
    },
    [TH_GetStorageRiskTags.pending.type]: (state) => {
      state.riskStorage.isLoading = true
    },
    [TH_GetStorageRiskTags.fulfilled.type]: (state, action: PayloadAction<T_TH_GetCcsRiskStorage['return']>) => {
      const { data, riskResourcePlot, radarData } = action.payload.data
      state.riskStorage.isLoading = false
      state.riskStorage.storages = data
      state.riskStorage.plot = riskResourcePlot.buckets
      state.riskStorage.radarData = radarData
    },
    [TH_GetAllTypes.fulfilled.type]: (state, action) => {
      state.allTags = action.payload
    },
    [TH_GetCcsStorageResource.fulfilled.type]: (state, action: PayloadAction<T_TH_GetCcsStorageResource['return']>) => {
      const { oilFields, gasFields, aquiferFields } = action.payload.storageResources
      state.storageResources.oilFields = oilFields
      state.storageResources.gasFields = gasFields
      state.storageResources.aquiferFields = aquiferFields

      state.storageResources.loading = false
    },
    [TH_GetCcsStorageResource.pending.type]: (state) => {
      state.storageResources.loading = true
    },
    [TH_EditCcStorage.pending.type]: (state) => {
      state.storageResources.loading = true
    },
    [TH_EditCcStorage.fulfilled.type]: (state) => {
      state.storageResources.loading = false
    },
    [TH_getMapPopupInfo.pending.type]: (state) => {
      state.map.loading = true

    },
    [TH_getMapPopupInfo.fulfilled.type]: (state, action) => {
      state.map.popupData = action.payload
      state.map.loading = false
    },
    [TH_GetSrmsBookings.pending.type]: (state) => {
      state.booking.isLoading = true
    },
    [TH_GetSrmsBookings.fulfilled.type]: (state, action) => {
      state.booking.isLoading = false
      state.booking.srmsBookings = action.payload
    },
    [TH_editBookingValue.fulfilled.type]: (state, action) => {
      const index = state.booking.srmsBookings.findIndex(item => item.id === action.payload.id)
      if(index > -1){ 
        state.booking.srmsBookings[index] = {...action.payload, color: state.booking.srmsBookings[index].color}
        state.booking.selectedProject = {...action.payload, color: state.booking.srmsBookings[index].color}
      }
    },

    [TH_GetCcsInjectivity.pending.type]: (state) => {
      state.injectivity.loading = true
    },
    [TH_GetCcsInjectivity.fulfilled.type]: (state, action) => {
      state.injectivity.estimation = action.payload.injectivity.estimation
      state.injectivity.ranking = action.payload.injectivity.ranking

      state.injectivity.loading = false
    },
  },
})

export default ccsSlice.reducer