import React, { useEffect, useState } from "react";
import './ProjectViewOptions.scss';
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { sideBarTreeSlice } from "store/sidebar/slice";
import { selectSelectedTab, workspaceSlice } from "store/workspace/slice";
import { DropDownList, DropDownListChangeEvent, DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    CompositeFilterDescriptor,
    filterBy,
    FilterDescriptor,
} from "@progress/kendo-data-query";
import { IProjectViewOption } from "store/sidebar/types";
import { R_ChangeProjectView } from "api/projectView/projectViewService";
import { T_getProjectView } from "store/workspace/thunk";

export const ProjectViewOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const [opened, setOpened] = useState<boolean>(true);
    const { projectViewOptions } = useAppSelector(state => state.sideBarTreeSlice);
    const [items, setItems] = useState<IProjectViewOption[]>(projectViewOptions.items);
    const { workspaceId, tabs } = useAppSelector(state => state.workspaceSlice);
    const selectedTab = useAppSelector(selectSelectedTab)

    useEffect(() => {
        setItems(projectViewOptions.items)
    }, [projectViewOptions.items])


    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const data = [...projectViewOptions.items]
        return filterBy(data, filter)
    }

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        if (!event.filter.value) {
            return setItems(projectViewOptions.items)
        }
        return setItems(filterData(event.filter))
    }

    const handleChange = async (e: DropDownListChangeEvent) => {
        dispatch(sideBarTreeSlice.actions.setProjectViewOptions(e.value))
        if (selectedTab?.id) {
            const res = await R_ChangeProjectView({ plotId: selectedTab?.id, toPlotId: e.value?.parentId, workspaceId: workspaceId }, null)
            const plotId = res.id
            const tab = tabs.find(item => item.id === plotId)
            if (tab && selectedTab?.id !== tab.id) {
                dispatch(workspaceSlice.actions.setSelectedTab(tab))
            } else {
                await dispatch(T_getProjectView({ plotId: selectedTab?.id, newId: e.value?.id, workspaceId: workspaceId }))
            }
        }
    }

    return (
        <div className="projectViewOptions">
            <div className="contentVisibilityHandler">
                <h3><span onClick={() => setOpened(!opened)} className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Project view options</h3>
            </div>
            <div className={opened ? "content" : "content closed"}>
                <div className={"dropDownListBlock"}>
                    <DropDownList
                        data={items}
                        textField="name"
                        filterable={true}
                        onFilterChange={filterChange}
                        onChange={handleChange}
                        value={projectViewOptions.items.find((e) => e.parentId === projectViewOptions.value?.parentId) || { name: 'Select' }}
                    />
                </div>
            </div>
        </div>
    )
}

