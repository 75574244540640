import React from 'react';
import { getActiveUser } from 'helpers/auth';
import { Navigate, Outlet } from 'react-router-dom'


const PrivateRoutes = () => {
    const user = getActiveUser()

    return user ? <Outlet /> : <Navigate to="/signIn"  replace={true}/>;
};

export default PrivateRoutes;