import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationInterface } from 'store/license/types';
import { getAllUsers, getUser, createUser, updateUser, deleteUser, blockUser } from '../../api/user/userService';
import { UserInterface } from './types';

export const createUserThunk = createAsyncThunk('user/create', async (credentials: UserInterface, thunkAPI) => {
    const response = await createUser(credentials, thunkAPI)
    return response
});

export const getAllUsersThunk = createAsyncThunk('user/getAllUsers', async (credentials:PaginationInterface, thunkAPI,) => {
    const response = await getAllUsers(credentials, thunkAPI)
    return response
});

export const getUserThunk = createAsyncThunk('user/getUser', async (credentials: string, thunkAPI) => {
    const response = await getUser(credentials, thunkAPI)
    return response
});

export const updateUserThunk = createAsyncThunk('user/update', async (credentials: UserInterface, thunkAPI) => {
    const response = await updateUser(credentials, thunkAPI)
    return response
});

export const deleteUserThunk = createAsyncThunk('user/delete', async (credentials: string, thunkAPI) => {
    const response = await deleteUser(credentials, thunkAPI)
    return response
});

export const blockUserThunk = createAsyncThunk('user/block', async (credentials: UserInterface, thunkAPI) => {
    const response = await blockUser(credentials, thunkAPI)
    return response
});
