import React, { useEffect, useState } from "react";
import './BenchmarkPlotOptions.scss';
import { Button } from "@progress/kendo-react-buttons";
import { DropDown, Tooltip } from "components/SideBarItems/DropDown";
import { InputCharactersCounter, NumericInput, TextInput } from "components/SideBarItems/Input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { T_saveSidebarBenchmark, getBenchmarkPlotOptionsThunk, getPlotOptionsThunk, getQuickPlotsThunk, saveQuickPlotThunk, saveSidebarThunk } from "store/sidebar/thunk";
import { KendoCheckbox } from "components/SideBarItems/Checkbox";
import { sideBarTreeSlice } from "store/sidebar/slice";
import { IElementType } from "components/SideBarItems/types";
import { selectSelectedTab } from "store/workspace/slice";
import sortAlphabetically from 'helpers/functions/sortAlphabetically';
import { LICENSE_TYPES } from "helpers/constants/app";
import WithRole from "components/userRoles/withRole/WithRole";
import { T_getBenchmarkData } from "store/explore/thunk";

export const BenchmarkPlotOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const [opened, setOpened] = useState<boolean>(true);
    const selectedTab = useAppSelector(selectSelectedTab);
    const { userDataLegends, benchmark } = useAppSelector(state => state.exploreSlice);
    const userData = useAppSelector(store => store.userData.datasetsList)
    const { workspaceId, plotId: plotIdW } = useAppSelector(state => state.workspaceSlice);
    const { benchmarkPlotOptions: plotOptions } = useAppSelector(state => state.sideBarTreeSlice);
    const plotId = selectedTab?.id || plotIdW

    const isBenchmarkUserData = !!benchmark?.benchmark?.generatedPlot?.userData?.length
    
    useEffect(() => {
        if (plotId && workspaceId && selectedTab?.type === 7) {
            dispatch(getBenchmarkPlotOptionsThunk({ workspaceId, plotId: selectedTab.id }));
        }
    }, [plotId, workspaceId, selectedTab]);

    const applyPlotAnalysis = async () => {
        await dispatch(T_saveSidebarBenchmark({ plotOptions, plotId, workspaceId }));
        await dispatch(getBenchmarkPlotOptionsThunk({ plotId, workspaceId }));
        if(selectedTab?.id){
            await dispatch(T_getBenchmarkData({ workspaceId, id: selectedTab?.id }))
        }
    };


    const handleChangeValue = (obj: { element?: IElementType, value?: unknown, group?: string | null, index?: number }): void => {
        dispatch(sideBarTreeSlice.actions.setBenchmarkPlotOptionItem(obj));
    }

    const handleSaveValues = async (element?: IElementType, group?: string | null) => {
        if (group !== 'serverSideOptions'){
            await dispatch(T_saveSidebarBenchmark({ plotOptions, plotId, workspaceId }));
        }
    };

    return (
        <>
            <div className="contentVisibilityHandler">
                <h3><span onClick={() => setOpened(!opened)} className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Plot Options</h3>
            </div>
            <div className={opened ? "content" : "content closed"}>
                <NumericInput
                    label="X Min"
                    element="xMin"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.xMin}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="X Max"
                    element="xMax"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.xMax}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="Y Min"
                    element="yMin"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.yMin}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="Y Max"
                    element="yMax"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.yMax}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="X Gridlines"
                    element="xGridlines"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.xGridlines}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Gridlines"
                    element="yGridlines"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.yGridlines}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="X Logarithmic"
                    element="xLogarithmic"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.xLogarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Logarithmic"
                    element="yLogarithmic"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.yLogarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="X Invert"
                    element="xInvert"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.xInvert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Y Invert"
                    element="yInvert"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.yInvert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Legends</h4>
                <KendoCheckbox
                    label="Show Filters"
                    group="clientSideOptions"
                    element="showFilters"
                    value={plotOptions.benchmarkPlotOptions.showFilters}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Show User Data"
                    group="clientSideOptions"
                    element="showUserData"
                    disabled={!userData.length && !isBenchmarkUserData}
                    value={plotOptions.benchmarkPlotOptions.showUserData}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Show Regression Equations"
                    group="clientSideOptions"
                    element="showRegressionEquations"
                    value={plotOptions.benchmarkPlotOptions.showRegressionEquations}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Labels</h4>
                <KendoCheckbox
                    label="Show User Data Labels"
                    element="showUserDataLabels"
                    group="clientSideOptions"
                    disabled={!userData.length && !isBenchmarkUserData}
                    value={plotOptions.benchmarkPlotOptions.showUserDataLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <WithRole roles={[LICENSE_TYPES.global]}>
                    <KendoCheckbox
                        label="Show Global Database Labels"
                        element="globalDatabaseLabels"
                        group="clientSideOptions"
                        value={plotOptions.benchmarkPlotOptions.globalDatabaseLabels}
                        setFunction={handleChangeValue}
                        saveFunction={handleSaveValues}
                    />
                    </WithRole>
                <KendoCheckbox
                    label="Show Axis Labels"
                    element="showAxisLabels"
                    group="clientSideOptions"
                    value={plotOptions.benchmarkPlotOptions.showAxisLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <div className="hr" />
                <h4>Plot Analysis</h4>
                <h4>Regression</h4>
                <KendoCheckbox
                    label="Lin Regression"
                    group="serverSideOptions"
                    element="linRegression"
                    value={plotOptions.benchmarkPlotOptions.linRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Pow Regression"
                    group="serverSideOptions"
                    element="powRegression"
                    value={plotOptions.benchmarkPlotOptions.powRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Add point"
                    group="serverSideOptions"
                    element="addPoint"
                    value={plotOptions.benchmarkPlotOptions.addPoint}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="X Value"
                    group="serverSideOptions"
                    element="xValue"
                    value={plotOptions.benchmarkPlotOptions.xValue}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <TextInput
                    label="Label"
                    group="serverSideOptions"
                    element="label"
                    value={plotOptions.benchmarkPlotOptions.label}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <Button themeColor={"primary"} onClick={applyPlotAnalysis}>Apply Plot Analysis</Button>
                <div className="hr" />
            </div>
        </>
    )
}

