import React, { useMemo, useState } from 'react'
import { DropDownList, DropDownListFilterChangeEvent, DropDownListOpenEvent } from '@progress/kendo-react-dropdowns'
import { ITreeElementProps } from 'helpers/types/tree'
import { workspaceSlice } from 'store/workspace/slice'
import { sideBarTreeSlice } from 'store/sidebar/slice'
import sortAlphabetically from 'helpers/functions/sortAlphabetically'
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { IOptions } from 'store/sidebar/types'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { deleteQuickPlotThunk, setQuickPlotThunk } from 'store/sidebar/thunk'

export const FilterDropDown: React.FC<ITreeElementProps> = ({ props }) => {
  const dispatch = useAppDispatch()
  const { workspaceId, tabs, projectViewTreeView } = useAppSelector(store => store.workspaceSlice)
  const selectedTab = tabs.find(item => item.isSelected)
  const allData = props.item.options ? sortAlphabetically(props.item.options, 'name') : []
  const [dropDownData, setDropdownData] = useState<IOptions[]>([])


  useMemo(() => {
    setDropdownData(props.item.options?.length ? sortAlphabetically(props.item.options, 'name') : [])
  }, [props.item.options])

  const selectItem = async (e: IOptions) => {
    if (props.item.parent === 'plotOptions')
      if (props.item.name === "QuickPlot") {
        await dispatch(setQuickPlotThunk({ workspaceId, plotId: e.id, quickPlotId: selectedTab?.quickPlotId as string }))
        dispatch(workspaceSlice.actions.setQuickPlotId(e.id))
        return
      }
    dispatch(sideBarTreeSlice.actions.setPlotOptionsChanged(true));
    dispatch(workspaceSlice.actions.changePlotOptions({ name: props.item.element, value: e }))

    dispatch(workspaceSlice.actions.selectItem({ props, value: e }))
    // if(props.item.name?.includes('Axis'))
    //     dispatch(workspaceSlice.actions.applyPlotAnalysis())
  }

  const getDropdownData: (event: DropDownListOpenEvent) => void = async () => {
    if (props.item.name === "X Axis" || props.item.name === "Y Axis") {
      dispatch(workspaceSlice.actions.getDropdownData({ itemHierarchicalIndex: props.itemHierarchicalIndex }))
      return
    }
  }


  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const data = [...allData]
    return filterBy(data, filter)
  }

  const removePlot = async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, plotName: string) => {
    event.preventDefault()
    event.stopPropagation()
    const plotId = props.item.options?.find(option => option.name === plotName)?.id
    if (plotId)
      await dispatch(deleteQuickPlotThunk(plotId))
  }

  const filterChange = (event: DropDownListFilterChangeEvent) => {
    if (!event.filter.value) {
      return setDropdownData(allData)
    }
    return setDropdownData(filterData(event.filter))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const itemRender = (li: any) => {
    const deleteElement = (
      <span onClick={(e) => removePlot(e, li.props.children[0].props.children)} className="k-icon k-i-delete" style={{ position: "absolute", right: "0.2rem", marginTop: ".2rem" }} />
    )
    const icon = props.item.name === "QuickPlot" ? deleteElement : ""
    const itemChildren = (
      <div>
        <span>{li.props.children}</span>
        {icon}
      </div>
    )

    return React.cloneElement(li, li.props, itemChildren)
  }

  const selected = props.item.category === 'projectView' ? projectViewTreeView.items.find(item => item.element === "projectViewoption")?.selected : props.item.selected

  return (
    <div className={props.item.noData ? "dropDownListBlock noData" : "dropDownListBlock"}>
      {props.item.name && <label>{props.item.name}</label>}
      <DropDownList
        data={dropDownData}
        onFilterChange={filterChange}
        itemRender={itemRender}
        filterable={true}
        onOpen={getDropdownData}
        onChange={(e) => selectItem(e.value)}
        textField="name"
        dataItemKey={selected?.parentId ? 'parentId' : 'id'}
        value={selected}
        key={selected?.parentId || selected?.id}
        className="filter-dropdown"
      />
    </div>
  )
}
