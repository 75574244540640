import { api } from "api/root";
import { handleRequestErrors } from "helpers/functions/commons";
import { Dispatch } from "react";
import { addNotification } from "store/notifications/slice";
import { processProjectViewOptions } from "./processors";

export const R_getProjectView = async (data: {workspaceId: string, plotId: string}, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/ProjectView/${data.plotId}/data`);
        // const crossPlots = createPlotData(response.data.crossPlots);
        return {...response.data, plotId: data.plotId};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_addProjectView = async (data: {workspaceId: string, plotId: string, datasetIds: string[]}, thunkAPI: unknown) => {
    try {
        const response = await api.post(`/Workspace/${data.workspaceId}/ProjectView/`, {identifierFieldId: data.plotId, datasetIds: data.datasetIds});
        return {...response.data};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_ChangeProjectView = async (data: {workspaceId: string, plotId: string, toPlotId: string}, thunkAPI: unknown) => {
    try {
        const response = await api.put(`/Workspace/${data.workspaceId}/ProjectView/${data.plotId}`, {
            identifierFieldId: data.toPlotId
        });
        return {...response.data};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_getProjectViewOptions = async (data: {workspaceId: string, plotId?: string}, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/ProjectView/DropdownData`, {params: {plotId: data.plotId}});
        const result = processProjectViewOptions(response.data.options)
        return result;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage);
    }
}

export const R_getProjectViewOptionsByDatasets = async (data: {workspaceId: string, plotId?: string, datasetId?: string[]},thunkAPI: unknown) => {
    try {
        const ids = data.datasetId?.map(item=>`DatasetIds=${item}`).join('&')
        const res = await api.get(`/Workspace/${data.workspaceId}/ProjectView/DropdownData?${ids}`, {params: {plotId: data.plotId}})
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}