/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "api/root";
import axios, { CancelTokenSource } from "axios";
import { handleRequestErrors } from "helpers/functions/commons";
import { Dispatch } from "react";
import { addNotification } from "store/notifications/slice";

export const R_addNewDataset = async (data: FormData, thunkAPI: unknown) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}Dataset`, data, {
            withCredentials: true, 
            responseType: 'blob',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.xlsx'); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_addEmptyDataset = async (data: FormData, thunkAPI: unknown) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}Dataset/Empty`, data, {
            withCredentials: true, 
            responseType: 'blob',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_importDataset = async (data: {id:string, file:FormData}, thunkAPI: unknown) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}Dataset/${data.id}/import`, data.file, {
            withCredentials: true, 
            responseType: 'blob',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.xlsx'); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const R_getDatasetsList = async (thunkAPI: unknown) => {
    try {
        const res = await api.get('Dataset/GetAll')
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_getDatasetsListByWorkspace = async (data: {workspaceId: string, identifierFieldType: number},thunkAPI: unknown) => {
    try {
        const res = await api.get(`/Workspace/${data.workspaceId}/Datasets/${data.identifierFieldType}`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_getDataset = async (data: any, thunkAPI: unknown) => {
    try {
        const res = await api.post('Data/AddDataset', data)

        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_editDatasetValues = async (data: {id: string, title: string, description: string, colorDisplayOption: number}, thunkAPI: unknown) => {
    try {
        const res = await api.put(`Dataset/${data.id}`, { title: data.title, description: data.description, colorDisplayOption: data.colorDisplayOption })
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const R_deleteDataset = async (data: {id: string}, thunkAPI: unknown) => {
    try {
        const res = await api.delete(`Dataset/${data.id}`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_unshareDataset = async (data: {id: string}, thunkAPI: unknown) => {
    try {
        const res = await api.delete(`Dataset/${data.id}/Unshare`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const R_getDatasetData = async (data: {id: string, offset: number, limit: number}, thunkAPI: unknown) => {
    try {
        const res = await api.get(`Dataset/${data.id}/Data`, {params: {offset: data.offset, limit: data.limit}})
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_deleteDatasetRow = async (data: {id: string, identifierFieldId: string}, thunkAPI: unknown) => {
    try {
        const res = await api.delete(`/Dataset/${data.id}/IdentifierField/${data.identifierFieldId}`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_editDatasetRow = async (data: {id: string, identifierFieldId: string, tags: { tagAttributeId: string, value: string}[]}, thunkAPI: unknown) => {
    try {
        const res = await api.patch(`/Dataset/${data.id}/IdentifierField/${data.identifierFieldId}`, {Tags: data.tags})
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_addEmptyDatasetRow = async (data: {id: string}, thunkAPI: unknown) => {
    try {
        const res = await api.post(`/Dataset/${data.id}/EmptyIdentifierField`)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_shareDataset = async (data: {id: string, sharedWith: string[], source?: CancelTokenSource }, thunkAPI: unknown) => {
    try {
        const config = data.source?.token ? {cancelToken: data.source.token} : {}
        const res = await api.put(`/Dataset/${data.id}/Share`, {sharedWith: data.sharedWith}, config)
        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_exportDataset = async (data: {id: string }, thunkAPI: unknown) => {
    try {


        const res = await axios.post(`${process.env.REACT_APP_API_URL}Dataset/${data.id}/Export`, {}, {
            withCredentials: true, 
            responseType: 'blob',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.xlsx'); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();


        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const R_exportDatasetTemplate = async (data: {datasetType: string, fileName: string }, thunkAPI: unknown) => {
    try {


        const res = await axios.get(`${process.env.REACT_APP_API_URL}Dataset/Export/Template/${data.datasetType}`, {
            withCredentials: true, 
            responseType: 'blob',
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${data.fileName} Template.xlsx`); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();


        return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
    }
}