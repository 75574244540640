/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";

const useOnClickOutside = (refs: any, handler: any) => {
  useEffect(() => {
    
    
    const listener = (event: any) => {
      if (!refs.current || refs.current?.contains(event.target)) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [refs, handler]);
};

export { useOnClickOutside };
