import React, { useEffect, useRef } from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import logoIcon from '../../assets/images/logoBig.png';
import questionSignIcon from '../../assets/images/questionSign.png';
import { getFromCache } from '../../helpers/functions/cache';
import AppConstants from '../../helpers/constants/app';
import ProjectListDropDown from '../ProjectListDropDown/ProjectListDropDown';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { usePaths } from 'hooks/usePaths';
import { getPlotThunk } from 'store/explore/thunk';
import { getAllWorkspacesThunk } from 'store/workspace/thunk';
import { revokeToken } from 'api/auth/service';
import settingsIcon from 'assets/icons/settings.svg';
import userSettingsIcon from 'assets/icons/user.svg';
import PopUpDropDown from '../PopUpDropDown/PopUpDropDown';
import { ProjectsNavbar } from './Porjects';
import './navbar.scss';
import { mapViewSlice } from "store/mapView/slice";
import { USER_ROLES } from 'helpers/constants/commons';
import { filterDataByRole } from 'helpers/functions/commons';
import { getActiveUser } from 'helpers/auth';
import { filteredDataWindowThunk } from 'store/footer/thunk';
import { selectSelectedTab, workspaceSlice } from 'store/workspace/slice';
import { selectAllTagAttributes } from 'store/sidebar/selectors';
import { selectIsDatasetChanged } from 'store/userData/selectors';
import { trackGAEvent } from 'helpers/functions/GoogleAnalytics/GA';
import { clickEventsGA, eventsGA } from 'helpers/functions/GoogleAnalytics/types';
import { selectActiveUser } from 'store/userState/selectors';

const navBarLinks = [
    {
        name: 'Home',
        link: '/home',
        tooltip: 'Access the Workspace Management, Notifications and Community Section',
        roles: Object.values(USER_ROLES)
    },
    {
        name: 'Data',
        link: '/data',
        tooltip: 'Import, Edit, Export, and Share your User Datasets',
        roles: Object.values(USER_ROLES)
    },
    {
        name: 'Explore',
        link: '/Explore',
        tooltip: 'Visualise and interrogate data',
        roles: Object.values(USER_ROLES)
    },
    {
        name: 'Subscriptions',
        link: '/projects',
        tooltip: 'Workflow based modules for specific Energy projects',
        roles: Object.values(USER_ROLES)
    },
    {
        name: 'Learn',
        tooltip: 'Access the knowledge base, get support, or suggest a feature (opens in a new tab)',
        link: 'https://belltreegroup.atlassian.net/servicedesk/customer/portal/2',
        external: true,
        onClick: () => { trackGAEvent(eventsGA.click, clickEventsGA.learn) },
        roles: Object.values(USER_ROLES)
    },
]

const popupDropdownValues = [
    {
        name: 'Admin Section',
        url: '/Admin',
        roles: [USER_ROLES.admin, USER_ROLES.client]
    },
    {
        name: 'Change Password',
        url: '/changePassword',
        roles: Object.values(USER_ROLES)
    },
]

export default function Navbar() {
    // const { workspaces } = useAppSelector(state => state.homeSlice);

    const paths = usePaths()

    const generateClassName = (isActive: boolean, link: typeof navBarLinks[number]['link']) => {
        if (isActive && link !== '/projects') return 'active'
        if (paths.includes(link.substring(1))) return 'active-child'
        return ''
    }
    const activeUser = getActiveUser()
    const user = useAppSelector(selectActiveUser)
    const { isFetched: isFetchedMap } = useAppSelector(state => state.mapViewSlice);
    const { workspaces, workspaceId, deletePlot, plotId, tabs, workspaceListLoading, styleSettings } = useAppSelector(state => state.workspaceSlice)
    const isDatasetChanged = useAppSelector(selectIsDatasetChanged)
    const currentWorkspace = workspaces.find(item => item.id === workspaceId)
    const tagAttributes = useAppSelector(selectAllTagAttributes)
    const dispatch = useAppDispatch();
    const initialized = useRef(false)

    useEffect(() => {
        if (tagAttributes?.length && !initialized?.current) {
            initialized.current = true
            dispatch(getAllWorkspacesThunk({ allTags: tagAttributes }));
        }
    }, [tagAttributes])

    useEffect(() => {
        const disabledSidebarPages = ['data', 'home', 'learn']
        dispatch(workspaceSlice.actions.hideSidebar(disabledSidebarPages.includes(paths[0])))
    }, [paths])

    const selectedTab = useAppSelector(selectSelectedTab)

    const timeoutId = useRef<any>(null)

    useEffect(() => {
        const getPlot = async () => {
            // define a thunk for this or use the one in explore page and pass the filters
            await dispatch(getPlotThunk({ plotId: selectedTab?.id || plotId, workspaceId }));
        }
        if (!workspaceListLoading && (!deletePlot && selectedTab?.type === 1 && selectedTab?.id) || isDatasetChanged){
            if(timeoutId.current) clearTimeout(timeoutId.current)
            timeoutId.current = setTimeout(() => {
                    getPlot();
                }, 600)
            }
    }, [tabs, currentWorkspace?.datasetIds, isDatasetChanged, selectedTab?.type, plotId, workspaceListLoading])

    useEffect(() => {
        if (isFetchedMap) {
            dispatch(mapViewSlice.actions.changeIsFetchedStatus(false))
        }
    }, [currentWorkspace?.datasetIds])

    useEffect(() => {
        const getFilteredDataWindow = async () => {
            await dispatch(filteredDataWindowThunk(workspaceId));
        }
        if (!workspaceListLoading && workspaceId)
            getFilteredDataWindow()
    }, [workspaceListLoading, workspaceId])

    const role = getActiveUser()?.role



    const topComponent = <div className='container' >
        <img src={userSettingsIcon} className='userLogo' />
        <div>
            <div className='name' > {user.name}  <span className='client' >({activeUser?.clientName})</span> </div>
            <div className='email' > {activeUser?.email} </div>

        </div>
    </div>

    return (
        <nav className='main-nav'>
            <ul className='nav-items'>
                <li className='logo'>
                    <img src={logoIcon} alt="bMark Logo" />
                </li>
                {navBarLinks.map(i => {
                    return (
                        i.external ?
                            <div key={i.name} title={i.tooltip} className='single-nav'>
                                <a href={i.link} key={i.name} rel="noreferrer" target='_blank' >
                                    {i.name}
                                </a>
                            </div>
                            :
                            <div key={i.name} title={i.tooltip} className='single-nav'>
                                <NavLink
                                    to={i.link}
                                    className={({ isActive }) => generateClassName(isActive, i.link)}
                                    end
                                >
                                    {i.name}
                                </NavLink>
                                {
                                    i.link === '/projects' &&
                                    <Routes>
                                        <Route path='/projects/*' element={<ProjectsNavbar />} />
                                    </Routes>
                                }
                            </div>
                    );
                })}
            </ul>
            <ul className='nav-items-user'>
                <li className="active-project">
                    <span className='active-project-label'>Active Workspace</span>
                    <div title='Switch to another workspace'>
                        <ProjectListDropDown width='200px' workspaces={workspaces} />
                    </div>
                </li>
                <li title='Administration & Password Management'>
                    <PopUpDropDown
                        name='settings'
                        icon={settingsIcon}
                        links={filterDataByRole(popupDropdownValues, role as string)}
                    />
                </li>
                <li title='Get support via Live Chat (when available)' className='question'>
                    {/* <PopUpDropDown name='calculation' icon={questionSignIcon} /> */}
                    <img className='questionIcon' src={questionSignIcon} alt="Help" />
                </li>
                <li title={`Logged in as: ${activeUser?.email}`}>
                    <PopUpDropDown
                        topComponent={topComponent}
                        name='userSettings'
                        icon={userSettingsIcon}
                        links={[
                            {
                                name: 'Log Out',
                                icon: 'k-i-logout',
                                url: '/signIn',
                                callback: async () => {
                                    localStorage.clear();
                                    await revokeToken();
                                    // TODO replace this to refresh redux store on signout
                                    window.location.reload()

                                }
                            }
                        ]}
                    />
                </li>
            </ul>
        </nav>
    );
}