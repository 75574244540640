import { api } from "api/root";
import { Dispatch } from "react";
import { processAxisSettings, processSettings, processSettingsToBody } from "./processors";
import { IAxisStyleSettings, IStyleSettings } from "store/workspace/types";
import { MappingOptionType, MappingOptionTypeReverse } from "helpers/constants/commons";



export const R_getStyleSettings = async (data: {
    workspaceId: string, 
    optionType: number,
    identifierFieldId?: string,
    plotType?: number
}, thunkAPI: unknown) => {
    try {
        const {workspaceId, optionType, identifierFieldId, plotType } = data;
        // const request = createRequestModel(mapOptions, filterOptions, categorizeOptions)
        const response = await api.get(`/Workspace/${workspaceId}/Option`, {
            params: { 
                optionType, 
                identifierFieldId, 
                plotType
            },
        });   
        return processSettings(response.data)
    } catch (e: unknown) {
        const validationMessage = e as { message: string, response: { data: { status: string, title: string } } };
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: string) => string, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage.message);
    }
}



export const R_getAxisStyleSettings = async (data: {
    workspaceId: string, 
    optionType: number,
    type: string,
    plotType?: number,
}, thunkAPI: unknown) => {
    try {
        const {workspaceId, optionType, plotType, type } = data;
        // const request = createRequestModel(mapOptions, filterOptions, categorizeOptions)
        const response = await api.get(`/Workspace/${workspaceId}/Option`, {
            params: { 
                optionType, 
                plotType
            },
        });   
        return processAxisSettings(response.data, type)
    } catch (e: unknown) {
        const validationMessage = e as { message: string, response: { data: { status: string, title: string } } };
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: string) => string, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage.message);
    }
}

export const R_changeStyleSettings = async (data: {
    workspaceId: string, 
    styleSettings: any,
    optionType: number,
    identifierFieldId?: string
}, thunkAPI: unknown) => {
    try {
        const { workspaceId, styleSettings, optionType, identifierFieldId } = data;
        const parsed = processSettingsToBody(styleSettings)
        const body = {
            optionType, 
            identifierFieldId,
            ...parsed
        }
        // const request = createRequestModel(mapOptions, filterOptions, categorizeOptions)
        const response = await api.post(`/Workspace/${workspaceId}/Option`, body);   
        return response
    } catch (e: unknown) {
        const validationMessage = e as { message: string, response: { data: { status: string, title: string } } };
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: string) => string, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage.message);
    }
}

export const R_changeAxisStyleSettings = async (data: {
    workspaceId: string, 
    styleSettings: Partial<IAxisStyleSettings>,
    optionType: number,
}, thunkAPI: unknown) => {
    try {
        
        const { workspaceId, styleSettings, optionType } = data;
        const sizeFontStr = styleSettings[MappingOptionTypeReverse?.[optionType as keyof typeof MappingOptionTypeReverse] as keyof typeof styleSettings]?.sizeFont.value
        const body = {
            optionType, 
            labelOption: {
                font: styleSettings[MappingOptionTypeReverse?.[optionType as keyof typeof MappingOptionTypeReverse] as keyof typeof styleSettings]?.font?.family,
                fontSizeType: sizeFontStr ? parseInt(sizeFontStr) : 12,
            }
        }
        // const request = createRequestModel(mapOptions, filterOptions, categorizeOptions)
        const response = await api.post(`/Workspace/${workspaceId}/Option`, body);   
        return response
    } catch (e: unknown) {
        const validationMessage = e as { message: string, response: { data: { status: string, title: string } } };
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: string) => string, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage.message);
    }
}

export const R_deleteStyleSettings = async (data: {
    workspaceId: string, 
    optionId: string
}, thunkAPI: unknown) => {
    try {
        const { workspaceId, optionId } = data;
        const response = await api.delete(`/Workspace/${workspaceId}/Option/${optionId}`);   
        return response
    } catch (e: unknown) {
        const validationMessage = e as { message: string, response: { data: { status: string, title: string } } };
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: string) => string, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage.message);
    }
}