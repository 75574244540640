import { MAPPINGS } from "api/styleSettings/processors";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createPlotData = (plotData: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plotData.forEach((e: any) => {
    e.textposition = MAPPINGS.positions[e?.crossPlotPointOption?.positionType - 1]?.plotValue || 'center'
    e.textfont = {
      family: e?.crossPlotPointOption?.font || 'Arial',
      size: e?.crossPlotPointOption?.fontSizeType,
      color: '#000'
    },
  
    e.marker = {
      color: e.crossPlotPointOption?.stringColor, 
      size: MAPPINGS.markerSizes[e.crossPlotPointOption?.markerSizeType as number - 1] || 3,
      symbol: MAPPINGS.shape[e?.crossPlotPointOption?.shapeType as number - 1]?.plotValue,
      line: {
        color: e?.crossPlotPointOption?.hasBorder ? '#000' : e.crossPlotPointOption?.stringColor,
        width: 1.5
      }
    }
    e.hoverlabel = {
      bgcolor: "#FFF",
      bordercolor: "#939495",
      align: "left",
      font: {
        family: "cursive",
        size: 14
      }
    }
  })
  return plotData;
}


