import { Loader } from '@progress/kendo-react-indicators'
import { Input, InputProps } from '@progress/kendo-react-inputs'
import { Portal } from 'components/Portal/Portal'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, {useState, useEffect} from 'react'
import { selectAllTagAttributes } from 'store/sidebar/selectors'
import { getAllWorkspacesThunk, T_deleteWorkspace, T_editWorkspace } from 'store/workspace/thunk'

type Props = {
    isOpen: boolean,
    id: string,
    name: string,
    type: 'new' | 'edit' | 'delete',
    closePortal: () => void,
    workspaceNames: string[]
}

const Popup = ({isOpen, id, name, type, workspaceNames, closePortal}: Props) => {
    const dispatch = useAppDispatch()
    const [workspaceName, setWorkspaceName] = useState('')
    const tagAttributes = useAppSelector(selectAllTagAttributes)
    const [error, setError] = useState('')
    const {workspaceListLoading, isLoading} = useAppSelector(store=>store.workspaceSlice)

    useEffect(()=>{
        setWorkspaceName(name ?? '')
    }, [name])

    const handleChange: InputProps['onChange'] = (e) => {
        setError('')
        if(workspaceNames.includes(e.value.toLowerCase())){
            setError(`A workspace with name ${e.value} exist`)
        }
        setWorkspaceName(e.value)
    }

    const saveChanges = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let res: any;
        if(!workspaceName){
            setError('Field is required')
            return
        }
        switch (type) {
            case 'new':
                // res = await dispatch(T_addWorkspace({title: workspaceName}))
                // dispatch(getHomeWorkspacesThunk());
                break;
            case 'edit':
                res = await dispatch(T_editWorkspace({title: workspaceName, id}))
                break;
            case 'delete':
                res = await dispatch(T_deleteWorkspace({id}))
                break;
            
            default:
                break;
        }
        if(res?.error){
            setError(res?.payload)
            return
        }
        
        await dispatch(getAllWorkspacesThunk({allTags: tagAttributes}))
        closePortal()
        setWorkspaceName('')
        
    }
    
  return (
    <Portal
        opened={isOpen}
        closePortal={closePortal}
    >
        <>
        <div className='portal-header'>
                    {type === 'delete' ? 
                    <h4>
                        Are you sure you want to delete the &quot;{name}&quot; workspace?
                    </h4> 
                    :
                    <h4>
                        {
                            type === 'new' ?
                            'Name ' :
                            type === 'edit' ?
                            'Rename ' :
                            'Delete '
                        }
                        the workspace {name}
                    </h4>}
                    <button className='close-btn' onClick={closePortal} />
                </div>
                <div className='portal-custom-content'>
                    <div className={`project-name-portal-content`}>
                       {type !== 'delete' && <Input
                            autoFocus
                            autoComplete='on'
                            placeholder='Enter Workspace Name'
                            value={workspaceName}
                            onChange={handleChange}
                            className={!error ? '' : 'error-border'}
                            validationMessage={error}
                        />}
                    </div>
                </div>
                <div className='workspace-error'>{error}</div>
                <div className='portal-footer'>
                    <button 
                        className='cancel' 
                        onClick={closePortal}
                    >
                        Cancel
                    </button>
                    <button 
                        className='save' 
                        onClick={saveChanges}
                        disabled={!!error || workspaceListLoading || isLoading}
                    >
                        {workspaceListLoading || isLoading && <Loader/>  }
                        {type === 'delete' ? 'Delete' : 'Save'}
                    </button>
                </div>
        </>
    </Portal>
  )
}

export default Popup