import { api } from "api/root";
import { CCS_STORAGE_RISK_PRIMARY_TAGS } from "helpers/constants/ccs";
import { normalizeByKey } from "helpers/functions/commons";
import { T_TH_AddCcsInstance, T_TH_AddPotentialCCSStorage, T_TH_CreateCcsProject, T_TH_DeleteCcsInstance, T_TH_DeleteCcsProject, T_TH_EditCcsInjectivity, T_TH_EditCcsProject, T_TH_EditCcsStorage, T_TH_EditCcsTagValue, T_TH_GetCcsInjectivity, T_TH_GetCcsProject, T_TH_GetCcsProjectNames, T_TH_GetCcsStorageResource, T_TH_GetMapPopupInfo, T_TH_GetSrmsBookings } from "store/ccs/types";
import { processCcsProject, processCcsProjectNames, processCcsInstance, processCcsStorageResources, processCcsInjectivity } from "./processors";
import { T_ResponseCreateCcsProject, T_ResponseDeleteCcsProject, T_ResponseEditCcsProjectName, T_ResponseGetProjectNames, T_ResponseGetCcsProject, T_ResponseCcsInstance, T_ResponseCcsInstances, T_ResponseGetCcsRiskStorages, T_ResponseCcsStorageResource, T_ResponseCcsInjectivity, T_ResponseDataOptions } from "./types";

export const R_GetCcsProjectNames = async ({ workSpaceId }: T_TH_GetCcsProjectNames['args']) => {
    const response = await api.get<T_ResponseGetProjectNames>(`Workspace/${workSpaceId}/CCS/GetAll`);
    return {
        workSpaceId,
        projects: processCcsProjectNames(response.data)
    };
}

export const R_CreateCcsProject = async ({ workSpaceId, title }: T_TH_CreateCcsProject['args']) => {
    const response = await api.post<T_ResponseCreateCcsProject>(
        `Workspace/${workSpaceId}/CCS`,
        { title }
    );
    return response.data
}

export const R_EditCcsProject = async ({ workSpaceId, id, title }: T_TH_EditCcsProject['args']) => {
    const response = await api.put<T_ResponseEditCcsProjectName>(
        `Workspace/${workSpaceId}/CCS/${id}`,
        { title }
    );
    return response.data
}

export const R_DeleteCcsProject = async ({ workSpaceId, id }: T_TH_DeleteCcsProject['args']) => {
    const response = await api.delete<T_ResponseDeleteCcsProject>(`Workspace/${workSpaceId}/CCS/${id}`);
    return response.data
}

export const R_GetCcsProject = async ({ workSpaceId, id }: T_TH_GetCcsProject['args']) => {
    const response = await api.get<T_ResponseGetCcsProject>(`Workspace/${workSpaceId}/CCS/${id}/Dashboard`);
    return processCcsProject(response.data)
}

export const R_GetCcsOptions = async ({ workspaceId, data, type, signal }: T_TH_AddPotentialCCSStorage['args'] & {signal?: AbortSignal}) => {
    const response = await api.post<T_ResponseDataOptions>(
        `Data/${type}/${workspaceId}`,
        { ...data },
        {signal: signal}
    );
    return response.data
}

export const R_AddCcsInstance = async ({ workSpaceId, ccsProjectId, identifierFieldIds, requestName }: T_TH_AddCcsInstance['args']) => {
    const response = await api.post<T_ResponseCcsInstances>(
        `Workspace/${workSpaceId}/CCS/${ccsProjectId}/${requestName}`,
        { identifierFieldIds }
    );
    return normalizeByKey(response.data.identifierFields.map(instance => processCcsInstance(instance, 'key')))
}

export const R_DeleteCcsInstance = async ({ workSpaceId, ccsProjectId, identifierFieldId, requestName }: T_TH_DeleteCcsInstance['args']) => {
    const response = await api.delete<T_ResponseCcsInstance>(`Workspace/${workSpaceId}/CCS/${ccsProjectId}/${requestName}/${identifierFieldId}`);
    return response.data
}

export const R_EditCcsTagValue = async ({ workSpaceId, ccsProjectId, identifierFieldId, data, requestName }: T_TH_EditCcsTagValue['args']) => {
    const response = await api.patch<T_ResponseCcsInstance>(
        `Workspace/${workSpaceId}/CCS/${ccsProjectId}/${requestName}/${identifierFieldId}`,
        {
            tags: [
                {
                    tagAttributeId: data.id,
                    value: data.value
                }
            ]
        }
    );

    return response.data
}

export const R_CcsBook = async ({ workSpaceId, ccsProjectId, identifierFieldId, data, requestName }: T_TH_EditCcsTagValue['args']) => {
    const response = await api.patch<T_ResponseCcsInstance>(
        `Workspace/${workSpaceId}/CCS/${ccsProjectId}/${requestName}/${identifierFieldId}`,
        {
            book: data.book
        }
    );

    return response.data
}

export const R_CcsTransportShip = async ({ workSpaceId, ccsProjectId, transportViaShip }: T_TH_EditCcsTagValue['args'] & { transportViaShip: boolean }) => {
    const response = await api.put<T_ResponseCcsInstance>(
        `Workspace/${workSpaceId}/CCS/${ccsProjectId}/Dashboard`,
        {
            transportViaShip
        }
    );

    return response.data
}

export const R_GetMapPopupInfo = async (data: { workspaceId: string, popupInfo: T_TH_GetMapPopupInfo['args'] }) => {
    const response = await api.get<T_TH_GetMapPopupInfo['return']>(`/data/Popupwindow/${data.popupInfo}`)
    return response.data
}

export const R_GetCcsStorageRisk = async ({ workSpaceId, id }: T_TH_GetCcsProject['args']) => {
    const response = await api.get<T_ResponseGetCcsRiskStorages>(`Workspace/${workSpaceId}/CCS/${id}/StorageRisk`);
    const data = normalizeByKey(response.data.storages.map(storage => processCcsInstance(storage, 'key')))

    const radarData = response.data.storages.map(item => {
        const found = item.tags.find(item => item.key === CCS_STORAGE_RISK_PRIMARY_TAGS.field.id)
        return item.tags.filter(item => item.key.endsWith('Risk'))
            .reduce((acc, item) => {
                acc[item.key] = { ...item, label: found?.value }
                return acc
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, {} as any)
    }
    )
    return { data, riskResourcePlot: response.data.riskResourcePlot, radarData }
}

export const R_GetCcsStorageResource = async ({ workSpaceId, id }: T_TH_GetCcsStorageResource['args']) => {
    const response = await api.get<T_ResponseCcsStorageResource>(`Workspace/${workSpaceId}/CCS/${id}/StorageResource`);
    return processCcsStorageResources(response.data)
}

export const R_EditCcsStorage = async ({ workSpaceId, ccsProjectId, data, identifierFieldId }: T_TH_EditCcsStorage['args']) => {
    const response = await api.patch(`Workspace/${workSpaceId}/CCS/${ccsProjectId}/Storage/${identifierFieldId}`, {
        book: data.book,
    })
    return response.data
}

export const R_GetSrmsBookings = async ({ workspaceId, ccsProjectId }: T_TH_GetSrmsBookings['args']) => {
    const response = await api.get<{ srmsBookings: T_TH_GetSrmsBookings['return'] }>(`Workspace/${workspaceId}/CCS/${ccsProjectId}/booking`)
    return response.data?.srmsBookings

}

export const R_editBookValue = async ({ workspaceId, ccsProjectId, data }: T_TH_GetSrmsBookings['args']) => {
    const response = await api.put<T_TH_GetSrmsBookings['return']>(`Workspace/${workspaceId}/CCS/${data?.id ?? ccsProjectId}/booking`, data)
    return response.data
}

export const R_GetCcsInjectivity = async ({ workSpaceId, id }: T_TH_GetCcsInjectivity['args']) => {
    const response = await api.get<T_ResponseCcsInjectivity>(`Workspace/${workSpaceId}/CCS/${id}/Injectivity`);
    return processCcsInjectivity(response.data)
}

export const R_EditCcsInjectivity = async ({ workSpaceId, ccsProjectId, data, identifierFieldId }: T_TH_EditCcsInjectivity['args']) => {
    const response = await api.patch(`Workspace/${workSpaceId}/CCS/${ccsProjectId}/Storage/${identifierFieldId}`, {
        book: data.book,
    })
    return response.data
}