import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LicenseState } from './types';
import { createLicenseThunk, getAllLicensesThunk, updateLicenseThunk, deleteLicenseThunk } from './thunk';

const initialState: LicenseState = {
    licenses: [],
    isLoading: false,
    error: null,
    totalRecords: 0
};

export const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {

    },
    extraReducers: {
        [createLicenseThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [createLicenseThunk.fulfilled.type]: (state, action) => {
            action.meta.arg.id = action.payload;
            state.licenses = [action.payload, ...state.licenses];
            state.isLoading = false;
        },

        [createLicenseThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [getAllLicensesThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [getAllLicensesThunk.fulfilled.type]: (state, action) => {
            state.licenses = action.payload.collection
            state.totalRecords = action.payload.totalRecords
            state.isLoading = false;

        },

        [getAllLicensesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [updateLicenseThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [updateLicenseThunk.fulfilled.type]: (state, action) => {
            const idx = state.licenses.findIndex(i => i.id === action.meta.arg.id);
            if (idx > -1) {
              state.licenses[idx] = action.payload;
            }
            state.isLoading = false;
        },

        [updateLicenseThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [deleteLicenseThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [deleteLicenseThunk.fulfilled.type]: (state, action) => {
            const newArr = state.licenses.filter(i => i.id !== action.meta.arg);
			state.licenses = newArr
            state.isLoading = false;
        },

        [deleteLicenseThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

export default licenseSlice.reducer