import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { registrationThunk } from './thunk';

interface RegistrationState {
    isEmailVerified: boolean,
    email: string,
    isLoading: boolean,
    error: string | null;
};

const initialState: RegistrationState = {
    isEmailVerified: false,
    isLoading: false,
    email: '',
    error: null,
};

export const registrationSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        handleEmailChange: (state, action) => {
            state.email = action.payload
        }
    },
    extraReducers: {
        [registrationThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [registrationThunk.fulfilled.type]: (state) => {
            state.isEmailVerified = true;
            state.isLoading = false;
        },

        [registrationThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const { handleEmailChange } = registrationSlice.actions;

export default registrationSlice.reducer;

