import { api } from "api/root";
import { CancelTokenSource } from "axios";
import { Dispatch } from "react";
import { IMapViewPointsResponse } from "./types";



export const getMapViewFilteredPoints = async (data: {
    workspaceId: string, 
    // mapOptions: TreeViewDataItem, 
    // filterOptions: IFilterOptions, 
    // categorizeOptions: ICategorizeOptions, 
    ApplyFilters?: boolean
    leftBottomCorner?: {
        latitude: number,
        longitude: number
    },
    rightTopCorner?: {
        latitude: number,
        longitude: number
    },
    source?: CancelTokenSource
}, thunkAPI: unknown) => {
    try {
        const {workspaceId, leftBottomCorner, rightTopCorner, ApplyFilters, source } = data;
        // const request = createRequestModel(mapOptions, filterOptions, categorizeOptions)
        const response = await api.get<string, {data: IMapViewPointsResponse}>(`/Workspace/${workspaceId}/MapView/Data`, {
            params: { 
                'LeftBottom.Latitude': leftBottomCorner?.latitude, 
                'LeftBottom.Longitude':leftBottomCorner?.longitude, 
                'RightTop.Latitude': rightTopCorner?.latitude, 
                'RightTop.Longitude': rightTopCorner?.longitude,
                ApplyFilters: ApplyFilters 
            },
            cancelToken: source?.token
        });   
        
        
        return {mapViewData: response.data}
    } catch (e: unknown) {
        const validationMessage = e as { message: string, response: { data: { status: string, title: string } } };
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: string) => string, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage.message);
    }
}