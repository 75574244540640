import { removeWorkSpaceFromLS } from 'helpers/functions/workspaceHelpers';
import React, { useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Navbar from '../NavBar/Navbar';
import SideBar from '../SideBar/SideBar';
import { sendPageViewGA } from 'helpers/functions/GoogleAnalytics/GA';


const Layout = () => {
  const navigate = useNavigate();
  const timeoutId = useRef<unknown>(null)


  const INACTIVITY_TIMEOUT = 21600000 // 6 hours

  const handleUserActivity = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if(timeoutId.current) clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(()=>{
      localStorage.removeItem('user');
      localStorage.removeItem('mfaVerified');
      removeWorkSpaceFromLS()
      navigate('/signin')
      window.location.reload()
    }, INACTIVITY_TIMEOUT)
  }

  useEffect(()=>{
    window.addEventListener('mousemove', handleUserActivity)
    window.addEventListener('mouseup', handleUserActivity)
    return () => {
      window.removeEventListener('mousemove', handleUserActivity)
      window.removeEventListener('mouseup', handleUserActivity)
    }
  }, [])

  useEffect(()=>{
    sendPageViewGA()
  }, [window.location.pathname])

  return (
    <div className='page-content'>
      <Navbar />
      <SideBar />
      <main className='main-content'>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
};

export default Layout;