import React, {ReactNode, useMemo} from 'react'
import { useAppSelector } from 'hooks/redux'
import { selectActiveUser } from 'store/userState/selectors'
import './style.scss'
import { LICENSE_TYPES } from 'helpers/constants/app'

type Props = {
    children: ReactNode,
    roles: any[],
    condition?: boolean,
    blured?: boolean
}

const WithRole = ({children, roles, condition = true, blured}: Props) => {
    
    const user = useAppSelector(selectActiveUser)
    const isActive = useMemo(() => {
        return (user.licenseType === LICENSE_TYPES.global) || (roles.includes(user.licenseType) && condition)
    }, [user, roles, condition])

    const className = user.licenseType === LICENSE_TYPES.global ? '' : blured ? 'blur' : isActive ? '' : 'disabledAction'

  return (
    <div title={isActive ? '' : "Unlock the full potential of bMark, contact sales@belltreegroup.co.uk today." } className={className}>{children}</div>
  )
}

export default WithRole