import React, { FC } from 'react';
import { PROJECTS_NAVBAR_TEMPLATE } from 'helpers/constants/ccs';
import { generateNavLinkActiveClassName } from 'helpers/functions/commons';
import { NavLink } from 'react-router-dom';
import './navbar.scss';

export const ProjectsNavbar: FC = () => {
    return (
        <div className='projects-nav'>
            {
                PROJECTS_NAVBAR_TEMPLATE.map(({ title, path, disabled }) => {
                    return (
                            <NavLink
                                key={path} 
                                to={path}
                                className={generateNavLinkActiveClassName}
                                onClick={(e) => disabled && e.preventDefault()}
                            >
                                <div title={disabled ? 'Coming soon.' : ''} className={disabled ? 'disabledNav' : ''} >
                                    {title}
                                </div>
                            </NavLink>
                    )
                })
            }
        </div>
    )
}