/* eslint-disable @typescript-eslint/no-explicit-any */
import { addNewHistogramPlotThunk, addNewPlotThunk, getPlotThunk, T_addGridDataView, T_addNewProjectView, T_changeBenchmark, T_getBenchmarkData, T_getBenchmarkDatasets, T_getBenchmarkIndentifierFields, T_getBenchmarkOptions, T_getBenchmarkTypes, T_getHistogramPlotData, T_getProductionPlotData } from './thunk';
import { ExploreContentTabsInterface, IBenchmarkType, ICategory, IFilteredData, IPlotType, IRegressionFormulas, IUserDataLegend } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import benchmarkingPlot from '../../assets/icons/benchmarkingPlot.svg';
import searchListDark from '../../assets/icons/searchListDark.svg';
import crossplot from '../../assets/icons/crossplot.svg';
import histogram from '../../assets/icons/histogram.svg';
import productionPlot from '../../assets/icons/productionPlot.svg';
import dataGrid from '../../assets/icons/dataGrid.svg';
import statisticsTable from '../../assets/icons/statisticsTable.svg';
import { v4 } from 'uuid';

interface ExploreState {
  selectedTab: ExploreContentTabsInterface,
  tabs: ExploreContentTabsInterface[],
  regressionFormulas: IRegressionFormulas[];
  categories: ICategory[],
  plot: Plotly.Data[],
  userDataLegends: IUserDataLegend[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  histogram: any,
  productionPlot: {
    plotAxisTitleOptions: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productionPlots: any[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    regressionFormulasInfo: any[],
    productionInfoColumns: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      productionInfoColumns: any[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      productionInfoColumnsRows: any[],
    }

  },
  filteredData: IFilteredData,
  isLoading: boolean,
  isCrossPlotLoading: boolean,
  isHistogramLoading: boolean,
  isProductionLoading: boolean,
  isPanelBarExpanded: boolean,
  error: null | string,
  addPlotPopup: boolean,
  plotTypesData: IPlotType[],
  benchmark: {
    isFetched: boolean,
    loading: boolean,
    benchmarkTypes: IBenchmarkType[],
    isFetchedBenchmarkTypes: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    benchmark: {
      generatedPlot: any,
      outputTable: {
        columns: any[]
        rows: any
      },
      inputTable: {
        columns: any[]
        rows: any
      }
    }
    indentifierFields: any[],
    datasets: any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    benchmarkOptions: any
  }
}

const initialState: ExploreState = {
  selectedTab: {
    id: '',
    defaultOption: false,
    icon: '',
    title: '',
    isSelected: false,
    xAxis: 'X',
    yAxis: 'Y',
  },
  filteredData: {
    globalDataCount: 0,
    productionDataCount: 0,
    userDataCount: 0,
    storageDataCount: 0,
    ccsProjectDataCount: 0,
    sourceDataCount: 0,
    transportDataCount: 0,
  },
  isPanelBarExpanded: false,
  userDataLegends: [],
  regressionFormulas: [],
  categories: [],
  plot: [
    {
      x: [],
      y: [],
      type: 'scattergl',
      mode: 'markers',
      marker: { color: 'red' },
      hoverinfo: 'none'
    }
  ],
  histogram: {
    data: [],
    statisticsData: [],
    percentages: [],
  },
  productionPlot: {
    plotAxisTitleOptions: {Title: {}, XAxis: {}, YAxis:{}, Y2Axis: {}},
    productionPlots: [],
    regressionFormulasInfo: [],
    productionInfoColumns: {
      productionInfoColumns: [],
      productionInfoColumnsRows: [],
    }
  },
  benchmark: {
    loading: false,
    isFetched: false,
    benchmarkTypes: [],
    isFetchedBenchmarkTypes: false,
    benchmarkOptions: {},
    indentifierFields: [],
    datasets: [],
    benchmark: {
      generatedPlot: {},
      outputTable: {
        columns: [],
        rows: []
      },
      inputTable: {
        columns: [],
        rows: []
      },
    }
  },
  tabs: [],
  isLoading: false,
  isCrossPlotLoading: false,
  isHistogramLoading: false,
  isProductionLoading: false,
  error: null,
  addPlotPopup: false,
  plotTypesData: [
    {
      id: v4(),
      image: crossplot,
      text: 'Crossplot',
      active: true,
      disabled: false,
      type: 1,
      setPlotType: () => undefined,
      noData: false,
    },
    {
      id: v4(),
      image: histogram,
      text: 'Histogram',
      active: false,
      disabled: false,
      type: 2,
      setPlotType: () => undefined,
      noData: false,
    },
    {
      id: v4(),
      image: statisticsTable,
      text: 'Statistics Table',
      active: false,
      disabled: false,
      type: 3,
      setPlotType: () => undefined,
      noData: true,
    },
    {
      id: v4(),
      image: dataGrid,
      text: 'Data Grid',
      active: false,
      disabled: false,
      type: 4,
      setPlotType: () => undefined,
      noData: false,
    },
    {
      id: v4(),
      image: productionPlot,
      text: 'Production Plot',
      active: false,
      disabled: false,
      type: 6,
      setPlotType: () => undefined,
      noData: false,
    },
    {
      id: v4(),
      image: benchmarkingPlot,
      text: 'Benchmarking Plot',
      active: false,
      disabled: false,
      type: 7,
      setPlotType: () => undefined,
      noData: false,
    },
    {
      id: v4(),
      image: searchListDark,
      text: 'Project viewer',
      active: false,
      disabled: false,
      type: 5,
      setPlotType: () => undefined,
      noData: false,
    },
  ],
};

export const exploreSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTabs: (state, action) => {
      state.tabs = action.payload
    },

    setIsPanelBarExpanded: (state, action) => {
      state.isPanelBarExpanded = action.payload;
    },

    toggleAddPlotPopup: (state) => {
      state.addPlotPopup = !state.addPlotPopup;
    },

    setActivePlotType: (state, action) => {
      state.plotTypesData.forEach((type: IPlotType) => {
        type.active = false
        if (type.id === action.payload) {
          type.active = true;
        }
      });
    },
    setActivePlotTypeByType: (state, action) => {
      state.plotTypesData.forEach((type: IPlotType) => {
        type.active = false
        if (type.type === action.payload) {
          type.active = true;
        }
      });
    },
  },
  extraReducers: {
    [getPlotThunk.pending.type]: (state) => {
      state.isLoading = true;
      state.isCrossPlotLoading = true;
      
    },

    [getPlotThunk.fulfilled.type]: (state, action) => {
      state.userDataLegends = action.payload.userDataLegends;
      state.plot = action.payload.crossPlots;
      state.regressionFormulas = action.payload.regressionsFormulasInfo;
      state.categories = action.payload.categories
      state.isLoading = false;
      state.isCrossPlotLoading = false
      state.error = null;
    },

    [getPlotThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isCrossPlotLoading = false;
      state.error = action.payload;
    },

    [addNewPlotThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [addNewPlotThunk.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },

    [addNewPlotThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [T_addNewProjectView.pending.type]: (state) => {
      state.isLoading = true;
    },

    [T_addNewProjectView.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },

    [T_addNewProjectView.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },


    [T_addGridDataView.pending.type]: (state) => {
      state.isLoading = true;
    },

    [T_addGridDataView.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },

    [T_addGridDataView.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [addNewHistogramPlotThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [addNewHistogramPlotThunk.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [addNewHistogramPlotThunk.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },

    [T_getHistogramPlotData.pending.type]: (state) => {
      state.isHistogramLoading = true
    },
    [T_getHistogramPlotData.fulfilled.type]: (state, action) => {
      state.histogram = {
        data: action.payload.histogram,
        statisticsData: action.payload.statisticsData,
        percentages: action.payload.percentages
      };
      state.isHistogramLoading = false;
      state.error = null;
    },
    [T_getHistogramPlotData.rejected.type]: (state, action) => {
      state.isHistogramLoading = false
      state.error = action.payload
    },
    [T_getProductionPlotData.pending.type]: (state) => {
      state.isLoading = true
    },
    [T_getProductionPlotData.fulfilled.type]: (state, action) => {
      state.productionPlot = action.payload;
      // state.userDataLegends = action.payload.userDataLegends;
      state.regressionFormulas = action.payload.regressionsFormulasInfo;
      state.isLoading = false;
      state.error = null;
    },
    [T_getProductionPlotData.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },

    [T_getBenchmarkTypes.pending.type]: (state) => {
      state.isLoading = true
    },
    [T_getBenchmarkTypes.fulfilled.type]: (state, action) => {
      state.benchmark.benchmarkTypes = action.payload;
      state.isLoading = false;
      state.error = null;
      state.benchmark.isFetchedBenchmarkTypes = true
    },
    [T_getBenchmarkTypes.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.benchmark.isFetchedBenchmarkTypes = true
    },

    [T_getBenchmarkData.pending.type]: (state) => {
      state.isLoading = true
      state.benchmark.loading = true
      
    },
    [T_getBenchmarkData.fulfilled.type]: (state, action) => {
      state.benchmark.benchmark = action.payload;
      const BenchmarkUserDataOptins = action.payload.generatedPlot?.userData.reduce((acc: {displayTexts: string[], oneMarkerForAll: {size: number, color: string}[], markers: {size: number, color: string}[]}, item: any) => { 
        item.markers && acc.markers.push(...item.markers);
        if(item.oneMarkerForAll){
          acc.oneMarkerForAll?.push(item.oneMarkerForAll)
        }
        acc.displayTexts.push(...item.displayTexts);  
        return acc 
      }, {displayTexts: [], oneMarkerForAll: [], markers: []})
      const benchmarkUserDataLegends = BenchmarkUserDataOptins?.displayTexts?.map((_: never, i: number) => ({ name: BenchmarkUserDataOptins.displayTexts[i], color: BenchmarkUserDataOptins.oneMarkerForAll[0]?.color || BenchmarkUserDataOptins.markers[i]?.color }));
      state.regressionFormulas = action.payload.regressionsFormulasInfo;
      state.userDataLegends = benchmarkUserDataLegends
      state.isLoading = false;
      state.benchmark.loading = false
      state.error = null;
      state.benchmark.isFetched = true
    },
    [T_getBenchmarkData.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.benchmark.loading = false
      // state.isFetchedBenchmark = true
    },

    [T_getBenchmarkOptions.pending.type]: (state) => {
      state.isLoading = true
    },
    [T_getBenchmarkOptions.fulfilled.type]: (state, action) => {
      state.benchmark.benchmarkOptions = action.payload;
      // state.userDataLegends = action.payload.userDataLegends;
      state.isLoading = false;
      state.error = null;
      // state.isFetchedBenchmark = true
    },
    [T_getBenchmarkOptions.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      // state.isFetchedBenchmark = true
    },







    [T_changeBenchmark.pending.type]: (state) => {
      state.isLoading = true
    },
    [T_changeBenchmark.fulfilled.type]: (state, action) => {
      state.benchmark.benchmarkOptions = action.payload;


      // state.userDataLegends = action.payload.userDataLegends;
      state.isLoading = false;
      state.error = null;
      // state.isFetchedBenchmark = true
    },
    [T_changeBenchmark.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      // state.isFetchedBenchmark = true
    },


    [T_getBenchmarkDatasets.pending.type]: (state) => {
      state.isLoading = true
    },
    [T_getBenchmarkDatasets.fulfilled.type]: (state, action) => {
      state.benchmark.datasets = action.payload;


      // state.userDataLegends = action.payload.userDataLegends;
      state.isLoading = false;
      state.error = null;
      // state.isFetchedBenchmark = true
    },
    [T_getBenchmarkDatasets.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      // state.isFetchedBenchmark = true
    },




    [T_getBenchmarkIndentifierFields.pending.type]: (state) => {
      state.isLoading = true
    },
    [T_getBenchmarkIndentifierFields.fulfilled.type]: (state, action) => {
      state.benchmark.indentifierFields = action.payload;


      // state.userDataLegends = action.payload.userDataLegends;
      state.isLoading = false;
      state.error = null;
      // state.isFetchedBenchmark = true
    },
    [T_getBenchmarkIndentifierFields.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      // state.isFetchedBenchmark = true
    },

  },
});

export default exploreSlice.reducer;
