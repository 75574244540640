import React from 'react';
import { NotificationGroup } from '@progress/kendo-react-notification';

import { useAppSelector } from '../../hooks/redux'
import SingleNotification from './SingleNotification';

export default function Notifications() {
    const {notifications} = useAppSelector((state) => state.notificationsSlice)

    return (
        <NotificationGroup
            style={{
                flexWrap: 'wrap-reverse',
                alignItems: 'flex-end',
                zIndex: 1000,
                paddingTop: 5
            }}
        >
            {notifications.map(i => {
                return (
                    <SingleNotification key={i.id} {...i} />
                );
            })}
        </NotificationGroup>
    )
}