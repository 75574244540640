import React, { useEffect, useMemo, useState } from "react";
import './productionPlotOptions.scss';
import { InputCharactersCounter, NumericInput, TextInput } from "components/SideBarItems/Input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { T_saveProductionPlotOptions } from "store/sidebar/thunk";
import { KendoCheckbox } from "components/SideBarItems/Checkbox";
import { sideBarTreeSlice } from "store/sidebar/slice";
import { IElementType } from "components/SideBarItems/types";
import { selectSelectedTab } from "store/workspace/slice";
import { WrappedDropDown } from "components/SideBarItems/DatePicker";
import { T_getProductionPlotData } from "store/explore/thunk";
import { LICENSE_TYPES } from "helpers/constants/app";
import WithRole from "components/userRoles/withRole/WithRole";

export const ProductionPlotOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const [opened, setOpened] = useState<boolean>(true);
    const { productionPlot, productionTagAttributesValues } = useAppSelector(state => state.sideBarTreeSlice);
    const { workspaceId, plotId, workspaceListLoading } = useAppSelector(state => state.workspaceSlice);
    const selectedTab = useAppSelector(selectSelectedTab);
    const isDateSelectedForX = useMemo(() => productionTagAttributesValues.find(item => item.id === productionPlot.xTagAttributeId)?.key === 'Date', [productionTagAttributesValues, productionPlot.xTagAttributeId])
    const selectedPlotId = selectedTab?.id || plotId
    
    useEffect(() => {
        let data = [
            'xMinDate',
            'xMaxDate',
            'xStartDate',
            'xEndDate',
            'xForecastDate',
            'xValueDate',
        ]
        if (isDateSelectedForX) {
            data = [
                'xMin',
                'xMax',
                'xStart',
                'xEnd',
                'xForecast',
                'xValue',
            ]
        }
        data.forEach((item) => {
            handleChangeValue({
                element: item as IElementType,
                value: null,
                group: 'clientSideOptions'
            });
        })
    }, [isDateSelectedForX])
    // useEffect(() => {
    //     if (plotId && workspaceId && selectedTab?.type === 1) {
    //         dispatch(getPlotOptionsThunk({ workspaceId, plotId: selectedTab.id }));
    //     }
    // }, [plotId, workspaceId, selectedTab]);

    const handleChangeValue = (obj: { element?: IElementType, value?: unknown, group?: string | null, index?: number }): void => {
        dispatch(sideBarTreeSlice.actions.setProductionPlotOptionItem(obj));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSaveValues = async (element?: IElementType, group?: string | null) => {
        if(selectedTab?.id){
            await dispatch(T_saveProductionPlotOptions({ productionPlotOptions: productionPlot, plotId: selectedTab?.id, workspaceId }));
        }
        if (group === 'serverSideOptions' && selectedTab?.id && workspaceId && !workspaceListLoading) {
            dispatch(T_getProductionPlotData({ workspaceId, plotId: selectedTab?.id }))
        }
    };

    return (
        <div className="productionPlotOptions">
            <div className="contentVisibilityHandler">
                <h3><span onClick={() => setOpened(!opened)} className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Production Profile Options</h3>
            </div>
            <div className={opened ? "content" : "content closed"}>
                <InputCharactersCounter
                    label="Plot Title"
                    element="title"
                    max={250}
                    value={productionPlot.title}
                    group={null}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                {isDateSelectedForX ?
                    <>
                        <WrappedDropDown
                            value={productionPlot.productionPlotOptions.xMinDate}
                            label="X Min"
                            element="xMinDate"
                            group="clientSideOptions"
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                        <WrappedDropDown
                            label="X Max"
                            element="xMaxDate"
                            group="clientSideOptions"
                            value={productionPlot.productionPlotOptions.xMaxDate}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                    </>
                    :
                    <>
                        <NumericInput label="X Min"
                            element="xMin"
                            group="clientSideOptions"
                            value={productionPlot.productionPlotOptions.xMin}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                        <NumericInput label="X Max"
                            element="xMax"
                            group="clientSideOptions"
                            value={productionPlot.productionPlotOptions.xMax}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                    </>
                }
                <NumericInput label="Y Min"
                    element="yMin"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.yMin}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput label="Y Max"
                    element="yMax"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.yMax}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput label="Y2 Min"
                    element="y2Min"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.y2Min}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput label="Y2 Max"
                    element="y2Max"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.y2Max}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="X Gridlines"
                    element="xGridlines"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.xGridlines}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Y Gridlines"
                    element="yGridlines"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.yGridlines}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="X Logarithmic"
                    element="xLogarithmic"
                    group="serverSideOptions"
                    value={productionPlot.productionPlotOptions.xLogarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Y Logarithmic"
                    element="yLogarithmic"
                    group="serverSideOptions"
                    value={productionPlot.productionPlotOptions.yLogarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Y2 Logarithmic"
                    element="y2Logarithmic"
                    group="serverSideOptions"
                    value={productionPlot.productionPlotOptions.y2Logarithmic}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="X Invert"
                    element="xInvert"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.xInvert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Y Invert"
                    element="yInvert"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.yInvert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Y2 Invert"
                    element="y2Invert"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.y2Invert}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Cascade Fields Across All Profiles"
                    element="cascadeFieldsAcrossAllProfiles"
                    group="serverSideOptions"
                    value={productionPlot.productionPlotOptions.cascadeFieldsAcrossAllProfiles}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Legends</h4>
                <KendoCheckbox label="Show Filters"
                    group="clientSideOptions"
                    element="showFilters"
                    value={productionPlot.productionPlotOptions.showFilters}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Show Series"
                    group="clientSideOptions"
                    element="showSeries"
                    value={productionPlot.productionPlotOptions.showSeries}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox label="Show Regression Equations"
                    group="clientSideOptions"
                    element="showRegressionEquations"
                    value={productionPlot.productionPlotOptions.showRegressionEquations}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Labels</h4>
                <KendoCheckbox
                    label="Show User Data Labels"
                    element="showUserDataLabels"
                    group="serverSideOptions"
                    value={productionPlot.productionPlotOptions.showUserDataLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                {/* <KendoCheckbox
                    label="Show User Background Labels"
                    element="showUserBackgroundLabels"
                    group="serverSideOptions"
                    value={productionPlot.productionPlotOptions.showUserBackgroundLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                /> */}
                <WithRole roles={[LICENSE_TYPES.global]}>
                    <KendoCheckbox
                        label="Show Global Database Labels"
                        element="globalDatabaseLabels"
                        group="serverSideOptions"
                        value={productionPlot.productionPlotOptions.globalDatabaseLabels}
                        setFunction={handleChangeValue}
                        saveFunction={handleSaveValues}
                    />
                </WithRole>
                <KendoCheckbox
                    label="Show Axis Labels"
                    element="showAxisLabels"
                    group="clientSideOptions"
                    value={productionPlot.productionPlotOptions.showAxisLabels}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <h4>Regression</h4>
                {isDateSelectedForX ?
                    <>
                        <WrappedDropDown
                            label="X Start"
                            element="xStartDate"
                            group="serverSideOptions"
                            value={productionPlot.productionPlotOptions.xStartDate}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                        <WrappedDropDown
                            label="X End"
                            element="xEndDate"
                            group="serverSideOptions"
                            value={productionPlot.productionPlotOptions.xEndDate}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                        <WrappedDropDown
                            label="X Forecast"
                            element="xForecastDate"
                            group="serverSideOptions"
                            value={productionPlot.productionPlotOptions.xForecastDate}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                    </>
                    :
                    <>
                        <NumericInput label="X Start"
                            element="xStart"
                            group="serverSideOptions"
                            value={productionPlot.productionPlotOptions.xStart}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                        <NumericInput label="X End"
                            element="xEnd"
                            group="serverSideOptions"
                            value={productionPlot.productionPlotOptions.xEnd}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                        <NumericInput label="X Forecast"
                            element="xForecast"
                            group="serverSideOptions"
                            value={productionPlot.productionPlotOptions.xForecast}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                    </>
                }

                <KendoCheckbox
                    label="Lin Regression"
                    group="serverSideOptions"
                    element="linRegression"
                    value={productionPlot.productionPlotOptions.linRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Log Regression"
                    group="serverSideOptions"
                    element="logRegression"
                    value={productionPlot.productionPlotOptions.logRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Exp Regression"
                    group="serverSideOptions"
                    element="expRegression"
                    value={productionPlot.productionPlotOptions.expRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Pow Regression"
                    group="serverSideOptions"
                    element="powRegression"
                    value={productionPlot.productionPlotOptions.powRegression}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <NumericInput
                    label="Intcp Value"
                    group="serverSideOptions"
                    element="intcpValue"
                    value={productionPlot.productionPlotOptions.intcpValue}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Intcp"
                    group="serverSideOptions"
                    element="intcp"
                    value={productionPlot.productionPlotOptions.intcp}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Add point"
                    group="serverSideOptions"
                    element="addPoint"
                    value={productionPlot.productionPlotOptions.addPoint}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />


                {isDateSelectedForX ?
                    <>
                        <WrappedDropDown
                            label="X Value"
                            group="serverSideOptions"
                            element="xValueDate"
                            value={productionPlot.productionPlotOptions.xValueDate}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                    </>
                    :
                    <>
                        <NumericInput
                            label="X Value"
                            group="serverSideOptions"
                            element="xValue"
                            value={productionPlot.productionPlotOptions.xValue}
                            setFunction={handleChangeValue}
                            saveFunction={handleSaveValues}
                        />
                    </>
                }

                <NumericInput
                    label="Y Value"
                    group="serverSideOptions"
                    element="yValue"
                    value={productionPlot.productionPlotOptions.yValue}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <TextInput
                    label="Label"
                    group="serverSideOptions"
                    element="label"
                    value={productionPlot.productionPlotOptions.label}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
            </div>
        </div>
    )
}

