import React, { useEffect } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { workspaceSlice } from '../../store/workspace/slice';
import { FILTER_TYPES } from '../../helpers/constants/filters';
import { ITreeElement } from '../../helpers/types/tree';
import { ICondition } from 'store/sidebar/types';

interface IProps {
  props: ITreeElement,
  inputs: {
    firstConditionInputValue?: string,
    setFirstConditionInputValue: (e: string) => void,
    secondConditionInputValue?: string,
    setSecondConditionInputValue: (e: string) => void,
    firstConditionListValue?: ICondition,
    setFirstConditionListValue: (e: ICondition) => void,
    secondConditionListValue?: ICondition,
    setSecondConditionListValue: (e: ICondition) => void,
    logicalOperationListValue?: ICondition,
    setLogicalOperationListValue: (e: ICondition) => void
  }
}

const FilterConditions = ({ props, inputs }: IProps) => {
  const dispatch = useAppDispatch();
  const filtersSearchText = useAppSelector(store => store.sideBarTreeSlice.filtersSearchText)
  const filtersSearched = !!filtersSearchText
  const { stringOperations, numericOperations, booleanOperations } = useAppSelector(state => state.sideBarTreeSlice);
  const operationType = props.item.type === FILTER_TYPES.filter ? stringOperations : numericOperations;
  const {
    firstConditionInputValue, setFirstConditionInputValue,
    secondConditionInputValue, setSecondConditionInputValue,
    firstConditionListValue, setFirstConditionListValue,
    secondConditionListValue, setSecondConditionListValue,
    logicalOperationListValue, setLogicalOperationListValue
  } = inputs

  const handleChangeFirstConditionList = (e: DropDownListChangeEvent) => {
    setFirstConditionListValue(e.value);
  };

  const handleChangeLogicalOperationList = (e: DropDownListChangeEvent) => {
    setLogicalOperationListValue(e.value);
  };

  const handleChangeSecondConditionList = (e: DropDownListChangeEvent) => {
    setSecondConditionListValue(e.value);
  };

  const handleChangeFirstConditionInput = (e: InputChangeEvent) => {
    setFirstConditionInputValue(e.value);
  };

  const handleChangeSecondConditionInput = (e: InputChangeEvent) => {
    setSecondConditionInputValue(e.value);
  };

  useEffect(() => {
    if (typeof props.item.firstConditionInput === "string") {
      setFirstConditionInputValue(props.item.firstConditionInput);
    }
  }, [props.item.firstConditionInput]);

  useEffect(() => {
    if (typeof props.item.secondConditionInput === "string") {
      setSecondConditionInputValue(props.item.secondConditionInput);
    }
  }, [props.item.secondConditionInput]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }

  return (
    <div className={'filterPopup'} onKeyDown={handleKeyDown}>
      <DropDownList
        className="firstConditionList"
        onChange={handleChangeFirstConditionList}
        style={{width: '100%'}}
        textField="name" data={operationType}
        value={firstConditionListValue}

      />
      <Input
        className="firstConditionInput"
        style={{width: '100%'}}
        onChange={handleChangeFirstConditionInput}
        value={firstConditionInputValue}
        type="text"
      />
      <DropDownList
        className="logicalOperationList"
        style={{width: '100%'}}
        onChange={handleChangeLogicalOperationList}
        textField="name"
        data={booleanOperations} 
        value={logicalOperationListValue}  
      />
      <DropDownList
        className="secondConditionList"
        style={{width: '100%'}}
        onChange={handleChangeSecondConditionList}
        textField="name" data={operationType}
        value={secondConditionListValue}
      />
      <Input
        className="secondConditionInput"
        style={{width: '100%'}}
        value={secondConditionInputValue}
        onChange={handleChangeSecondConditionInput}
      />
    </div>
  );
};


export default FilterConditions;