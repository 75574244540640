/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import './FilterOptions.scss';
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { KendoCheckbox } from "components/SideBarItems/Checkbox";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { IElementType } from "components/SideBarItems/types";
import { workspaceSlice, selectSelectedTab } from "store/workspace/slice";
import { selectAllTagAttributes } from "store/sidebar/selectors";
import { trackGAEvent } from "helpers/functions/GoogleAnalytics/GA";
import { eventsGA } from "helpers/functions/GoogleAnalytics/types";
import { R_clearFilterSetSelection } from "api/sidebar/sideBarService";
import { T_changeFilterset, T_deleteFilterset, T_getFilterset, getSideBarFiltersThunk } from "store/sidebar/thunk";
import { sideBarTreeSlice } from "store/sidebar/slice";
import { IFilterSet, TreeViewDataItem } from "store/sidebar/types";
import { DropDownList, DropDownListChangeEvent, DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { handleTreeViewCheckChange, ItemRenderProps, processTreeViewItems, TreeView, TreeViewCheckChangeEvent, TreeViewCheckDescriptor, TreeViewExpandChangeEvent } from '@progress/kendo-react-treeview';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import WithRole from "components/userRoles/withRole/WithRole";
import { selectAllTags } from "store/ccs/selectors";
import { LICENSE_TYPES } from "helpers/constants/app";
import { getWorkspaceByIdThunk } from "store/workspace/thunk";
import { filterBy } from "@progress/kendo-data-query";
import { deepClone } from 'helpers/functions/commons';
import { Loader } from "@progress/kendo-react-indicators";
import AddFilterSetPortal from "./addFilterSetPortal";

export const FilterOptions: React.FC<{searchValue: string}> = ({searchValue}) => {
    const dispatch = useAppDispatch();
    const { filterOptions } = useAppSelector(state => state.workspaceSlice.filters)
    const tagAttributes = useAppSelector(selectAllTagAttributes)
    const { workspaceId, tree, workspaceListLoading, filteredFilters, filters: {filterOptions: {filters}} } = useAppSelector(store => store.workspaceSlice)
    const allTags = useAppSelector(selectAllTags)
    const selectedTab = useAppSelector(selectSelectedTab)
    const { filterSets: { list, loading, error, selected} } = useAppSelector(state => state.sideBarTreeSlice)
    const [data, setData] = useState<IFilterSet[]>([])
    const [showAddFilterSetPortal, setShowAddFilterSetPortal] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false);

    const searchTimeoutId = useRef<any>(null)

    const handleChangeValue = (obj: { element?: IElementType, value?: unknown, group?: string | null, index?: number }): void => {
        dispatch(workspaceSlice.actions.setFilterOptionItem(obj))
        switch (obj.element) {
            case 'applyToUserData':
                trackGAEvent(eventsGA.filters_applied_user_data)
                break;
            case 'applyToBenchmarkData':
                trackGAEvent(eventsGA.filters_applied_benchmark)
                break;
            default:
                break;
        }
    }
    const contains = (name: string | undefined, term: string) => {
        return name ? name.toLowerCase().indexOf(term.toLowerCase()) >= 0 : true;
    };
    const search = (items: TreeViewDataItem[], term: string) => {

        return items?.reduce((acc: TreeViewDataItem[], item: TreeViewDataItem) => {
            if (contains(item.name, term) && item.name) {
                acc.push(item);
            } else if (item.items && item.items.length > 0) {
                const newItems = search(item.items, term);
                if (newItems && newItems.length > 0) {
                    acc.push({
                        element: item.element,
                        type: item.type,
                        name: item.name,
                        items: newItems,
                        expanded: true,
                        id: item.id
                    });
                }
            }
            return acc;
        }, []);
    };

    const clearAllFilters = async () => {
        dispatch(sideBarTreeSlice.actions.setFiltersSearchText(''))
        dispatch(workspaceSlice.actions.clearAllFilters())
        dispatch(workspaceSlice.actions.clearFiltersApplied())
        const filters = search(tree[0].items, searchValue);
        searchValue ?
            dispatch(workspaceSlice.actions.setFilteredFilters(filters)) :
            dispatch(workspaceSlice.actions.setFilteredFilters([]));
        await R_clearFilterSetSelection({workspaceId}, null)
        dispatch(sideBarTreeSlice.actions.setSelectedFilterSet(null))
        await dispatch(getSideBarFiltersThunk({ tagAttributes }));
    }

    const handleSaveValues = async (_element?: IElementType | undefined, _group?: string | null | undefined) => null;

    const handleOpenPortal = async () => {
        setShowAddFilterSetPortal(true)
    }

    const handleClosePortal = async () => {
        setShowAddFilterSetPortal(false)
    }

    const handleDelete = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, title: string) => {
        e.stopPropagation()
        e.preventDefault()
        const id = list.find(item=>item.title === title)?.id
        if(id){
           await dispatch(T_deleteFilterset({workspaceId, filterSetId: id}))
           await dispatch(T_getFilterset({workspaceId}))
           dispatch(sideBarTreeSlice.actions.setSelectedFilterSet(null))
        }
    };

    const itemRender = (li: any) => {
        const deleteElement = (
            <span onClick={(e) => handleDelete(e, li.props.children[0].props.children)} className="k-icon k-i-delete" style={{ position: "absolute", right: "0.2rem", marginTop: ".2rem" }} />
        );
        const icon = deleteElement;
        const itemChildren = (
            <div>
                <span>{li.props.children}</span>
                {icon}
            </div>
        );

        return React.cloneElement(li, li.props, itemChildren);
    }

    const handleChange = async (e: DropDownListChangeEvent) => {
        dispatch(sideBarTreeSlice.actions.setSelectedFilterSet(e.value))
        await dispatch(T_changeFilterset({workspaceId, filterSetId: e.value.id}))
        if(selectedTab?.id){
            await dispatch(getWorkspaceByIdThunk({id: workspaceId, allTags: allTags, tabId: selectedTab.id}))
            await dispatch(getSideBarFiltersThunk({tagAttributes: allTags}))
        }
    };

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        if (!event.filter.value) {
          return setData(list)
        }
        return setData(filterBy(list, event.filter))
      }

      React.useEffect(()=>{
        const getAll = async (workspaceId: string) => {
            await dispatch(T_getFilterset({workspaceId}))
        }
        if(workspaceId){
            getAll(workspaceId)
        }
      }, [workspaceId])

      React.useEffect(()=>{
        setData(list)
      }, [list])

    
    const handleSearch = (e: InputChangeEvent) => {
        const { value } = e;
        setSearchLoading(true)
        dispatch(sideBarTreeSlice.actions.setFiltersSearchText(value))
    };

    useEffect(() => {
        if (tree[0]?.items) {
            if (searchTimeoutId.current) clearTimeout(searchTimeoutId.current)
            searchTimeoutId.current = setTimeout(() => {
                const cloned = deepClone(tree)
                const filters = search(tree[0]?.items, searchValue);
                cloned[0].items = filters
                searchValue ?
                    dispatch(workspaceSlice.actions.setFilteredFilters(cloned)) :
                    dispatch(workspaceSlice.actions.setFilteredFilters([]));
                setSearchLoading(false)
            }, 600)
        }
    }, [searchValue])

    return (
      <div className="header">
        <DropDownList
          data={data}
          itemRender={itemRender}
          loading={loading}
          textField="title"
          filterable={true}
          onFilterChange={filterChange}
          onChange={handleChange}
          value={selected || { title: "Recall Saved Filters", id: "" }}
        />

        <div className={`searchFilterInput`} style={{ display: tree[0].expanded ? "block" : "block" }}>
          <Input className="k-textbox" onChange={handleSearch} placeholder="Search Filters" value={searchValue} />
          {searchLoading && (
            <span className="searchLoader">
              <Loader />
            </span>
          )}
        </div>

        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <Button
            title={!filterOptions.isEnabled ? "Enable Filters" : "Disable Filters"}
            icon="filter"
            themeColor={filterOptions.isEnabled ? "primary" : "light"}
            onClick={() =>
              handleChangeValue({
                element: "isEnabled",
                value: !filterOptions.isEnabled,
                group: "filterOptions",
              })
            }
          />
          <Button title="Clear All Filters" icon="filter-clear" themeColor={"light"} onClick={clearAllFilters} />
          <Button
            title="Apply to User Data"
            icon="file-data"
            themeColor={filterOptions.applyToUserData ? "primary" : "light"}
            onClick={() =>
              handleChangeValue({
                element: "applyToUserData",
                value: !filterOptions.applyToUserData,
                group: "filterOptions",
              })
            }
          />
          <Button
            title="Apply to Benchmark Data"
            icon="file-data"
            themeColor={filterOptions.applyToBenchmarkData ? "primary" : "light"}
            onClick={() =>
              handleChangeValue({
                element: "applyToBenchmarkData",
                value: !filterOptions.applyToBenchmarkData,
                group: "filterOptions",
              })
            }
          />
          <WithRole roles={[LICENSE_TYPES.global]}>
            <Button title="Save current filters to Filter Sets" icon="save" disabled={!filters.length || workspaceListLoading} themeColor={"light"} onClick={handleOpenPortal}></Button>
          </WithRole>
        </Tooltip>
        <AddFilterSetPortal portalOpened={showAddFilterSetPortal} closePortal={() => { setShowAddFilterSetPortal(false) }} />
      </div>

      /* <KendoCheckbox
                    label="Apply to Benchmark plots"
                    element="applyToBenchmarkData"
                    group="filterOptions"
                    value={filterOptions.applyToBenchmarkData}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                />
                <KendoCheckbox
                    label="Apply to Production data"
                    element="applyToProductionData"
                    group="filterOptions"
                    value={filterOptions.applyToProductionData}
                    setFunction={handleChangeValue}
                    saveFunction={handleSaveValues}
                /> */
    )
}

