import { createAsyncThunk } from '@reduxjs/toolkit';
import { R_addGridDataView } from 'api/GridData/gridData';
import { R_addProjectView } from 'api/projectView/projectViewService';
import { addNewHistogramPlot, addNewPlot, getPlot, R_addProductionPlot, R_getHistogramPlotData, R_getProductionPlotData } from '../../api/plot/plotService';
import { INewHistogramPlotData, INewPlotData, IProductionPlot } from './types';
import { R_addBenchmark, R_changeBenchmark, R_getBenchmarkData, R_getBenchmarkDatasets, R_getBenchmarkIndentifierFields, R_getBenchmarkOptions, R_getBenchmarkTypes } from 'api/benchmark/benchmarkservice';
import { IBenchmarkPlotOptions } from 'store/sidebar/types';

export const getPlotThunk = createAsyncThunk('plot/getPlotThunk', async (data: { plotId: string, workspaceId: string }, thunkAPI,) => {   
    const response = await getPlot(data, thunkAPI)
    return response
});

export const addNewPlotThunk = createAsyncThunk('plot/addNewPlotThunk', async (data: { workspaceId: string, newPlotData: INewPlotData }, thunkAPI,) => {
    const response = await addNewPlot(data, thunkAPI)
    return response
});

export const addNewHistogramPlotThunk = createAsyncThunk('ploy/addNewHistogramPlot', async (data: { workspaceId: string, newPlotData: INewHistogramPlotData }, thunkAPI) => {
    return await addNewHistogramPlot(data, thunkAPI)
})

export const T_getHistogramPlotData = createAsyncThunk('ploy/addNewHistogramPlotData', async (data: { workspaceId: string, plotId: string }, thunkAPI) => {
    return await R_getHistogramPlotData(data, thunkAPI)
})

export const T_addNewProjectView = createAsyncThunk('plot/projectView', async (data: { workspaceId: string, plotId: string, datasetIds: string[] }, thunkAPI) => {
    const response = await R_addProjectView(data, thunkAPI)
    return response
})

export const T_addProductionPlot = createAsyncThunk('plot/addProductionPlot', async (data: { workspaceId: string, title: IProductionPlot['title'], xTagAttributeId: IProductionPlot['xTagAttributeId'], yTagAttributeId: IProductionPlot['yTagAttributeId'], y2TagAttributeId?: IProductionPlot['y2TagAttributeId'] }, thunkAPI) => {
    const response = await R_addProductionPlot(data, thunkAPI)
    return response
})

export const T_getProductionPlotData = createAsyncThunk('plot/getProductionPlotData', async (data: { workspaceId: string, plotId: string }, thunkAPI) => {
    const response = await R_getProductionPlotData(data, thunkAPI)
    return response
})

export const T_addGridDataView = createAsyncThunk('plot/addGridDataView', async (data: { workspaceId: string, type: string, gridColumns?: string[], datasetId: string | null, title: string | null}, thunkAPI) => {
    const response = await R_addGridDataView(data, thunkAPI)
    return response
})

export const T_getBenchmarkTypes = createAsyncThunk('plot/getBenchmarks', async (data: { id?: string }, thunkAPI) => {
    const response = await R_getBenchmarkTypes(data, thunkAPI)
    return response
})

export const T_addBenchmark = createAsyncThunk('plot/addBenchmark', async (data: { type: string | number, workspaceId: string, title: string }, thunkAPI) => {
    const response = await R_addBenchmark(data, thunkAPI)
    return response
})

export const T_getBenchmarkData = createAsyncThunk('plot/getBenchmarkData', async (data: { workspaceId: string, id: string }, thunkAPI) => {
    const response = await R_getBenchmarkData(data, thunkAPI)
    return response
})

export const T_getBenchmarkOptions = createAsyncThunk('plot/getBenchmarkOptions', async (data: { workspaceId: string, id: string }, thunkAPI) => {
    const response = await R_getBenchmarkOptions(data, thunkAPI)
    return response
})

export const T_changeBenchmark = createAsyncThunk('plot/changeBenchmark', async (data: { workspaceId: string, type: string, plotId: string, benchmarkPlotOptions: IBenchmarkPlotOptions['benchmarkPlotOptions']}, thunkAPI) => {
    const response = await R_changeBenchmark(data, thunkAPI)
    return response
})

export const T_getBenchmarkDatasets = createAsyncThunk('plot/getBenchmarkDatasets', async (data: { workspaceId: string }, thunkAPI) => {
    const response = await R_getBenchmarkDatasets(data, thunkAPI)
    return response
})

export const T_getBenchmarkIndentifierFields = createAsyncThunk('plot/getBenchmarkIndentifierFields', async (data: { workspaceId: string }, thunkAPI) => {
    const response = await R_getBenchmarkIndentifierFields(data, thunkAPI)
    return response
})





