export const saveWorkSpaceToLS = (workspaceId: string) => {
    localStorage.setItem('workspaceId', workspaceId)
}

export const getWorkSpaceFromLS = (): string => {
    return localStorage.getItem('workspaceId') || ''
}

export const removeWorkSpaceFromLS = () => {
    localStorage.removeItem('workspaceId')
}