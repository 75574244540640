import { HomeState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDatasetsThunk, getHomeMapDataThunk, getHomeNotificationsThunk, getUsersThunk, unshareWorkspaceWithMeThunk, getLinkedInPostsThunk, getLinkedInFollowersThunk } from './thunk';
const initialState: HomeState = {
  isLoading: false,
  error: null,
  mapData: null,
  showNotificationsPopup: false,
  addNewWorkspacePopup: {
    isOpen: false,
    workspace: null
  },
  showLinkedInPostsPopup: false,
  users: [],
  isUsersFetched: false,
  notifications: {
    isFetched: false,
    collection: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    totalUnreadCount: 0,
  },
  notificationsPopup: {
    collection: [],
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
  },
  linkedInPosts: {
    collection: [],
    pageNumber: 0,
    pageSize: 0,
    totalRecords: 0,
    totalPages: 0,
  },
  linkedInPostsPopup: {
    collection: [],
    pageNumber: 0,
    pageSize: 0,
    totalRecords: 0,
    totalPages: 0,
  },
  linkedinFollowers: 0,
  datasets: [],
}
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setShowNotificationsPopup: (state, action) => {
      state.showNotificationsPopup = action.payload
      if (!action.payload) state.notificationsPopup.collection = []
    },
    setAddNewWorkspacePopup: (state, action) => {
      state.addNewWorkspacePopup = action.payload
    },
    setlinkedInPostsPopup: (state, action) => {
      state.showLinkedInPostsPopup = action.payload
      if(!action.payload){
        state.linkedInPostsPopup = {
          collection: [],
          pageNumber: 0,
          pageSize: 0,
          totalRecords: 0,
          totalPages: 0,
        }
      }
    },
  },
  extraReducers: {
    [getHomeMapDataThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [getHomeMapDataThunk.fulfilled.type]: (state, action) => {
      state.mapData = action.payload
    },
    [getHomeMapDataThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getHomeNotificationsThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [getHomeNotificationsThunk.fulfilled.type]: (state, action) => {
      const { data, isLast } = action.payload
      isLast ? (state.notifications = data) : state.notificationsPopup.collection.push(...data.collection)
      state.notifications.isFetched = true
    },
    [getHomeNotificationsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getUsersThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [getUsersThunk.fulfilled.type]: (state, action) => {
      state.users = action.payload
      state.isUsersFetched = true
    },
    [getUsersThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getDatasetsThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDatasetsThunk.fulfilled.type]: (state, action) => {
      state.datasets = action.payload.datasets
    },
    [getDatasetsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [unshareWorkspaceWithMeThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [unshareWorkspaceWithMeThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getLinkedInPostsThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [getLinkedInPostsThunk.fulfilled.type]: (state, action) => {
      const { data, isLast } = action.payload
      if (isLast) {
        state.linkedInPosts = data
      } else {
        const dataToChange = state.linkedInPostsPopup
        dataToChange.collection.push(...data.collection)
        dataToChange.pageNumber = data.pageNumber
        dataToChange.totalPages = data.totalPages
        dataToChange.pageSize = data.pageSize
        dataToChange.totalRecords = data.totalRecords
      }
    },
    [getLinkedInPostsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getLinkedInFollowersThunk.pending.type]: (state) => {
      state.isLoading = true
    },
    [getLinkedInFollowersThunk.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.linkedinFollowers = payload
    },
    [getLinkedInFollowersThunk.pending.type]: (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    },
  },
})
export default homeSlice.reducer

