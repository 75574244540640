/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavLinkProps } from "react-router-dom";
import { IFilterByRoleType } from "store/user/types";
import { G_Normalized } from "../types/commons";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: deck.gl doesnt provide
import { RGBAColor } from "deck.gl";

export const combineClassNames = (classNames: string[]): string => {
	if (!classNames?.length) return '';
	return classNames.join(' ') ?? '';
}

export const normalizeByKey = (data: any[], key?: string): G_Normalized<string, any> => {
    const result: G_Normalized<string, any> = {
        byId: {},
        allIds: []
    }

    if(!data.length) return result
    
    data.forEach(project => {
        const id = project[key ?? 'id']
        result.byId[id] = project
        result.allIds.push(id)
    })

    return result
}

export const generateNavLinkActiveClassName: NavLinkProps['className'] = ({ isActive }) => isActive ? 'active' : ''

export const isDefined = (value: any) => value != null

export const filterDataByRole = (data: IFilterByRoleType[], role: string) => {
    return data.filter((item: any) => item.roles.includes(role))
}

export const handleRequestErrors = (e: any) => {
    const data = e?.response?.data
    let validationMessage = '';
    if(typeof data === 'string'){
        validationMessage = data
    }else if(data?.errors){
        for (const key in data.errors) {
            validationMessage += `${data.errors[key]}\n`;
        }
    }else if(data?.title){
        validationMessage = data.title
    }
    return validationMessage
}

export const capitalizeFirstLetter = (string: string | undefined) => {
    if(!string) return
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const immutableSplice = (arr: any[], start: number, deleteCount: number, ...addItem: any[]) => {
    const result = [];
    if (start > 0) {
        result.push(...arr.slice(0, start));
    }
    result.push(...addItem);
    const len = result.length - addItem.length;
    const count = deleteCount <= 0 ? len : len + deleteCount;
    if (arr[count]) {
        result.push(...arr.slice(count));
    }
    return result;
}


export const truncateString = (str: string, length: number) => {
    if(!str) return ''
    return str.length > length ? str.substring(0,length) + '...' : str
}

export const formatDate = (timestamp: string): string => {
    if(!timestamp) return ''
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }
  


export const getNearestRoundNumber = (number: number) => {
    if (number < 0) {
        return 0;
      }
      
      const length = Math.floor(Math.log10(number) + 1);
      
      if (length <= 2) {
        return 100;
      }
      
      const powerOfTen = Math.pow(10, length - 1);
      const remainder = number % powerOfTen;
      
      if (remainder > 0) {
        return Math.ceil(number / powerOfTen) * powerOfTen;
      }
      
      return number;
  }
  
  export const deepClone = (obj: any, visited = new WeakMap()): any => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (visited.has(obj)) {
      return visited.get(obj);
    }
  
    let clone: any;
    if (obj instanceof Array) {
      clone = [];
      visited.set(obj, clone);
      for (let i = 0; i < obj.length; i++) {
        clone[i] = deepClone(obj[i], visited);
      }
    } else {
      clone = Object.create(Object.getPrototypeOf(obj));
      visited.set(obj, clone);
      for (const key of Object.keys(obj)) {
        clone[key] = deepClone(obj[key], visited);
      }
    }
  
    return clone;
  }
  

  export const convertToRGB = (hex: string): RGBAColor  => {
    const hexCode = hex.substring(1)
    if(hexCode.length != 6){
        throw "Only six-digit hex colors are allowed.";
    }

    const aRgbHex = hexCode.match(/.{1,2}/g);
    if(aRgbHex){
      const aRgb: RGBAColor  = [
          parseInt(aRgbHex[0], 16),
          parseInt(aRgbHex[1], 16),
          parseInt(aRgbHex[2], 16)
      ];
      return aRgb;
    }
    return [0, 0, 0] as RGBAColor
}

export const rgbaStringToArray = (rgbaString: string): RGBAColor => {
  const valuesString = rgbaString.replace(/^rgba\(|\)$/g, '');
  const valuesArray = valuesString.split(', ');
  const numericValues = valuesArray.map(value => parseFloat(value));
  numericValues.pop()
  return numericValues as RGBAColor;
}

export const rgbToHex = (rgbString: string) => {
  const values = rgbString.replace(/[\srgb()a]/g, '').split(',');
  const r = parseInt(values[0]);
  const g = parseInt(values[1]);
  const b = parseInt(values[2]);
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

