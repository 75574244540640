import { T_ResponseGetCcsRiskStorages } from 'api/ccs/types';
import { ITagAttribute } from 'api/sidebar/types';
import { RootState } from 'store/store'
import { T_CcsProject, T_CcsState } from "./types";

export const selectSelectedProject = (state: RootState): T_CcsProject => state.ccsSlice.projects.byId[state.ccsSlice.projects.selectedId]
export const selectSelectedProjectId = (state: RootState): T_CcsState['projects']['selectedId'] => state.ccsSlice.projects.selectedId
export const selectWorkSpaceId = (state: RootState): T_CcsState['workSpaceId'] => state.ccsSlice.workSpaceId
export const selectCcsProjects = (state: RootState): T_CcsState['projects'] => state.ccsSlice.projects
export const selectCcsSources = (state: RootState): T_CcsState['sources'] => state.ccsSlice.sources
export const selectCcsStorages = (state: RootState): T_CcsState['storages'] => state.ccsSlice.storages
export const selectCcsTransports = (state: RootState): T_CcsState['transports'] => state.ccsSlice.transports
export const selectIsFetching = (state: RootState): T_CcsState['isFetching'] => state.ccsSlice.isFetching
export const selectDistance = (state: RootState): T_CcsState['distance'] => state.ccsSlice.distance
export const selectMapData = (state: RootState): T_CcsState['map'] => state.ccsSlice.map
export const selectRiskStorages = (state: RootState): T_CcsState['storages'] => state.ccsSlice.riskStorage.storages
export const selectRiskStoragesPlot = (state: RootState): T_ResponseGetCcsRiskStorages['riskResourcePlot']['bucket'] => state.ccsSlice.riskStorage.plot
export const selectRiskStoragesRadar = (state: RootState): T_ResponseGetCcsRiskStorages['radarData'] => state.ccsSlice.riskStorage.radarData
export const selectCcsStorageResources = (state: RootState): T_CcsState['storageResources'] => state.ccsSlice.storageResources
export const selectCcsInjectivity = (state: RootState): T_CcsState['injectivity'] => state.ccsSlice.injectivity
export const selectRiskStoragesLoading = (state: RootState): T_CcsState['riskStorage']['isLoading'] => state.ccsSlice.riskStorage.isLoading
export const selectAllTags = (state: RootState): ITagAttribute[] => state.ccsSlice.allTags
export const selectSrmsBookings = (state: RootState): T_CcsState['booking']['srmsBookings'] => state.ccsSlice.booking.srmsBookings
export const selectSrmsBookingsLoading = (state: RootState): T_CcsState['booking']['isLoading'] => state.ccsSlice.booking.isLoading
export const selectSelectedSrmsBookingProject = (state: RootState): T_CcsState['booking']['selectedProject'] => state.ccsSlice.booking.selectedProject
