import React, {useState, useEffect} from 'react'
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { ITreeElementProps } from 'helpers/types/tree';
import { sideBarTreeSlice } from 'store/sidebar/slice';
import { selectSelectedTab, workspaceSlice } from 'store/workspace/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';

export const FilterInput: React.FC<ITreeElementProps> = ({ props }) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState<string>('');
    const [validationError, setValidationError] = useState<string>('');
    const selectedTab = useAppSelector(selectSelectedTab);
    
    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        if(props.item.parent === 'plotOptions')
            dispatch(sideBarTreeSlice.actions.setPlotOptionsChanged(true));
        if (props.item.element === "plotTitle") {
          dispatch(workspaceSlice.actions.changePlotTitle({ value: e.target.value, id: selectedTab?.id }))
        }
        dispatch(workspaceSlice.actions.changeInputValue({ value: e.target.value, itemHierarchicalIndex: props.itemHierarchicalIndex }))
    }

    const handleChange = (e: InputChangeEvent) => {
        const isNumber = /^-?\d*\.?\d*$/;
        if((props.item.name === 'Label')){
            setValue(e.value);
        }else if(props.item.name === 'Plot Title'){
            if(e.value.length > 250) {
                setValidationError('Max 250 symbols');
                return
            } else {
                setValue(e.value);
                setValidationError('');
            } 
        }else if(isNumber.test(e.value) || !e.value){
            setValue(e.value);
            setValidationError('');
        }else {
            setValidationError('Only Numbers')
            return
        }
    }

    // it's because kendo treeview somehow don't allow use space key
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
    }
    useEffect(() => {
        props.item.inputValue === null ? setValue('') : setValue(props.item.inputValue + '');
    }, [props.item.inputValue])

    return (
        <div className='inputBlock' onKeyDown={handleKeyDown}>
            {
                !!props.item.name && 
                <label htmlFor={props.item.id}>{props.item.name}</label>
            }
            <Input
                id={props.item.id}
                name={props.item.id}
                type={props.item.type || 'text'}
                autoComplete='on'
                onBlur={handleBlur}
                defaultValue={props.item.inputValue ? props.item.inputValue + '' : '' }
                key={props.item.inputValue}
                onChange={handleChange}
                value={value}
            />
            {props.item.name !== 'Label' ? <span className='validationError'>{validationError}</span> : ''}
        </div>
    )
}