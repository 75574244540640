import { createAsyncThunk } from '@reduxjs/toolkit';
import { forgotPassword, resetPassword } from '../../../api/auth/service';
import { ResetPasswordInterface } from './types';

export const forgotPasswordThunk = createAsyncThunk('user/forgotPassword', async (email: string, thunkAPI) => {
  const response = forgotPassword(email, thunkAPI);
  return response;
});

export const resetPasswordThunk = createAsyncThunk('user/resetPassword', async (resetedPassword: ResetPasswordInterface, thunkAPI) => {
  const response = await resetPassword(resetedPassword, thunkAPI);
  return response;
});