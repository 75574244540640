// eslint-disable-next-line
// @ts-nocheck
import { filterBy } from '@progress/kendo-react-data-tools';
import { mapTree, extendDataItem } from '@progress/kendo-react-common';
import { datasetOptionsMapping, datasetsIds } from 'helpers/constants/commons';

export const processTreeData = (data: any, state: any, fields: any) => {
    const { selectField, expandField, dataItemKey, subItemsField } = fields;
    const { expanded, value, filter } = state;
    const filtering = Boolean(filter && filter.value);

    return mapTree(
        filtering ? filterBy(data, [filter], subItemsField) : data,
        subItemsField,
        item => {
            const props = {
                [expandField]: expanded.includes(item[dataItemKey]),
                [selectField]: value && item[dataItemKey] === value[dataItemKey]
            };
            return filtering ?
                extendDataItem(item, subItemsField, props) :
                { ...item, ...props };
        }
    );
};


export const expandedState = (item: any, dataItemKey: any, expanded: any) => {
    const nextExpanded = expanded.slice();
    const itemKey = item[dataItemKey];
    const index = expanded.indexOf(itemKey);
    index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

    return nextExpanded;
};



export function transformArrayToTreeData(inputArray) {
    const result = [];
    // Create a map to group items by 'identifierFieldType'
    const groupedMap = new Map();
    datasetsIds.forEach(item => groupedMap.set(item, []))
    inputArray.forEach((item) => {
      const { name, id, identifierFieldTypeKey } = item

      if (!groupedMap.has(identifierFieldTypeKey)) {
        groupedMap.set(identifierFieldTypeKey, [])
      }
      groupedMap.get(identifierFieldTypeKey).push({ text: name, id })
    })

    // Convert the map into the desired format
    groupedMap.forEach((items, key, map) => {
        if(datasetOptionsMapping[key] && items.length)
        result.push({
            text: datasetOptionsMapping[key],
            id: result.length,
            items,
        });
    });

    return result;
}
