import { filteredDataWindowThunk } from './thunk';
import { FooterState, IFilteredData } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: FooterState = {
  isLoading: false,
  error: null,
  filteredData: {
    globalDataCount: 0,
    productionDataCount: 0,
    userDataCount: 0,
    storageDataCount: 0,
    ccsProjectDataCount: 0,
    sourceDataCount: 0,
    transportDataCount: 0,
  }
};

export const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {},
  extraReducers: {
    [filteredDataWindowThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [filteredDataWindowThunk.fulfilled.type]: (state, action: PayloadAction<IFilteredData>) => {
      state.filteredData = action.payload
      state.isLoading = false;
      state.error = null;
    },

    [filteredDataWindowThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

  },
});

export default footerSlice.reducer;


