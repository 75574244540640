import { ICategorizeOptions, IFilterOptions, ITagAttribute, TreeViewDataItem } from 'store/sidebar/types';
import { ITab } from '../../api/workspace/types';
import { IAxisStyleSettings, IStyleSettings, IWorkspaceItem, MapOptions } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllWorkspaces, getWorkspaceById, R_addWorkspace, R_deleteWorkspace, R_editWorkspace, R_editWorkspaceFilters, saveMapOptions, savePlotOptions } from 'api/workspace/service';
import { addNotification } from 'store/notifications/slice';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosError } from 'axios';
import { ccsSlice } from 'store/ccs/slice';
import { createTabs } from '../../api/workspace/processors';
import { handleRequestErrors } from 'helpers/functions/commons';
import { getWorkSpaceFromLS } from 'helpers/functions/workspaceHelpers';
import { R_getProjectView } from 'api/projectView/projectViewService';
import { IUserActions } from 'api/userAction/types';
import { R_GetUserActions, R_SaveUserActions } from 'api/userAction/service';
import { ROUTES, USER_ACTION_ENUM, USER_ACTION_TYPES } from 'helpers/constants/app';
import { getPlotOptions } from 'api/sidebar/sideBarService';
import { R_changeAxisStyleSettings, R_changeStyleSettings, R_deleteStyleSettings, R_getAxisStyleSettings, R_getStyleSettings } from 'api/styleSettings/service';
import { mapViewSlice } from 'store/mapView/slice';
// import { AppStore } from 'store/store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (err: AxiosError, { dispatch, rejectWithValue }: BaseThunkAPI<any, any, any, any>) => {
    if (!err.response) {
        throw err
    }
    const message = handleRequestErrors(err)
    dispatch(addNotification({ type: 'error', message }))
    return rejectWithValue(message)
}

export const getAllWorkspacesThunk = createAsyncThunk('workspace/getAll', async ({ allTags }: { allTags: ITagAttribute[] }, thunkAPI) => {
    try {
        const state = thunkAPI.getState()
        // if(!(state as RootState)?.userStateSlice?.isSignedIn){
        //     thunkAPI.dispatch(userStateSlice.actions.setUserStatus('pending'));
        // }
        // await thunkAPI.dispatch(T_getUserInfo());
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: IWorkspaceItem[] = await getAllWorkspaces(thunkAPI);
        const userActions = await R_GetUserActions()
        const lastActiveWorkspaceId = userActions?.workspaceId || getWorkSpaceFromLS()
        const lastActiveWorkspace = response?.find((workspace: IWorkspaceItem) => workspace.id === lastActiveWorkspaceId)
        const workspaceId = lastActiveWorkspace ? lastActiveWorkspace.id : response[0].id
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(state as any).workspaceSlice.workspaceId || ((state as any).workspaceSlice.workspaceId !== workspaceId)) {
            await thunkAPI.dispatch(getWorkspaceByIdThunk({ id: workspaceId, allTags }));
            thunkAPI.dispatch(ccsSlice.actions.setCcsCurrentWorkSpaceId(workspaceId));
        }
        await thunkAPI.dispatch(T_GetUserActions(userActions));
        // thunkAPI.dispatch(userStateSlice.actions.setUserStatus(true));
        return response;
    } catch (e) {
        return handleError(e as AxiosError, thunkAPI);
    }
});

export const T_GetUserActions = createAsyncThunk('workspace/getUserActions', async (userActions: IUserActions) => {
    const { actionName, entityId, action, workspaceId } = userActions
    return { actionName, entityId, action, workspaceId }
});

export const T_SaveUserActions = createAsyncThunk('workspace/saveUserActions', async (data: { selectedTabId?: string, isMapViewSelected: boolean, workspaceId: string, selectedProjectId?: string }) => {
    const path = window.location.pathname
    const pathEntries = Object.entries(ROUTES)
    const isCCs = path.includes('projects/ccs') && data?.selectedProjectId
    const pathToSave = pathEntries.find(([, value]) => path.includes(value))
    let actionName = pathToSave ? USER_ACTION_TYPES[pathToSave[0] as keyof typeof USER_ACTION_TYPES] : null
    actionName = actionName === USER_ACTION_TYPES.explore && data.isMapViewSelected ? USER_ACTION_TYPES.exploreMap : actionName || '';
    const UserActionsData: Partial<IUserActions> = {
        workspaceId: data.workspaceId,
        action: actionName ? USER_ACTION_ENUM.indexOf(actionName) : 0,
        entityId: isCCs ? data?.selectedProjectId : data.isMapViewSelected ? undefined : data?.selectedTabId
    }
    UserActionsData.workspaceId && actionName && R_SaveUserActions(UserActionsData)

    return UserActionsData
});

export const T_getProjectView = createAsyncThunk('plot/getprojectView', async (data: { workspaceId: string, plotId: string, newId?: string }, thunkAPI,) => {
    const response = await R_getProjectView(data, thunkAPI)
    return { ...response, newId: data.newId }
});

export const getWorkspaceByIdThunk = createAsyncThunk('workspace/getById', async (data: { id: string, tabId?: string, selectLast?: boolean, allTags: ITagAttribute[] }, thunkAPI) => {
    try {
        const { id, tabId, selectLast, allTags } = data
        thunkAPI.dispatch(mapViewSlice.actions.resetIsMapViewFullDataFetched(false))
        const { workspace } = await getWorkspaceById(id);
        const { tabs }: { tabs: ITab[] } = createTabs(workspace, tabId, selectLast);
        return { workspace, tabs, allTags, tabId };
    } catch (e) {
        return handleError(e as AxiosError, thunkAPI);
    }
});

export const savePlotOptionsThunk = createAsyncThunk('workspace/savePlotOptions', async (data: { plotOptions: TreeViewDataItem | undefined, selectedTab: ITab | undefined }, thunkAPI) => {
    const response = await savePlotOptions(data, thunkAPI);
    return response;
});

export const getPlotOptionsThunk = createAsyncThunk('workspace/getPlotOptions', async (data: { plotId: string, workspaceId: string }, thunkAPI) => {
    const response = await getPlotOptions(data, thunkAPI);
    return response;
});

export const T_addWorkspace = createAsyncThunk('workspace/add', async (data: { title: string, unit: number, datasetIds: string[], sharedWith: string[] }, thunkAPI) => {
    try {
        const response = await R_addWorkspace(data)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})

export const T_editWorkspace = createAsyncThunk('workspace/edit', async (data: Partial<IWorkspaceItem>, thunkAPI) => {
    try {
        const response = await R_editWorkspace(data)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})

export const T_editWorkspaceFilters = createAsyncThunk('workspace/editFilters', async (data: { id: string, title?: string, unit: number | null, plotId?: string, mapOptions?: TreeViewDataItem, filterOptions?: IFilterOptions, categorizeOptions?: ICategorizeOptions }, thunkAPI) => {
    try {
        const response = await R_editWorkspaceFilters(data);
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})


export const T_deleteWorkspace = createAsyncThunk('workspace/delete', async (data: { id: string }, thunkAPI) => {
    try {
        const response = await R_deleteWorkspace(data)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})

export const saveMapOptionsThunk = createAsyncThunk('mapOptons/saveMapOptions', async (data: { id: string, mapOptions: MapOptions }, thunkAPI) => {
    try {
        const response = await saveMapOptions(data)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})

export const T_getStyleSettings = createAsyncThunk('get/styleSettings', async (data: { workspaceId: string, optionType: number, identifierFieldId?: string, plotType?: number }, thunkAPI) => {
    try {
        const response = await R_getStyleSettings(data, thunkAPI)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})
export const T_changeStyleSettings = createAsyncThunk('change/styleSettings', async (data: { workspaceId: string, styleSettings: any, optionType: number, identifierFieldId?: string }, thunkAPI) => {
    try {
        const response = await R_changeStyleSettings(data, thunkAPI)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})

export const T_getAxisStyleSettings = createAsyncThunk('get/axisStyleSettings', async (data: { workspaceId: string, optionType: number, plotType?: number, type: string }, thunkAPI) => {
    try {
        const response = await R_getAxisStyleSettings(data, thunkAPI)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})



export const T_changeAxisStyleSettings = createAsyncThunk('change/styleAxisSettings', async (data: { workspaceId: string, styleSettings: Partial<IAxisStyleSettings>, optionType: number }, thunkAPI) => {
    try {
        const response = await R_changeAxisStyleSettings(data, thunkAPI)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})

export const T_deleteStyleSettings = createAsyncThunk('delete/styleSettings', async (data: { workspaceId: string, optionId: string }, thunkAPI) => {
    try {
        const response = await R_deleteStyleSettings(data, thunkAPI)
        return response
    } catch (error) {
        return handleError(error as AxiosError, thunkAPI);
    }
})
