import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { notificationInterface } from './types';

const initialState: notificationInterface = {
	notifications: [],
}

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		addNotification: (state, action) => {
			const notif = {
				id: uuidv4(),
				type: action.payload.type,
				message: action.payload.message,
			};
			state.notifications.push(notif);
		},
		removeNotification: (state, action) => {
			state.notifications = state.notifications.filter((notif) => notif.id !== action.payload.id);
		},
	},
});

export const { addNotification, removeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;