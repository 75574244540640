import React from "react"
import { Button } from "@progress/kendo-react-buttons"
import { ITreeElementProps } from "helpers/types/tree"
import { useAppSelector } from "hooks/redux"
// import { getQuickPlotsThunk, saveQuickPlotThunk } from "store/workspace/thunk"

export const FilterButton: React.FC<ITreeElementProps> = ({ props }) => {
  // const dispatch = useAppDispatch()

  const isEnabledFilters = useAppSelector((store) => store.workspaceSlice.filters.filterOptions.isEnabled)
  // const {tree, plotOptionTreeview} =  useAppSelector(state => state.workspaceSlice);
  // const plotOptions = tree.length ? tree.find((node: TreeViewDataItem) => node?.name === 'Plot Options') : plotOptionTreeview;


  const handleButtonClick = async () => {
    switch (props.item.action) {
      default:
        break
    }
  }

  const enabled = isEnabledFilters
  const buttonName = props.item.action !== "isEnabled" ? props.item.name : enabled ? "Enable filters" : "Disable filters"

  return (
    <Button
      className={props.item.noData ? `${props.item.type} noData` : props.item.type}
      themeColor={props.item.action !== "isEnabled" ? "primary" : enabled ? "primary" : "inverse"}
      onClick={handleButtonClick}
    >
      {buttonName}
    </Button>
  )
}
