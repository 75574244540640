import { ITab, ISaveModel } from './types';
import { IWorkspace, IWorkspaceItem, MapOptions } from '../../store/workspace/types';
import { Dispatch } from 'react';
import { api } from '../root';
import { createSaveModel } from './processors';
import { TreeViewDataItem, ICategorizeOptions, IFilterOptions } from 'store/sidebar/types';
import { handleRequestErrors } from 'helpers/functions/commons';
import { createRequestModel } from 'api/mapView/processors';
import { addNotification } from 'store/notifications/slice';



export const getAllWorkspaces = async (thunkAPI: unknown) => {
    try {
        const response = await api.get<{workspaces: IWorkspaceItem[]}>('/Workspace/GetAll');
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
} 

export const R_addWorkspace = async (data: { title: string, unit: number, datasetIds: string[], sharedWith: string[] }) => {
    const response = await api.post('/Workspace', data);
    return response.data;
}

export const R_editWorkspace = async (data: Partial<IWorkspaceItem>) => {
    const response = await api.patch(`/Workspace/${data.id}`, data)
    return response.data
}

export const R_editWorkspaceFilters = async (data: {id: string, title?: string, unit: number | null, mapOptions?: TreeViewDataItem, filterOptions?: IFilterOptions, categorizeOptions?: ICategorizeOptions}) => {
    const req = createRequestModel({mapOptions: data.mapOptions, filterOptions: data.filterOptions, categorizeOptions: data.categorizeOptions})
    req.title = data.title
    req.unit = data.unit
    const response = await api.patch(`/Workspace/${data.id}`, {...req})
    return response.data
}

export const R_deleteWorkspace = async (data: {id: string}) => {
    const response = await api.delete(`/Workspace/${data.id}`)
    return response.data
}

export const getWorkspaceById = async (id: string) => {
    const response = await api.get<IWorkspace>(`/Workspace/${id}`);
    return {workspace: response.data};
}

export const savePlotOptions = async (data: {plotOptions: TreeViewDataItem | undefined, selectedTab: ITab | undefined}, thunkAPI: unknown) => {
    try {
        const saveData: ISaveModel = createSaveModel(data);
        const response = await api.put<IWorkspace>(`/Workspace/${data.selectedTab?.workspaceId}/CrossPlot/${data.selectedTab?.id}`, saveData);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        // thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const saveMapOptions = async (data: {id: string, mapOptions: MapOptions}) => {
    const response = await api.patch(`/Workspace/${data.id}`, { mapViewOptions: data.mapOptions })
    return response.data
}
