import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDataset } from "./types";
import { R_addEmptyDataset, R_addNewDataset, R_deleteDataset, R_editDatasetValues, R_getDataset, R_getDatasetsList, R_importDataset, R_shareDataset, R_unshareDataset } from "api/dataSet/datasetService";
import { CancelTokenSource } from "axios";

export const T_addDataset = createAsyncThunk('userData/add', async (data: FormData, thunkAPI) => {
    const response = await R_addNewDataset(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});

export const T_addEmptyDataset = createAsyncThunk('userData/addEmpty', async (data: FormData, thunkAPI) => {
    const response = await R_addEmptyDataset(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});

export const T_importDataset = createAsyncThunk('userData/add', async (data: {id: string, file: FormData}, thunkAPI) => {
    const response = await R_importDataset(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});

export const T_getDatasetsList = createAsyncThunk('userData/getall', async (thunkAPI) => {
    const response = await R_getDatasetsList(thunkAPI)
    return response
});


export const T_getDataset = createAsyncThunk('userData/getData', async (data: IDataset, thunkAPI) => {
    const response = await R_getDataset(data, thunkAPI)
    return response
});

export const T_editDatasetValues = createAsyncThunk('userData/editData', async (data: {id: IDataset['id'], title: IDataset['title'], description: IDataset['description'], colorDisplayOption: number}, thunkAPI) => {
    const response = await R_editDatasetValues(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});

export const T_deleteDataset = createAsyncThunk('userData/deleteData', async (data: {id: IDataset['id'], title: IDataset['title'], description: IDataset['description']}, thunkAPI) => {
    const response = await R_deleteDataset(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});


export const T_shareDataset = createAsyncThunk('userData/shareData', async (data: {id: string, sharedWith: string[], source?: CancelTokenSource }, thunkAPI) => {
    const response = await R_shareDataset(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});

export const T_unshareDataset = createAsyncThunk('userData/unshareData', async (data: {id: IDataset['id'], title: IDataset['title'], description: IDataset['description']}, thunkAPI) => {
    const response = await R_unshareDataset(data, thunkAPI)
    await thunkAPI.dispatch(T_getDatasetsList())
    return response
});