import { DropDownList, DropDownListChangeEvent, DropDownListProps } from '@progress/kendo-react-dropdowns';
import { ITab } from 'api/workspace/types';
import { saveWorkSpaceToLS } from 'helpers/functions/workspaceHelpers';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useEffect, useState } from 'react';
import { ccsSlice } from 'store/ccs/slice';
import { mapViewSlice } from 'store/mapView/slice';
import { selectAllTagAttributes } from 'store/sidebar/selectors';
import { workspaceSlice } from 'store/workspace/slice';
import { getWorkspaceByIdThunk } from 'store/workspace/thunk';
import { IWorkspaceItem } from 'store/workspace/types';
import Popup from './popup';
import './style.scss'
import { homeSlice } from 'store/home/slice';
import AddNewWorkspacePopup from 'components/UserData/popups/addNewWorkspacePopup/addNewWorkspacePopup';
import WithRole from 'components/userRoles/withRole/WithRole';
import { LICENSE_TYPES } from 'helpers/constants/app';

interface IProps {
    width: string;
    workspaces: IWorkspaceItem[];
}
type IPopupDataType = 'new' | 'edit' | 'delete';

type IPopupType = {
    isOpen: boolean,
    id: string,
    name: string,
    type: IPopupDataType
};

export default function ProjectListDropDown({ width, workspaces }: IProps) {
    const dispatch = useAppDispatch()
    const { workspaceListLoading, isWorkspaceListFetched, workspaceId, } = useAppSelector(store => store.workspaceSlice)
    const { addNewWorkspacePopup } = useAppSelector(state => state.homeSlice);

    const allTags = useAppSelector(selectAllTagAttributes)
    const [popup, setPopup] = useState<IPopupType>({
        isOpen: false,
        id: '',
        name: '',
        type: 'new'
    })

    useEffect(() => {
        if (!workspaces.length && isWorkspaceListFetched) {
            dispatch(homeSlice.actions.setAddNewWorkspacePopup({isOpen: true}))
        }
    }, [workspaces, isWorkspaceListFetched])

    const handleWorkSpaceChange = async (e: DropDownListChangeEvent) => {
        saveWorkSpaceToLS(e.value.id)
        const workspaceId: string = e.value.id
        const res = await dispatch(getWorkspaceByIdThunk({ id: workspaceId, allTags }));
        dispatch(mapViewSlice.actions.changeIsFetchedStatus(false))
        dispatch(ccsSlice.actions.setCcsCurrentWorkSpaceId(workspaceId));
        dispatch(workspaceSlice.actions.setSelectedTab((res?.payload as { tabs: ITab[] })?.tabs?.[0]));
        // // todo: may be we want this, so in future we can remove this, if it will not give problems
        // // const permVsPoroTab = tabs.payload.tabs.find((tab: ITab) => tab.title.toLowerCase().includes('perm') && tab.title.toLowerCase().includes('poro'));
    }


    const closePortal = () => {
        setPopup({
            isOpen: false,
            id: '',
            name: '',
            type: 'new'
        })
    }

    const DropdownItemRender: DropDownListProps['itemRender'] = (li) => {
        const itemChildren = (
            <div className='ccs-dropdown-item'>
                <>{li.props.children}</>
            </div>
        )

        return React.cloneElement(li, li.props, itemChildren)
    }

    const value = workspaces.find((i: IWorkspaceItem) => i.id === workspaceId)
    return <div className='workspace-list-cont' >
        <DropDownList loading={workspaceListLoading || !isWorkspaceListFetched} style={{ width: width, marginRight: '10px' }} data={workspaces} textField='title' value={value} itemRender={DropdownItemRender} onChange={handleWorkSpaceChange} />
        <WithRole roles={[LICENSE_TYPES.free]} condition={workspaces.length < 1}>
            <button title='Create a New Workspace' className='plus-btn' data-type={'new'} onClick={() => dispatch(homeSlice.actions.setAddNewWorkspacePopup({isOpen: true}))} />
        </WithRole>
        <AddNewWorkspacePopup portalOpened={addNewWorkspacePopup} closePortal={() => dispatch(homeSlice.actions.setAddNewWorkspacePopup({isOpen: false}))} />
        <Popup
            {...popup}
            workspaceNames={workspaces?.map(item => item.title.toLowerCase())}
            closePortal={closePortal}
        />
    </div>
}