import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { forgotPasswordThunk, resetPasswordThunk } from './thunk';

interface ForgotPasswordState {
    isEmailVerified: boolean,
    email: string,
    isLoading: boolean,
    error: string | null;
    changePasswordError: string | null,
};

const initialState: ForgotPasswordState = {
    isEmailVerified: false,
    isLoading: false,
    email: '',
    error: null,
    changePasswordError: null,
};

export const forgotPasswordSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        handleEmailChange: (state, action) => {
            state.email = action.payload
        }
    },
    extraReducers: {
        [forgotPasswordThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [forgotPasswordThunk.fulfilled.type]: (state) => {
            state.isEmailVerified = true;
            state.isLoading = false;
        },

        [forgotPasswordThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        [resetPasswordThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [resetPasswordThunk.fulfilled.type]: (state) => {
            state.isEmailVerified = true;
            state.isLoading = false;
            state.changePasswordError = null;
        },

        [resetPasswordThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.changePasswordError = action.payload;
        },
    },
});

export const { handleEmailChange } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;

