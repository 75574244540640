import { IOptions } from "./../../store/sidebar/types"
import { ICategorizeOptions, TreeViewDataItem, IFilterOptions } from "store/sidebar/types"

export const createRequestModel = (data: { mapOptions?: TreeViewDataItem; filterOptions?: IFilterOptions; categorizeOptions?: ICategorizeOptions }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const model: any = { tooltips: [] }
  if (data.categorizeOptions) {
    model.categorizeOptions = data.categorizeOptions
  }
  if (data.filterOptions) {
    model.filterOptions = data.filterOptions
  }
  
  data?.mapOptions?.items?.forEach((e: TreeViewDataItem) => {
    if (e.element === "mapType") {
      model.mapViewOptions.mapType = e.options?.findIndex((opt: IOptions) => opt.id === e.selected?.id)
    }

    if (e.category && e.element && model.mapViewOptions[e.category] && e.type === "checkbox") {
      model.mapViewOptions[e.category][e.element] = e.checked
    }

    if (e.element === "tooltip" && e.selected?.type) {
      model?.mapViewOptions?.tooltips?.push(e.selected.id)
    }
  })

  return model
}
