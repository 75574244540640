import React, { useState, useRef, FormEvent } from 'react'
import { Checkbox, Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Portal } from 'components/Portal/Portal';
import { Loader } from '@progress/kendo-react-indicators';
import { getUsersThunk } from 'store/home/thunk';
import { selectAllTagAttributes } from 'store/sidebar/selectors';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { T_addWorkspace, T_editWorkspace, getAllWorkspacesThunk } from 'store/workspace/thunk';
import { DropDownList, DropDownListChangeEvent, ListItemProps, MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import AvatarsGroup from 'components/avatarsGroup/avatarsGroup';
import './addNewWorkspacePopup.scss';
import { IWorkspaceItem } from 'store/workspace/types';
import { Dataset, HomeState } from 'store/home/types';
import { filterBy } from "@progress/kendo-data-query";
import { trackGAEvent } from 'helpers/functions/GoogleAnalytics/GA';
import { eventsGA } from 'helpers/functions/GoogleAnalytics/types';
import WithRole from 'components/userRoles/withRole/WithRole';
import { LICENSE_TYPES } from 'helpers/constants/app';

type IUnitOption = { name: string, id: number, disabled?: boolean };




type Props = {
    portalOpened: HomeState['addNewWorkspacePopup']
    closePortal: () => void
    saveChanges?: (values: unknown) => void
}

export default function AddNewWorkspacePopup({ portalOpened, closePortal }: Props) {
    const unitOptions: IUnitOption[] = [{ name: 'Field', id: 1 }, { name: 'Metric', id: 2 }];
    const dispatch = useAppDispatch()
    const tagAttributes = useAppSelector(selectAllTagAttributes);
    const workspaces = useAppSelector(store=>store.workspaceSlice.workspaces)
    const { users, datasets: allDatasets, isUsersFetched } = useAppSelector(state => state.homeSlice);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [title, setTitle] = useState('');
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [error, setError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [unitError, setUnitError] = useState('');
    const [process, setProcess] = useState<boolean>(false);
    const [unitValue, setUnitValue] = useState<IUnitOption>();
    const [datasetIds, setDatasetIds] = useState<Dataset[]>([]);
    const [unitOpen, setUnitOpen] = useState(false);
    const [editMode, setEditMode] = useState(false)
    const [datasetsFilter, setDatasetsFilter] = useState('')
    const datasets = allDatasets.filter(item=>item.identifierFieldUnit === unitValue?.id)
    
    const isDisabledSelected = useRef(false)

    const [filteredDatasets, setFilteredDatasets] = useState<Dataset[]>([]);
    

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!title)
            return setTitleError('Name is required');
        if (!unitValue)
            return setUnitError('Units is required');


        setProcess(true)
        setTitleError('')
        setUnitError('')
        if (editMode && portalOpened?.workspace) {
            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await dispatch(T_editWorkspace({ id: portalOpened?.workspace.id, title, unit: +unitValue?.id, datasetIds: datasetIds.map((dataset: { id: any; }) => dataset.id) }))
            if (res.payload.status === 423) {
                setProcess(false);
                closePortal();
            }
            if (res?.error) {
                setProcess(false)
                return setError(res?.payload);
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await dispatch(T_addWorkspace({ title, unit: +unitValue?.id, datasetIds: datasetIds.map((dataset: { id: any; }) => dataset.id), sharedWith: selectedUsers }))
            if (res?.error) {
                setProcess(false)
                return setError(res?.payload);
            }
        }
        trackGAEvent(eventsGA.new_workspace)
        trackGAEvent(eventsGA.unit_choice, { unit: unitValue.name })
        await dispatch(getAllWorkspacesThunk({ allTags: tagAttributes }));

        setProcess(false);
        closePortal();
    };


    const handleNameChange = (e: InputChangeEvent) => {
        setTitle(e.value)
        if(workspaces.some(item=>item.title === e.value)){
            return setTitleError(`A workspace with name "${e.value}" already exist.`);
        }
        else{
            setTitleError('');
        }
    }

    const itemRender = (
        element: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {
        if (!itemProps.dataItem || !itemProps.dataItem.disabled) {
            return element;
        }
        const children = [
            <span
                key={itemProps.dataItem.id}
                style={{
                    pointerEvents: 'none',
                    color: '#ccc',
                }}
            >
                {itemProps.dataItem.name}
            </span>,
        ];

        return React.cloneElement(
            element,
            {
                ...element.props,
                style: {
                    ...element.props.style,
                    cursor: 'default',
                    pointerEvents: itemProps.dataItem.disabled ? 'none' : 'unset',
                },
            },
            children,
        );

    };

    const onDataSetChange = (event: MultiSelectChangeEvent) => {
        setDatasetIds(event.value);
    }

    const onUnitChange = (event: DropDownListChangeEvent) => {
        const { value } = event.target;

        if (value.disabled) {
            isDisabledSelected.current = true;
            setTimeout(() => {
                isDisabledSelected.current = false;
            })
            return;
        }
        setUnitValue(value);
        setDatasetIds([])
        setFilteredDatasets(allDatasets.filter(item=>item.identifierFieldUnit === value?.id))
        setDatasetsFilter('')
        
    };

    const handleOpen = () => {
        setUnitOpen(true)
    }

    const handleClose = () => {
        if (isDisabledSelected.current) {
            return
        }

        setUnitOpen(false)
    }
    React.useEffect(() => {
        if (portalOpened?.workspace) {
            setEditMode(true)
            setTitle(portalOpened?.workspace?.title);
            setDatasetIds(allDatasets.filter(item => portalOpened?.workspace?.datasetIds?.includes(item.id)));
            setUnitValue(unitOptions.find(item => item.id === portalOpened?.workspace?.unit));
            const filteredDatasets = allDatasets.filter(item=>item.identifierFieldUnit === portalOpened?.workspace?.unit)
            setFilteredDatasets(filteredDatasets)
        } else {
            setEditMode(false)
            setDatasetIds([]);
            setUnitValue(unitOptions[0]);
            const filteredDatasets = allDatasets.filter(item=>item.identifierFieldUnit === unitOptions?.[0]?.id)
            setFilteredDatasets(filteredDatasets)
        }

        return () => {
            setTitleError('');
            setUnitError('');
            setSelectedUsers([]);
            setDatasetIds([]);
            setTitle('');
        }
    }, [portalOpened])

    React.useEffect(() => {
        if (!isUsersFetched) {
            dispatch(getUsersThunk());
        }
    }, [isUsersFetched])



    
    const onDatasetsFilter = (e: MultiSelectFilterChangeEvent) => {
        setFilteredDatasets(filterBy(datasets.slice(), e.filter))
        setDatasetsFilter(e.filter.value)
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const MultiSelectWithCheckboxItem = (li: any, itemProps: ListItemProps) => {
        const itemChildren = (
            <span className='MultiSelectWithCheckboxItem'>
                <Checkbox
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                />
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    return (
        <Portal
            opened={!!portalOpened.isOpen}
            closePortal={closePortal}
        >
            <div className='portal'>
                <div className='portal-header home'>
                    <h4 className='title'>{portalOpened?.workspace ? 'Edit' : 'Add'} Workspace</h4>
                    <button className='close-btn' onClick={closePortal} />
                </div>
                <div className='portal-custom-content'>
                    <form onSubmit={handleSubmit} className={"k-form form-content"}>
                        <div className='title newWorkspaceField'>
                            <span className='label'>Name</span>
                            <div className={!titleError ? '' : 'error-border'}>
                                <Input name={"Title"} placeholder='Name' value={title} onChange={handleNameChange} />
                            </div>
                            {!!titleError && <span className='error'>{titleError}</span>}
                        </div>
                        <div className='dataset newWorkspaceField'>
                            <span className='label'>Add User Data</span>
                            <MultiSelect
                                style={{ width: "100%" }}
                                placeholder={'Select datasets'}
                                itemRender={MultiSelectWithCheckboxItem}
                                filterable
                                autoClose={false}
                                name="Dataset"
                                textField='title'
                                dataItemKey="id"
                                data={(filteredDatasets)}
                                value={datasetIds}
                                onChange={onDataSetChange}
                                filter={datasetsFilter}
                                onFilterChange={onDatasetsFilter}
                            />
                        </div>
                        <div className='units newWorkspaceField'>
                            <span className='label'>Unit Type</span>
                            <DropDownList
                                style={{ width: "100%" }}
                                name="Units"
                                textField='name'
                                value={unitValue}
                                itemRender={itemRender}
                                data={unitOptions}
                                opened={unitOpen}
                                onChange={onUnitChange}
                                onOpen={handleOpen}
                                onClose={handleClose}
                            />
                            {!!unitError && <span className='error'>{unitError}</span>}
                        </div>
                        {portalOpened?.workspace &&
                            <WithRole roles={[LICENSE_TYPES.global]}>
                                <div className='shareWith newWorkspaceField'>
                                    <span className='label'>Share with</span>
                                    <AvatarsGroup showDropdown={true} avatarsToShow={3} list={users} setSelectedUsers={setSelectedUsers} />
                                </div>
                            </WithRole>}
                        <div className='portal-footer'>
                            {process && <Loader size="small" type={"infinite-spinner"} />}
                            <button
                                disabled={process}
                                className='cancel'
                                type="button"
                                onClick={closePortal}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={process || !!titleError || !!unitError}
                                className='save'
                                type={"submit"}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Portal>
    )
}