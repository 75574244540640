import { IStyleSettings } from 'store/workspace/types';

export const MAPPINGS = {
  shape: [{ id: "Circle", url: `${window.location.origin}/circle.png`, plotValue: 'circle' }, { id: "Square", url: `${window.location.origin}/square.png`, plotValue: 'square' }, { id: "Triangle", url: `${window.location.origin}/triangle.png`, plotValue: 'triangle-up' }],
  shapeProduction: [{ name: "Point", value: 2}, { name: "Line", value: 1 }],
  sizes: ['x1', 'x2', 'x3', 'x4', 'x5'],
  markerSizes: [1, 3, 7, 14, 21],
  fontSizes: {12: {name: 'Small', value: '12px'}, 14: {name: 'Small', value: '14px'}, 16: {name: 'Medium', value: '16px'}, 18: {name: 'Medium', value: '18px'}, 20: {name: 'Large', value: '20px'}},
  positions: [{label: 'Center', value: 'center', plotValue: 'center'}, {label: 'Above', value: 'top', plotValue: 'top'}, {label: 'Below', value: 'bottom', plotValue: 'bottom'}, {label: 'Right', value: 'start', plotValue: 'right'}, {label: 'Left', value: 'end', plotValue: 'left'}]
}

export const processSettings = (data: any): any => {
  return {
    color: data?.markerOption?.color,
    font: {name: data?.labelOption?.font, family: data?.labelOption?.font},
    shape: MAPPINGS.shape[data?.markerOption?.shapeType - 1],
    sizeFont: MAPPINGS.fontSizes[data?.labelOption?.fontSizeType as keyof typeof MAPPINGS.fontSizes],
    sizeShape: MAPPINGS.sizes[data?.markerOption?.markerSizeType - 1] as IStyleSettings['sizeShape'],
    position: MAPPINGS.positions[data?.labelOption?.positionType - 1],
    border: data?.markerOption?.hasBorder,
    optionId: data.id
  }
}

export const processAxisSettings = (data: any, type: string): any => {
  return {
    [type]: {
      font: {name: data?.labelOption?.font, family: data?.labelOption?.font},
      sizeFont: MAPPINGS.fontSizes[data?.labelOption?.fontSizeType as keyof typeof MAPPINGS.fontSizes],
    },
  }
}

export const processSettingsToBody = (data: any): any => {
  return {
    markerOption: {
      color: data?.color,
      shapeType: MAPPINGS?.shape?.findIndex(item=>item.id === data?.shape?.id) + 1,
      productionMarkerType: typeof data?.shape === 'number' ? data?.shape : undefined,
      lineWidth: MAPPINGS?.sizes?.indexOf(data?.sizeShape) + 1,
      markerSizeType: MAPPINGS?.sizes?.indexOf(data?.sizeShape) + 1,
      hasBorder: data?.border
    },
    labelOption: {
      font: data?.font?.name,
      fontSizeType: parseInt(data?.sizeFont?.value) || undefined,
      positionType: MAPPINGS?.positions?.findIndex(item=>item.label === data?.position?.label) + 1
    }
  }
}

