import { filterBy } from "@progress/kendo-data-query";
import { ListItemProps, MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import { Checkbox } from '@progress/kendo-react-inputs'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { useState, useRef, Dispatch, SetStateAction, useMemo } from 'react'
import './avatarsGroup.scss'
import usePrevious from "hooks/usePrevious";
import { LICENSE_TYPES } from "helpers/constants/app";
import { useAppSelector } from "hooks/redux";
import { selectActiveUser } from "store/userState/selectors";

interface Props {
    list: { name: string, img?: string, id: string }[] | null,
    avatarsToShow?: number,
    defaultValues?: { name: string, img?: string, id: string }[] | null,
    showDropdown?: boolean,
    setSelectedUsers?: Dispatch<SetStateAction<string[]>>;
    onChange?: (selectedUsers: string[]) => void
}

export default function AvatarsGroup({ list = [], avatarsToShow = 2, defaultValues = [], showDropdown = false, setSelectedUsers, onChange }: Props) {

    const [openDropDown, setOpenDropdown] = useState(false)
    const [users, setUsers] = useState<Props['list']>(defaultValues)
    const [options, setOptions] = useState(list)
    const user = useAppSelector(selectActiveUser)
    const dropdownRef = useRef(null)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const prevUsers = usePrevious(users)
    React.useEffect(() => {
        if (defaultValues?.length) {
            setUsers(defaultValues)
        }
    }, [defaultValues])

    React.useEffect(() => {
        setOptions(list)
    }, [list])

    const handleUsersSelect = (e: MultiSelectChangeEvent) => {
        if (onChange) {
            if (confirm('Are you sure you want to unshare this item?')) {
                onChange(e.value.map((item: { id: string }) => item.id));
            } else {
                setUsers(users);
                setOpenDropdown(true);
            }
            return;
        }
        setUsers(e.value);
        setOpenDropdown(true);
    }

    const handleOpenDropDown = () => {
        setOpenDropdown(true)
    }

    const filterChange = (event: MultiSelectFilterChangeEvent) => {
        if (list) {
            setOptions(filterBy(list.slice(), event.filter));
        }
    };

    useMemo(() => {
        if (setSelectedUsers && users) {
            const selectedUsers: string[] = users?.map(user => user.id)
            setSelectedUsers(selectedUsers)
        }
    }, [users]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClickOutside = (e: any) => {
        const menu = document.querySelectorAll('.k-animation-container.k-animation-container-relative.k-animation-container-shown')
        if (menu.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (Array.from(menu).some((ref: any) => ref.contains(e.target))) {
                return
            }
            setOpenDropdown(false)
        }

    }

    useOnClickOutside(dropdownRef, onClickOutside)

    const itemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
    ) => {

        const itemChildren =
            <div className='avatarsGroup'>
                <Checkbox
                    type="checkbox"
                    value={itemProps.selected}
                />
                <button type="button" key={itemProps.dataItem.id} title={itemProps.dataItem.name} className='avatar' style={{ margin: '0px 10px' }} >
                    <span>{itemProps.dataItem.name[0]}</span>
                </button>
                <span >{itemProps.dataItem.name}</span>
            </div>

        return React.cloneElement(li, li.props, itemChildren);

    };

    const restAvatarsCount = users ? Math.max(users.length - avatarsToShow, 0) : 0

    return (
        <div className='avatarsGroup' style={{zIndex: user.licenseType === LICENSE_TYPES.free ? 0 : 2}}>
            {!showDropdown &&
                <>
                    {Array.isArray(users) && users.slice(0, avatarsToShow).map(item => (
                        <button type="button" key={item.id} title={item.name} className='avatar' >
                            <span>{item.name[0]}</span>
                        </button>
                    ))}
                    <button type="button" onClick={handleOpenDropDown} title={`${users && users.length}`} className='avatar rest' >
                        <span>{`+${restAvatarsCount}`}</span>
                    </button>
                </>}
            {Array.isArray(options) && Array.isArray(users) && <div className={!showDropdown ? 'dropdown-hide' : 'dropdown-show'} ref={dropdownRef}>
                <MultiSelect
                    data={options}
                    onChange={handleUsersSelect}
                    value={users}
                    textField="name"
                    dataItemKey="id"
                    itemRender={itemRender}
                    opened={openDropDown}
                    onOpen={handleOpenDropDown}
                    filterable={true}
                    autoClose={false}
                    onFilterChange={filterChange}
                    style={{ width: "100%" }}
                    placeholder="Select users"
                />
            </div>}
        </div>
    )
}