import './DropDown.scss';
import React from "react";
import { DropDownList, DropDownListChangeEvent, DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IDropDownData } from 'store/sidebar/types';
import { deleteQuickPlotThunk, setQuickPlotThunk } from 'store/sidebar/thunk';
import { selectSelectedTab, workspaceSlice } from 'store/workspace/slice';
import { IElementType } from './types';
import { trackGAEvent } from 'helpers/functions/GoogleAnalytics/GA';
import { eventsGA } from 'helpers/functions/GoogleAnalytics/types';

export interface IDropDownProps {
    label?: string,
    items: IDropDownData[],
    element: IElementType,
    group: string | null,
    value: string | number | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null) => any;
}

export const DropDown = ({ label, items, element, group, value, setFunction, saveFunction }: IDropDownProps) => {
    const dispatch = useAppDispatch();
    const [data, setData] = React.useState(items.slice());
    const { plotId, workspaceId } = useAppSelector(state => state.workspaceSlice);
    const selectedTab = useAppSelector(selectSelectedTab)
    const [changed, setChanged] = React.useState<boolean>(false);


    React.useEffect(() => {
        setData(items.slice());
    }, [items]);

    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const data = items.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setData(filterData(event.filter));
    };

    const removePlot = async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, plotName: string) => {
        event.preventDefault();
        event.stopPropagation();
        const plotId = items.find((option: IDropDownData) => option.name === plotName)?.id;
        if (plotId)
            await dispatch(deleteQuickPlotThunk(plotId));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const itemRender = (li: any) => {
        const deleteElement = (
            <span onClick={(e) => removePlot(e, li.props.children[0].props.children)} className="k-icon k-i-delete" style={{ position: "absolute", right: "0.2rem", marginTop: ".2rem" }} />
        );
        const icon = element === "quickPlotId" ? deleteElement : "";
        const itemChildren = (
            <div>
                <span>{li.props.children}</span>
                {icon}
            </div>
        );

        return React.cloneElement(li, li.props, itemChildren);
    }

    const handleChange = async (e: DropDownListChangeEvent) => {
        if (element === 'quickPlotId') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { payload } : any = await dispatch(setQuickPlotThunk({
                workspaceId,
                plotId,
                quickPlotId: e.value.id,
                field: selectedTab?.type === 2 ? 'histogramOptions' : 'plotOptions'
            }))

            dispatch(workspaceSlice.actions.changePlotTitle({ id: plotId, value: payload?.data?.title}))
            return
        }
            if(element === 'xTagAttributeId'){
                trackGAEvent(eventsGA.plotXaxis, {xAxis: e.value.name})
            }
            if(element === 'yTagAttributeId'){
                trackGAEvent(eventsGA.plotYaxis, {yAxis: e.value.name})
            }
        setFunction({ group, element, value: e.value.id })
        setChanged(true);
    };

    React.useMemo(() => {
        if (changed) {
            saveFunction(element, group);
        }
        setChanged(false);
    }, [value]);

    return (
        <div className="dropDown">
            <label>{label || ''}</label>
            <DropDownList
                data={data}
                itemRender={itemRender}
                textField="name"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                value={items.find((e) => e.id === value) || { name: 'Select' }}
            />
        </div>
    );
};


interface ITooltipProps {
    label?: string,
    items: IDropDownData[],
    element: IElementType,
    group: string | null,
    value: { id: string; name: string | null; },
    index?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null, index?: number) => any;
}

export const Tooltip = ({ label, items, element, group, value, index, setFunction, saveFunction }: ITooltipProps) => {
    const [data, setData] = React.useState(items.slice());
    const [changed, setChanged] = React.useState<boolean>(false);

    React.useEffect(() => {
        setData(items.slice())
    }, [items]);

    const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const data = items.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setData(filterData(event.filter));
    };

    const handleChange = (e: DropDownListChangeEvent) => {
        trackGAEvent(eventsGA.map_tooltips, {tooltip: e.value.name})
        setFunction({ group, element, value: e.value, index })
        setChanged(true);
    };

    React.useMemo(() => {
        if (changed) {
            saveFunction(element, group);
        }
        setChanged(false);
    }, [value]);

    return (
        <div className="dropDown">
            <label>{label || ''}</label>
            <DropDownList
                data={data}
                textField="name"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                value={value || { name: 'select' }}
            />
        </div>
    );
};