import './DropDown.scss';
import React from "react";
import { DropDownList, DropDownListChangeEvent, DropDownListFilterChangeEvent, DropDownTreeChangeEvent } from "@progress/kendo-react-dropdowns";
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IDropDownData, IDropDownTreeFilterData } from 'store/sidebar/types';
import { deleteQuickPlotThunk, setQuickPlotThunk } from 'store/sidebar/thunk';
import { selectSelectedTab, workspaceSlice } from 'store/workspace/slice';
import { IElementType } from './types';
import { trackGAEvent } from 'helpers/functions/GoogleAnalytics/GA';
import { eventsGA } from 'helpers/functions/GoogleAnalytics/types';
import DropdownTreeFilter from 'components/dropdownTreeFilter';
import { transformArrayToTreeData } from 'components/dropdownTreeFilter/tree-data-operations';

export interface IDropDownProps {
    label?: string,
    items: IDropDownData[],
    element: IElementType,
    group: string | null,
    value: string | number | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null) => any;
}

export const DropdownTreeFilterSidebar = ({ label, items, element, group, value, setFunction, saveFunction }: IDropDownProps) => {
    const dispatch = useAppDispatch();
    const [data, setData] = React.useState<IDropDownTreeFilterData[]>([]);
    const { plotId, workspaceId } = useAppSelector(state => state.workspaceSlice);
    const selectedTab = useAppSelector(selectSelectedTab)
    const [changed, setChanged] = React.useState<boolean>(false);


    React.useEffect(() => {
        setData(transformArrayToTreeData(items.slice()));
    }, [items]);


    const removePlot = async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, plotName: string) => {
        event.preventDefault();
        event.stopPropagation();
        const plotId = items.find((option: IDropDownData) => option.name === plotName)?.id;
        if (plotId)
            await dispatch(deleteQuickPlotThunk(plotId));
    }


    const handleChange = async (e: IDropDownTreeFilterData) => {
        if (element === 'quickPlotId') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { payload } : any = await dispatch(setQuickPlotThunk({
                workspaceId,
                plotId,
                quickPlotId: e.id,
                field: selectedTab?.type === 2 ? 'histogramOptions' : 'plotOptions'
            }))

            dispatch(workspaceSlice.actions.changePlotTitle({ id: plotId, value: payload?.data?.title}))
            return
        }
            if(element === 'xTagAttributeId'){
                trackGAEvent(eventsGA.plotXaxis, {xAxis: e.text})
            }
            if(element === 'yTagAttributeId'){
                trackGAEvent(eventsGA.plotYaxis, {yAxis: e.text})
            }
        setFunction({ group, element, value: e.id })
        setChanged(true);
    };

    React.useMemo(() => {
        if (changed) {
            saveFunction(element, group);
        }
        setChanged(false);
    }, [value]);

    const selected = data.map(item=>item.items).flat().find(item=>item?.id === value)
    
    return (
        <div className="dropDown">
            <label>{label || ''}</label>
            <DropdownTreeFilter value={selected || { name: 'Select' }} handleChange={handleChange} data={data}/>
            {/* <DropDownList
                data={data}
                itemRender={itemRender}
                textField="name"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                value={items.find((e) => e.id === value) || { name: 'Select' }}
            /> */}
        </div>
    );
};


interface ITooltipProps {
    label?: string,
    items: IDropDownData[],
    element: IElementType,
    group: string | null,
    value: IDropDownTreeFilterData,
    index?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null, index?: number) => any;
}

export const TooltipTreeFilter = ({ label, items, element, group, value, index, setFunction, saveFunction }: ITooltipProps) => {
    const [data, setData] = React.useState<IDropDownTreeFilterData[]>([]);
    const [changed, setChanged] = React.useState<boolean>(false);

    React.useEffect(() => {
        setData(transformArrayToTreeData(items.slice()))
    }, [items]);

    const handleChange = (e: IDropDownTreeFilterData) => {
        trackGAEvent(eventsGA.map_tooltips, {tooltip: e.text})
        setFunction({ group, element, value: e, index, data })
        setChanged(true);
    };

    React.useMemo(() => {
        if (changed) {
            saveFunction(element, group);
        }
        setChanged(false);
    }, [value]);

    const selected = data.map(item=>item.items).flat().find(item=>item?.id === value?.id)
    return (
        <div className="dropDown">
            <label>{label || ''}</label>
            <DropdownTreeFilter value={selected || { name: 'Select' }} handleChange={handleChange} data={data}/>
            {/* <DropDownList
                data={data}
                textField="name"
                filterable={true}
                onFilterChange={filterChange}
                onChange={handleChange}
                value={value || { name: 'select' }}
            /> */}
        </div>
    );
};