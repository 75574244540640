import { Dispatch } from 'react';
import { ChangePasswordInterface } from '../../store/password/change/types';
import { api } from '../root';
import { addNotification } from '../../store/notifications/slice';
import { SignUp } from 'store/signup/types';
import { ResetPasswordInterface } from 'store/password/forgot/types';
import { IResponseSignIn } from './types';
import { LoginCredentials } from 'store/signin/types';
import { handleRequestErrors } from 'helpers/functions/commons';

export const signIn = async (credentials: LoginCredentials, thunkAPI: unknown) => {
    try {
        const response = await api.post<IResponseSignIn>('/Account/login', credentials);
        return response.data;
    } catch (e) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const enable2FA = async ({ userId, token }: { userId: string, token: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get('/Account/enable-authenticator', { params: { userId, token } });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const verify2FA = async (code: string, thunkAPI: unknown) => {
    try {
        const response = await api.post<string>('/Account/verify-authenticator', { code });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const signInMFA = async (twoFactorCredentials: { twoFactorCode: string, authType: number }, thunkAPI: unknown) => {
    try {
        const response = await api.post('/Account/login-2fa', twoFactorCredentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const forgotPassword = async (email: string, thunkAPI: unknown) => {
    try {
        const response = await api.post<string>('/Account/forgot-password', { email });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const changePassword = async (changedPassword: ChangePasswordInterface, thunkAPI: unknown) => {
    try {
        const response = await api.post<string>('/Account/change-password', changedPassword);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const resetPassword = async (resetedPassword: ResetPasswordInterface, thunkAPI: unknown) => {
    try {
        const response = await api.post<string>('/Account/reset-password', resetedPassword);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const revokeToken = async () => {
    try {
        await api.post('/Account/signout', {});
    } catch (e: unknown) {
        const validationMessage = e as { message: string };
        return validationMessage.message;
    }
}

export const sendEmailCode = async (email: string, thunkAPI: unknown) => {
    try {
        const response = await api.get('/Account/sendemailcode', { params: { email } });
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const sendSMSCode = async (phone: string | undefined, thunkAPI: unknown) => {
    try {
        const response = await api.get('/Account/sendsmscode', { params: { phone } });
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const signUp = async (signUpCredentials: SignUp, thunkAPI: unknown) => {
    try {
        const response = await api.post<string>('/Account/signup', signUpCredentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const accountTest = async (thunkAPI: unknown) => {
    try {
        const response = await api.get('/Account/test');
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const registration = async (email: string, thunkAPI: unknown) => {
    try {
        const response = await api.post('/Account/registration', { email });
        return response.data
    } catch (e) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

