import ReactGA from 'react-ga4'
import { eventsGA } from './types';

export const initGA = () => {
    process.env.REACT_APP_GA_ID && ReactGA.initialize(process.env.REACT_APP_GA_ID as string)
}

export const setUserToGA = (userId: string) => {
    ReactGA.set({ userId });
}

export const trackGAEvent = (event: (typeof eventsGA)[keyof typeof eventsGA] , options?: any) => {
    ReactGA.event(event, options);
}

export const sendPageViewGA = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page View" });
}