import { Dispatch } from 'react';
import { api } from '../root';
import { Company } from '../../store/company/types';

import { addNotification } from '../../store/notifications/slice';
import { handleRequestErrors } from 'helpers/functions/commons';

export const createCompany = async (credentials: Company, thunkAPI: unknown) => {
    try {
        const response = await api.post<Company>('/Client/create', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getAllCompanies = async (credentials: {pageNumber: number, pageSize: number}, thunkAPI: unknown) => {
    try {
        const response = await api.get<Company[]>(`/Client/getall/${credentials.pageNumber}`, {
            params: {
                pageSize: credentials.pageSize
            }
        });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getCompaniesPartial = async (credentials: {skip: number, take: number}, thunkAPI: unknown) => {
    try {
        const response = await api.get<Company[]>(`/Client/getAll/${credentials.skip}?pageSize=${credentials.take}&pagingType=1`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const updateCompany = async (credentials: Company, thunkAPI: unknown) => {
    try {
        const response = await api.put<Company>('/Client/update', credentials);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const deleteCompany = async (credentials: string, thunkAPI: unknown) => {
    try {
        const response = await api.delete<Company>(`/Client/delete/${credentials}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}