import { IBenchmarkType } from "store/explore/types";

type GroupedItem = {
    title: string;
    id: string;
    items: GroupedItem[];
  };

export const groupBenchmarkTypes = (array: IBenchmarkType[], keys: (keyof IBenchmarkType)[]) => {
    return array.reduce((result: GroupedItem[], item: IBenchmarkType) => {
        let nestedObject = result;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = item[key];
            const existingGroup = nestedObject.find(group => group.id === value + item.fluidType);
            if (existingGroup) {
                nestedObject = existingGroup.items;
            } else {
                const newGroup: GroupedItem = { title: value, id: value + item.fluidType, items: [] };
                nestedObject.push(newGroup);
                nestedObject = newGroup.items;
            }
            if (i === keys.length - 1) {
                nestedObject.push({...item, title: item.trainingRegion, id: item.id, items: [] });
            }
        }
        return result;
    }, []);
}

export const addFieldIds = (arr: any[]) => {
    return arr.map((item, i)=>({...item, identifierFieldId: i}))
}