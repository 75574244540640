import React, { useEffect, useState } from "react";
import './HistogramOptions.scss';
import { Button } from "@progress/kendo-react-buttons";
import { DropDown } from "components/SideBarItems/DropDown";
import { InputCharactersCounter, NumericInput } from "components/SideBarItems/Input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getHistogramOptionsThunk, getQuickPlotsThunk, saveQuickPlotThunk, saveSidebarHistogramThunk } from "store/sidebar/thunk";
import { KendoCheckbox } from "components/SideBarItems/Checkbox";
import { sideBarTreeSlice } from "store/sidebar/slice";
import { T_getHistogramPlotData } from "store/explore/thunk";
import { selectSelectedTab } from "store/workspace/slice";
import sortAlphabetically from "helpers/functions/sortAlphabetically";
import { DropdownTreeFilterSidebar } from "components/SideBarItems/DropDownTreeFilter";

export const HistogramOptions: React.FC = () => {
    const dispatch = useAppDispatch();
    const [opened, setOpened] = useState<boolean>(true);
    const { quickPlots, numericTagAttributesValues, histogramOptions } = useAppSelector(state => state.sideBarTreeSlice);
    const { workspaceId, plotId: selectedPlotId } = useAppSelector(state => state.workspaceSlice);
    const selectedTab = useAppSelector(selectSelectedTab)
    const plotId = selectedTab?.id || selectedPlotId

    useEffect(() => {
        if (plotId && workspaceId)
            dispatch(getHistogramOptionsThunk({ workspaceId, plotId }));
    }, [plotId]);

    useEffect(() => {
        if (plotId && workspaceId)
            dispatch(getHistogramOptionsThunk({ workspaceId, plotId: plotId }));
    }, [plotId]);

    const saveAsQuickPlot = async () => {
        await dispatch(saveSidebarHistogramThunk({ plotOptions: histogramOptions, plotId, workspaceId }));
        await dispatch(saveQuickPlotThunk(plotId))
        await dispatch(getQuickPlotsThunk());
    }

    const handleChangeValue = (obj: {element?: string , value?: unknown, group?: string | null}): void => {
        dispatch(sideBarTreeSlice.actions.setHistogramOptionItem(obj))
    }

    const handleSaveValues = async (element?: string) => {
        await dispatch(saveSidebarHistogramThunk({ plotOptions: {...histogramOptions, xMin: histogramOptions.xMin || null, xMax: histogramOptions.xMax || null, statsPrecision: histogramOptions.statsPrecision || 0 }, plotId, workspaceId }))
        const serversideOptions = ['bins', 'xMin', 'xMax', 'xLogarithmic', 'yLogarithmic', 'xTagAttributeId', 'statsPrecision'] 
        if(selectedTab?.id && element && serversideOptions.includes(element)){
            dispatch(T_getHistogramPlotData({workspaceId, plotId: selectedTab.id}))
        }
    };

    const errorMessageXmin = histogramOptions.xMin && histogramOptions.xMax && (histogramOptions.xMin as number) > (histogramOptions.xMax as number) ? 'xMin can not be greater than xMax' : ''

    return (
        <div className="histogramOptions">
            <div className="contentVisibilityHandler">
                <h3><span onClick={() => setOpened(!opened)} className={opened ? "k-icon k-i-caret-alt-down" : "k-icon k-i-caret-alt-right"}></span>Histogram Options</h3>
            </div>
            <div className={opened ? "content" : "content closed"}>
                <InputCharactersCounter setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Plot Title" element="title" max={250} value={histogramOptions.title} group={null} />
                {/* <DropDown setFunction={handleChangeValue} saveFunction={handleSaveValues} items={plotTypes} label="Plot Type" element="type" group={null} value={histogramOptions.type + ''} /> */}
                <DropDown setFunction={handleChangeValue} saveFunction={handleSaveValues} items={quickPlots.filter(item=>item.type === selectedTab?.type)} label="QuickPlot" element="quickPlotId" group={null} value={histogramOptions.quickPlotId} />
                <Button themeColor={"primary"} onClick={saveAsQuickPlot}>Save To Quick Plot</Button>
                {/* <DropDown setFunction={handleChangeValue} saveFunction={handleSaveValues} items={sortAlphabetically(numericTagAttributesValues, 'name')} label="X Axis" element="xTagAttributeId" group={null} value={histogramOptions.xTagAttributeId} /> */}
                <DropdownTreeFilterSidebar setFunction={handleChangeValue} saveFunction={handleSaveValues} items={sortAlphabetically(numericTagAttributesValues, 'name')} label="X Axis" element="xTagAttributeId" group={null} value={histogramOptions.xTagAttributeId} />
                <NumericInput setFunction={handleChangeValue} saveFunction={handleSaveValues} label="X Min" element="xMin" group="clientSideOptions" value={histogramOptions.xMin} errorMessage={!!errorMessageXmin}  />
                <NumericInput setFunction={handleChangeValue} saveFunction={handleSaveValues} label="X Max" element="xMax" group="clientSideOptions" value={histogramOptions.xMax} errorMessage={errorMessageXmin} />
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="X Gridlines" element="xGridlines" group="clientSideOptions" value={histogramOptions.xGridlines} />
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Y Gridlines" element="yGridlines" group="clientSideOptions" value={histogramOptions.yGridlines} />
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="X Logarithmic" element="xLogarithmic" group="clientSideOptions" value={histogramOptions.xLogarithmic} />
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Y Logarithmic" element="yLogarithmic" group="clientSideOptions" value={histogramOptions.yLogarithmic} />
                <NumericInput errorMessage="greater than 0"  required={true} setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Bins" element="bins" group="serverSideOptions" value={histogramOptions.bins} min={1} max={2000000} type="text" pattern={/^\d+$/} />
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Y Invert" element="yInvert" group="clientSideOptions" value={histogramOptions.yInvert} />
                <NumericInput  errorMessage="greater than or equal 0" required={true} setFunction={handleChangeValue} saveFunction={handleSaveValues} max={15} min={0} label="Stats Precision" element="statsPrecision" group="serverSideOptions" type="text" pattern={/^\d+$/} value={histogramOptions.statsPrecision} />
                <h4>Legends</h4>
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Show Filters" group="clientSideOptions" element="showFilters" value={histogramOptions.showFilters} />
                <h4>Labels</h4>
                <KendoCheckbox setFunction={handleChangeValue} saveFunction={handleSaveValues} label="Show Axis Labels" element="showAxisLabels" group="clientSideOptions" value={histogramOptions.showAxisLabels} /> 
            </div>
        </div>
    )
}

