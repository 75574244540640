import React, { useMemo } from "react"
import { TreeViewDataItem } from "store/sidebar/types"
import { FILTER_TYPES } from "helpers/constants/filters"
import { FilterCheckbox } from "./Checkbox"
import { FilterDropDown } from "./DropDown"
import { FilterButton } from "./Button"
import { FilterInput } from "./Input"
import { FilterTitle } from "./Title"
import { FilterLine } from "./Line"
import {
  ItemRenderProps
} from "@progress/kendo-react-treeview"
import { ITreeElementProps } from "helpers/types/tree"
import Filter from "components/Filter/Filter"

const TreeItem = (props: Omit<ItemRenderProps, "item"> & { item: TreeViewDataItem; }) => {
  const deepCheckFilterApplied = (items: TreeViewDataItem[]): boolean => {
    const dropdownNames: (keyof typeof FILTER_TYPES)[] = [FILTER_TYPES.title, FILTER_TYPES.subMenu]
    if (!dropdownNames.includes(props.item.type as keyof typeof FILTER_TYPES)) return false
    if (!items.length) return false

    return items.some((item) => {
      if (item.type === "subMenu") return items.some((item: TreeViewDataItem["items"][number]) => item.filterApplied)
    })
  }

  const filterClassName = useMemo(() => {
    return deepCheckFilterApplied(props.item.items) ? "text-primary" : "text-secondary"
  }, [props.item.items])

  const COMPONENTS_BY_FILTER_TYPE: { [key in keyof typeof FILTER_TYPES]: React.FC<ITreeElementProps> } = {
    [FILTER_TYPES.title]: FilterTitle,
    [FILTER_TYPES.subMenu]: FilterTitle,
    [FILTER_TYPES.subTitle]: FilterTitle,
    [FILTER_TYPES.select]: FilterDropDown,
    [FILTER_TYPES.input]: FilterInput,
    [FILTER_TYPES.button]: FilterButton,
    [FILTER_TYPES.filter]: Filter,
    [FILTER_TYPES.numeric]: Filter,
    [FILTER_TYPES.checkbox]: FilterCheckbox,
    [FILTER_TYPES.line]: FilterLine,
  }
  const Component = COMPONENTS_BY_FILTER_TYPE[props.item.type as keyof typeof FILTER_TYPES]

  return (
    <>
      {props.item.type === FILTER_TYPES.title || props.item.type === FILTER_TYPES.subMenu || props.item.type === FILTER_TYPES.subTitle ? (
        <div onClick={() => props.item.handleExpandChange?.(props.item)} className={filterClassName} style={{ cursor: "pointer" }}>
          <Component key={props.item.id} props={props} appliedFilterClassName={filterClassName} />
        </div>
      ) : (
        <Component key={props.item.id} props={props} appliedFilterClassName={filterClassName} />
      )}
    </>
  )
}

export default React.memo(TreeItem)
