import { createAsyncThunk } from '@reduxjs/toolkit';
import { License, PaginationInterface } from './types';
import { createLicense, getAllLicenses, updateLicense, deleteLicense } from '../../api/license/licenseService';

export const createLicenseThunk = createAsyncThunk('license/create', async (credentials: License, thunkAPI) => {
    delete credentials.clientName
    const response = await createLicense(credentials, thunkAPI)
    
    return response
});

export const getAllLicensesThunk = createAsyncThunk('license/getAllLicenses', async (credentials: PaginationInterface, thunkAPI,) => {
    const response = await getAllLicenses(credentials, thunkAPI)
    return response
});


export const updateLicenseThunk = createAsyncThunk('license/update', async (credentials: Partial<License>, thunkAPI) => {
    delete credentials.clientName
    const response = await updateLicense(credentials, thunkAPI)
    return response
});

export const deleteLicenseThunk = createAsyncThunk('license/delete', async (credentials: string, thunkAPI) => {
    const response = await deleteLicense(credentials, thunkAPI)
    return response
});


