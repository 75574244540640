import { getActiveUser } from 'helpers/auth';
import React, { useEffect, useRef } from 'react';
import { IResponseSignIn } from 'api/auth/types';
import { T_SaveUserActions } from 'store/workspace/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ROUTES, USER_ACTION_TYPES } from 'helpers/constants/app';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { selectSelectedProjectId } from 'store/ccs/selectors';


const Private2FARoutes = () => {
    const user = getActiveUser()
    let mfaVerified = true;
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const { workspaceId, userActions, tabs } = useAppSelector(store => store.workspaceSlice)
    const isUserActionsApplied = useRef(false)
    const selectedTab = tabs.find(item => item.isSelected)
    const selectedProjectId = useAppSelector(selectSelectedProjectId)


    useEffect(() => {
        dispatch(T_SaveUserActions({ selectedTabId: selectedTab?.id, selectedProjectId, workspaceId, isMapViewSelected: selectedTab?.type === 'mapView' }))
    }, [location.pathname, selectedTab?.id, selectedProjectId, workspaceId])

    useEffect(() => {
        if (isUserActionsApplied.current || !userActions.actionName) return
        isUserActionsApplied.current = true
        const { actionName } = userActions
        const path = window.location.pathname
        const userActionentries = Object.entries(USER_ACTION_TYPES)
        const lastPath = userActionentries.find(([_, value]) => actionName === value) // eslint-disable-line @typescript-eslint/no-unused-vars
        lastPath?.[0] && !path.includes(ROUTES[lastPath?.[0] as keyof typeof ROUTES]) && navigate(ROUTES[lastPath?.[0] as keyof typeof ROUTES]);
    }, [userActions])

    if ((user as IResponseSignIn).mfa) {
        const storedMfaVerified = localStorage.getItem('mfaVerified')
        mfaVerified = storedMfaVerified && JSON.parse(storedMfaVerified)
    }

    return user ? mfaVerified ? <Outlet /> : <Navigate to={'/signinMFA'} replace={true} /> : <Navigate to="/signIn" replace={true} />;
};

export default Private2FARoutes;