import React, { useState } from "react";
import './Input.scss';
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { CrossPlotOptions, IHistogramOptions, PlotOptions } from "store/sidebar/types";
import { selectSelectedTab, workspaceSlice } from "store/workspace/slice";
import { MapOptions } from "store/workspace/types";
import { IElementType } from "./types";

type IInputCharactersCounterProps = {
    label?: string;
    max: number;
    element: keyof PlotOptions | keyof CrossPlotOptions | keyof MapOptions | keyof IHistogramOptions;
    group: string | null;
    value: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (...args: any) => any;
}

export const InputCharactersCounter = ({ label, max, element, group, value, setFunction, saveFunction }: IInputCharactersCounterProps) => {
    const dispatch = useAppDispatch();
    const { plotId } = useAppSelector(state => state.workspaceSlice);
    const selectedTab = useAppSelector(selectSelectedTab)

    const handleBlur = async () => {
        await saveFunction(element, group);
        if (element === 'title')
            dispatch(workspaceSlice.actions.changePlotTitle({ id: selectedTab?.id || plotId, value }))
    }

    return (
        <div className="InputCharactersCounter" >
            <div className="InputCharactersCounterContent">
                <label>{label}</label>
                <Input
                    maxLength={max}
                    onChange={(e) => setFunction({ group, element, value: e.value })}
                    onBlur={handleBlur}
                    value={value ?? ''}
                />
            </div>
            {value?.length === max ? <span className="error">{`Max ${max} symbols`}</span> : ''}
        </div>
    );
};

interface INumericInputProps {
    label?: string;
    element: IElementType;
    group: string | null;
    value: number | null;
    max?: number;
    min?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null) => any;
    errorMessage?: string | boolean,
    required?: boolean,
    type?: 'number' | 'text',
    pattern?: RegExp,
}

export const NumericInput = ({ required, label, element, group, value, setFunction, saveFunction, max, min, errorMessage, type='number', pattern }: INumericInputProps) => {
    const [error, setError] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleError = (e: any) => {
        Number.isFinite(+e.value) || 
        isFinite(e.key) || 
        e.keyCode === 8 || 
        e.keyCode === 9 || 
        e.keyCode === 46 || 
        e.keyCode === 110 || 
        e.keyCode === 190 ? setError(false) : setError(true)
    };

    const handleBlur = () => {
        if(required ? !error : !errorMessage)
        saveFunction(element, group)
    }

    const handleChange = (e: InputChangeEvent) => {
        let val = +e.value
        if(pattern && !(pattern.test(e.value))) return
        if(required && (val < (element==="bins" ? 1 : 0) || e.value === '')){
            setError(true)
        }
        if (Number.isFinite(max) && val > (max as number)) {
            val = (max as number)
        } else if (Number.isFinite(min) && val < (min as number)) {
            val = (min as number)
        }
        setFunction({ group, element, value: e.value === '' ? null : +val })
    }
    return (
        <div className="InputCharactersCounter">
            <div className="InputCharactersCounterContent">
                <label>{label}</label>
                <Input
                    type={type}
                    // pattern={pattern}
                    value={Number.isFinite(value) ? +(value as number) : ''}
                    onBlur={handleBlur}
                    max={max}
                    min={min}
                    step={element==="bins" ? 1 : 0.1}
                    onChange={handleChange}
                    onKeyDown={handleError}
                />
            </div>
            
            {error ? <span className="error">Only numbers</span> : ''}
            {(required ? error : errorMessage) ? <span className="error">{errorMessage}</span> : ''}
        </div>
    );
};

interface ITextInputProps {
    label?: string;
    element: keyof PlotOptions | keyof CrossPlotOptions | keyof MapOptions | keyof IHistogramOptions;
    group: string | null;
    value: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (...args: any) => any;
}

export const TextInput = ({ label, element, group, value, setFunction, saveFunction }: ITextInputProps) => {

    const handleBlur = () => {
        saveFunction(element, group)
    };

    return (
        <div className="TextInput">
            <label>{label}</label>
            <Input
                value={value ?? ''}
                onChange={(e) => setFunction({ group, element, value: e.value })}
                onBlur={handleBlur}
            />
        </div>
    );
};
