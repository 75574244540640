import { api } from "api/root";
import { Dispatch } from "react";
import { handleRequestErrors } from "helpers/functions/commons";
import { addNotification } from "store/notifications/slice";

export const R_addGridDataView = async (data: {workspaceId: string, type: string, gridColumns?: string[], datasetId: null | string, title: string | null}, thunkAPI: unknown) => {
    try {
        const response = await api.post(`/Workspace/${data.workspaceId}/GridView`, {identifierFieldType: +data.type, gridColumns: data.gridColumns, datasetId: data.datasetId, title: data.title});
        return {...response.data};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_addGridDataViewData = async (data: {workspaceId: string, plotId: string, offset: number, limit: number}, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/GridView/${data.plotId}/Data`, {params: {offset: +data.offset, limit: data.limit }});
        return {...response.data};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError ? thunkRejectError.rejectWithValue(validationMessage) : e;
    }
}


export const R_changeGridDataView = async (data: {workspaceId: string, plotId: string, tagAttributeId: string, isFreezed?: boolean, order?: number}, thunkAPI: unknown) => {
    try {
        const response = await api.patch(`/Workspace/${data.workspaceId}/GridView/${data.plotId}/Columns`, {
            tagAttributeId: data?.tagAttributeId,
            isFreezed: data?.isFreezed,
            order: data?.order,
        });
        return {...response.data};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || {errorMsg: validationMessage};
    }
}

export const R_changeGridDataViewOrder = async (data: {workspaceId: string, plotId: string, gridColumns: {tagAttributeId: string, isFreezed?: boolean, order?: number}}, thunkAPI: unknown) => {
    try {
        const response = await api.put(`/Workspace/${data.workspaceId}/GridView/${data.plotId}`, { gridColumns: data.gridColumns });
        return {...response.data};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || {errorMsg: validationMessage};
    }
}