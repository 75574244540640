// eslint-disable-next-line
// @ts-nocheck

import * as React from "react";

import {
    DropDownTree,
    DropDownTreeChangeEvent,
    DropDownTreeExpandEvent,
    DropDownTreeFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { processTreeData, expandedState } from "./tree-data-operations";
import './style.scss'
import { FilterDescriptor } from "@progress/kendo-data-query";

const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "id";
const textField = "text";
const subItemsField = "items";
const fields = { selectField, expandField, dataItemKey, subItemsField };

const DropdownTreeFilter = ({ data, value, handleChange, width = '100%', withClearIcon = false, label = "", element = '', itemKey='' }) => {
    const [expanded, setExpanded] = React.useState([]);
    const [open, setOpen] = React.useState(false)
    const [filter, setFilter] = React.useState<FilterDescriptor>({
        value: "",
        operator: "contains",
    });
    const [preventClose, setPreventClose] = React.useState(false)

    const onChange = (event: DropDownTreeChangeEvent) => {
      if (event.value.items) {
        onExpandChange({
          item: event.value,
          level: event.level,
          value: event.value,
        })
        setPreventClose(true)
      } else {
        handleChange(event.value, element, itemKey)
      }
    }

    const onExpandChange = React.useCallback(
      (event: DropDownTreeExpandEvent) => {
        setExpanded(expandedState(event.item, dataItemKey, expanded))
      },
      [expanded],
    )

    const treeData = React.useMemo(
        () => processTreeData(data, { expanded, value, filter }, fields),
        [expanded, value, filter]
    );

    const onFilterChange = (event: DropDownTreeFilterChangeEvent) =>
        setFilter(event.filter);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Item = (props: ItemProps) => {
        return (
          <span className={`dropdownItem ${props.item.isDisabled ? "disabledItem" : ""} ${value?.id === props.item.id ? "selectedDropdownValue" : ""} `}>
            {props.item[textField]}
          </span>
        )
    };

    React.useEffect(() => {
      if (preventClose) {
        setOpen(true)
        setPreventClose(false)
      }
    }, [preventClose])

    return (
      <div style={{ width: width || "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {!!label && <span style={{ whiteSpace: "nowrap" }}>{label}</span>}
        <DropDownTree
          key={`${expanded.join()}`}
          opened={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          style={{ width: width || "100%", maxHeight: 300, overflowY: "auto" }}
          placeholder="Select"
          data={treeData}
          //   popupSettings={{
          //     popupClass: "dropdownTreeFiltersCont",
          //   }}
          // className={`dropdownTreeFilters ${withClearIcon ? "showClearIcon" : "removeClearIcon"}`}
          value={value}
          onChange={onChange}
          item={Item}
          textField={textField}
          dataItemKey={dataItemKey}
          selectField={selectField}
          expandField={expandField}
          onExpandChange={onExpandChange}
          filterable={true}
          onFilterChange={onFilterChange}
          filter={filter.value}
        />
      </div>
    )
};

export default DropdownTreeFilter