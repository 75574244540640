import { configureStore, combineReducers } from '@reduxjs/toolkit';
import mapViewSlice from './mapView/slice';
import workspaceSlice  from 'store/workspace/slice';
import exploreSlice from './explore/slice';
import sideBarTreeSlice from './sidebar/slice';
import forgotPasswordSlice from './password/forgot/slice';
import signInSlice from './signin/slice';
import notificationsSlice from './notifications/slice'
import userSlice from './user/slice';
import licenseSlice from './license/slice';
import companySlice from './company/slice';
import ccsSlice from './ccs/slice';
import registrationSlice from './registration/slice';
import homeSlice from './home/slice';
import footerSlice from './footer/slice';
import userData from './userData/slice'
import userStateSlice from './userState/slice';

const rootReducer = combineReducers({
  signInSlice,
  userStateSlice,
  forgotPasswordSlice,
  userSlice,
  companySlice,
  licenseSlice,
  notificationsSlice,
  sideBarTreeSlice,
  exploreSlice,
  ccsSlice,
  workspaceSlice,
  registrationSlice,
  mapViewSlice,
  footerSlice,
  userData,
  homeSlice
});

const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
  });
};

export const store = setupStore()
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];