import { IOptions } from "store/sidebar/types"

export const PORTAL_ROOT_ID = 'portal-root'

export const USER_ROLES = {
    admin: 'Global Admin',
    client: 'Client Admin',
    user: 'User'
}

export const datasetOptions: IOptions[] = [
  { name: "Location", id: "15", type: "" },
  { name: "Subsurface Assets", id: "1", type: "" },
  { name: "CCS Projects", id: "8", type: "" },
  { name: "CO2 Emitters", id: "4", type: "" },
  { name: "Infrastructure Assets", id: "2", type: "" },
]

export const datasetOptionsMapping = {
  1: "Subsurface Assets",
  2: "Infrastructure Assets",
  4: "CO2 Emitters",
  5: "Common Data",
  8: "CCS Projects",
  15: "Location",
}

export const datasetOptionsMappingEmissions = {
    1: 'Subsurface Assets',
    2: 'Infrastructure Assets',
    4: 'Emissions',
    5: 'Common Data',
    8: 'CCS Projects',
}

export const datasetsIds = datasetOptions.map(item=>+item.id)

export const unitOptions = [{ name: 'Field', id: '1' }, { name: 'Metric', id: '2' }]


export const MappingOptionType = {
    title: 8,
    x: 5,
    y: 6,
    y2: 7
}


export const MappingOptionTypeReverse = {
    8: 'title',
    5: 'x',
    6: 'y',
    7: 'y2',
}