import React, { useMemo, useState } from "react";
import './Input.scss';
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { IElementType } from "./types";

interface IInputCharactersCounterProps {
    label?: string;
    element: IElementType;
    group?: string | null;
    value: boolean;
    type?: string;
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFunction: (args: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveFunction: (element?: IElementType, group?: string | null) => any;
}

export const KendoCheckbox = ({ label, element, group, value, disabled, setFunction, saveFunction }: IInputCharactersCounterProps) => {
    const [changed, setChanged] = useState<boolean>(false);

    const handleChange = (e: CheckboxChangeEvent) => {
        setFunction({ group, element, value: e.value });
        setChanged(true);
    };

    useMemo(() => {

        if (changed) {
            saveFunction(element, group);
        }
        setChanged(false);
    }, [value]);

    return <Checkbox
        className={disabled ? "checkBox disabled" : "checkBox"}
        labelPlacement={"before"}
        label={label}
        value={disabled ? false : value}
        onChange={handleChange}
    />;
};
