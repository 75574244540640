import React, { useState, useEffect } from 'react'
import { Portal } from 'components/Portal/Portal';
import { Loader } from '@progress/kendo-react-indicators';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import './addFilterSetPortal.scss';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { T_addFilterset, T_getFilterset } from 'store/sidebar/thunk';
import { IFilterSet } from 'store/sidebar/types';

type Props = {
    portalOpened: boolean
    closePortal: () => void
}



function AddFilterSetPortal({ portalOpened, closePortal }: Props) {
    const dispatch = useAppDispatch()
    const { workspaceId } = useAppSelector(store => store.workspaceSlice)
    const { filterSets: { list } } = useAppSelector(state => state.sideBarTreeSlice)
    const [title, setTitle] = useState('')
    const [process, setProcess] = React.useState(false)
    const [error, setError] = useState('')

    const handleChange = (e: InputChangeEvent) => {
        setTitle(e.value)
        if(list.some((item: IFilterSet)=>item.title === e.value)){
            setError(`A filter set with name ${e.value} already exist.`)
        }else{
            setError('')
        }
    }

    useEffect(()=>{
        setTitle('')
        setError('')
    }, [portalOpened])


    const handleSave = async () => {
        setProcess(true)
        
        await dispatch(T_addFilterset({title: title, workspaceId}))
        await dispatch(T_getFilterset({workspaceId}))
        
        setProcess(false)
        closePortal()
    }


    return (
        <Portal
            opened={portalOpened}
            closePortal={() => { closePortal() }}
        >
            <div className='portal benchmark-row'>
                <div className='portal-header home'>
                    <h4 className='title'>Add Filter Set</h4>
                    <button className='close-btn' onClick={closePortal} />
                </div>
                <div className='portal-custom-content'>
                    <div>
                        <Input value={title} onChange={handleChange} />
                        {!!error && <span className='error'>{error}</span>}
                    </div>

                    <div className='portal-footer'>
                        {(process) && <Loader size="small" type={"infinite-spinner"} />}
                        <button
                            className='cancel'
                            type="button"
                            onClick={() => { closePortal() }}
                        >
                            Cancel
                        </button>
                        <button
                            className='save'
                            onClick={handleSave}
                            disabled={(!title.trim()) || process || !!error}
                        >
                            Save
                        </button>
                    </div>
                </div>

            </div>
        </Portal>
    )
}

export default React.memo(AddFilterSetPortal)