import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginCredentials } from './types';
import { signIn, signInMFA, verify2FA, enable2FA, sendEmailCode, sendSMSCode } from '../../api/auth/service';

export const signInThunk = createAsyncThunk('user/signIn', async (credentials: LoginCredentials, thunkAPI) => {
  const response = await signIn(credentials, thunkAPI)
  return response
});

export const enable2FAThunk = createAsyncThunk('user/enable2FA', async (credentials: { userId: string, token: string, }, thunkAPI) => {
  const response = await enable2FA(credentials, thunkAPI)
  return response
});

export const verify2FAThunk = createAsyncThunk('user/verify2FA', async (code: string, thunkAPI) => {
  const response = await verify2FA(code, thunkAPI)
  return response
});

export const signInMFAThunk = createAsyncThunk('user/signInMFA', async (twoFactorCredentials: { twoFactorCode: string, authType: number }, thunkAPI) => {
  const response = await signInMFA(twoFactorCredentials, thunkAPI)
  return response
});

export const sendEmailCodeThunk = createAsyncThunk('user/sendEmailCode', async (email: string, thunkAPI) => {
  const response = await sendEmailCode(email, thunkAPI)
  return response
});

export const sendSMSThunk = createAsyncThunk('user/sendSMSCode', async (phoneNumber: string | undefined, thunkAPI) => {
  const response = await sendSMSCode(phoneNumber, thunkAPI)
  return response
});