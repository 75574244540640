import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMapViewFilteredPoints } from "api/mapView/service";
import { CancelTokenSource } from 'axios';

export const getFullMapViewThunk = createAsyncThunk('mapView/getFullMapData', async (data: {
    workspaceId: string,
    source?: CancelTokenSource
}, thunkAPI) => {    
    const response = await getMapViewFilteredPoints({...data, ApplyFilters: false, source: data.source}, thunkAPI)
    
    return response
});

export const getMapViewFilteredPointsThunk = createAsyncThunk('mapView/getFilteredPoints', async (data: {
    workspaceId: string,
    source?: CancelTokenSource
}, thunkAPI) => {    
    const response = await getMapViewFilteredPoints(data, thunkAPI)
    return response
});


export const getMapViewPolygonsThunk = createAsyncThunk('mapView/getPolygons', async (data: {
    workspaceId: string,
    title?: string
    ApplyFilters?: boolean
    leftBottomCorner?: {
        latitude: number,
        longitude: number
    },
    rightTopCorner?: {
        latitude: number,
        longitude: number
    }
}, thunkAPI) => {    
    const response = await getMapViewFilteredPoints(data, thunkAPI)
    return response
});