import { getActiveUser } from 'helpers/auth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'

const PublicRoutes = () => {
    const user = getActiveUser()
    
    return user ? <Navigate to="/home"  replace={true}/> : <Outlet />;
}

export default PublicRoutes;