// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processProjectViewOptions = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((item: any) => {
    result.push({
      parentId: item.id,
      name: item?.title,
      value:  item?.title
    })
  })

  return result;
}


