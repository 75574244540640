import { Dispatch } from 'react';
import { api } from '../root';
import { addNotification } from '../../store/notifications/slice';
import { handleRequestErrors } from 'helpers/functions/commons';
import { CancelTokenSource } from 'axios';

export const getHomeMapData = async (thunkAPI: unknown) => {
    try {
        const response = await api.get('/Data/GetMapViewData');
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const getHomeNotifications = async (data: {pageNumber: number, pageSize: number, isLast: boolean} ,thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Notification/getInternalNotifications/${data.pageNumber}`, {
            params: {
                pageSize: data.pageSize
            }
        });
        return {data: response.data, isLast: data.isLast};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const setNotificationsRead = async (notifications: string[],thunkAPI: unknown) => {
    try {
        const response = await api.put('/Notification', notifications);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const getUsers = async (thunkAPI: unknown) => {
    try {
        const response = await api.get('/Lookup');
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const getDatasets = async (thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Dataset/GetAll`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const setSharedUsers = async (data: {workspaceId: string, users: { sharedWith: string[]} }, thunkAPI: unknown) => {
    try {
        const response = await api.put(`/Workspace/${data.workspaceId}/Share`, data.users);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const unshareWorkspaceWithMe = async (workspaceId: string, thunkAPI: unknown) => {
    try {
        const response = await api.delete(`/Workspace/${workspaceId}/Unshare`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const getLinkedInPosts = async (data: {pageNumber: number, pageSize: number, isLast: boolean, source?: CancelTokenSource} ,thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Notification/getIntegrationNotification/${data.pageNumber}`, {
            params: {
                pageSize: data.pageSize
            },
            cancelToken: data.source?.token
        });
        return {data: response.data, isLast: data.isLast};
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

export const getLinkedInFollowers = async (thunkAPI: unknown) => {
    try {
        return {} // TODO: Remove once LinkedIn token issue resolved
        const response = await api.get(`/Notification/LinkedinFollowers`)
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
};

