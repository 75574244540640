import { api } from "api/root";
import { handleRequestErrors } from "helpers/functions/commons";
import { Dispatch } from "react";
import { addNotification } from "store/notifications/slice";
import { IFilteredData } from "./types";

export const filteredDataWindow = async (workspaceId: string, thunkAPI: unknown) => {
    try {
        const response = await api.get<IFilteredData>(`/Data/filteredDataWindow/${workspaceId}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}