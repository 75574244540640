export const FILTER_TYPES = {
    title: 'title',
    subMenu: 'subMenu',
    subTitle: 'subTitle',
    select: 'select',
    input: 'input',
    button: 'button',
    filter: 'filter',
    numeric: 'numeric',
    checkbox: 'checkbox',
    line: 'line',
} as const