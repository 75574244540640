import { WritableDraft } from 'immer/dist/internal'
import { TreeViewDataItem } from 'store/sidebar/types'

export const checkFilterActiveStatus = (filter: WritableDraft<TreeViewDataItem>, inputs?: {firstConditionInput: string, secondConditionInput: string}, ar?: string): boolean => {
  
    const values: boolean[] = [
      !!filter.firstConditionInput,
      // !!filter.firstConditionList,
      !!filter.items.length,
      // !!filter.logicalOperationList,
      !!filter.secondConditionInput,
      // !!filter.secondConditionList,
      !!filter.values?.length,
      !!filter.inputValue,
      !!filter.checked,
      !!filter.selected,
    ] 
    return values.includes(true) || !!inputs?.firstConditionInput || !!inputs?.secondConditionInput
}