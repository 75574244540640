import permVsPoro from '../../assets/icons/permVsPoro.svg';
import gridViewIcon from '../../assets/icons/dataGrid.svg';
import statisticsTable from '../../assets/icons/statisticsTable.svg';
import histogramIcon from '../../assets/icons/histogram.svg';
import productionPlot from '../../assets/icons/productionPlot.svg';
import benchmarkingPlot from '../../assets/icons/benchmarkingPlot.svg';
import searchListDark from '../../assets/icons/searchListDark.svg';

export const iconMapping = {
    1: permVsPoro,
    2: histogramIcon,
    3: statisticsTable,
    4: gridViewIcon,
    5: searchListDark,
    6: productionPlot,
    7: benchmarkingPlot,
}