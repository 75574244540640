export const eventsGA = {
    click: 'click',
    share_workspace: 'Workspace shared',
    new_workspace: 'Workspace Created',
    unit_choice: 'workspace unit selected',
    load_datasets: 'Datasets Loaded',
    new_dataset: 'Dataset Created',
    share_dataset: 'Dataset Shared',
    unit_choice_dataset: 'Dataset unit selected',
    fieldType_choice_dataset: 'Dataset type selected',
    map_tooltips: 'Map Tooltip Selected',
    categorize: 'Categorize Selected',
    create_crossPlot: 'Created CrossPlot',
    create_Histogram: 'Created Histogram',
    create_Statistics_table: 'Created Statistics Table',
    create_data_grid: 'Created Data Grid',
    create_production_plot: 'Created Production plot',
    create_benchmarking_plot: 'Created Benchmarking Plot',
    create_project_viewer: 'Created Project Viewer',
    plotXaxis: 'selected X axis',
    plotYaxis: 'selected Y axis',
    create_CCS_project: 'Created CCS Project',
    added_storages_global: 'Added Potential Storage Sites from bMark database',
    added_sources_global:'Added Potential CO2 Source from bMark database',
    added_transports_global: 'Added Potential Transport from bMark database',
    added_storages_user_data: 'Added Potential Storage Sites from User Database',
    added_sources_user_data:'Added Potential CO2 Source from User Database',
    added_transports_user_data: 'Added Potential Transport from User Database',
    filters_applied_user_data: 'Applied to User Data',
    filters_applied_benchmark: 'Applied to Benchmark plots',
    filtered: 'filters'

} as const

export const clickEventsGA = {
    community: 'Community Click',
    learn: 'Learn Click',
} as const