import { Dispatch } from 'react';
import { INewHistogramPlotData, INewPlotData, IPlot, IProductionPlot } from 'store/explore/types';
import { api } from '../root';
import { addNotification } from '../../store/notifications/slice';
import { handleRequestErrors } from 'helpers/functions/commons';
import { createPlotData } from './processors';

export const getPlot = async (data: { plotId: string, workspaceId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get<IPlot>(`/Workspace/${data.workspaceId}/CrossPlot/${data.plotId}/Data`, {
            params: {
                Label: '</br><b>#value</b></br>',
                Category: '<b>#key:</b> #value<br>',
                Tooltip: '<b>#key:</b> #value<br>',
                Extra: '<extra></extra>',
            }
        });
        const crossPlots = createPlotData(response.data.crossPlots);
        return { ...response.data, crossPlots };
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        // thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const addNewPlot = async (data: { workspaceId: string, newPlotData: INewPlotData }, thunkAPI: unknown) => {
    try {
        const { workspaceId, newPlotData } = data;
        const response = await api.post<IPlot>(`/Workspace/${workspaceId}/CrossPlot`, newPlotData);
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const addNewHistogramPlot = async (data: { workspaceId: string, newPlotData: INewHistogramPlotData }, thunkAPI: unknown) => {
    try {
        const { workspaceId, newPlotData } = data;
        const response = await api.post<IPlot>(`/Workspace/${workspaceId}/Histogram/`, { ...newPlotData });
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}


export const R_getHistogramPlotData = async (data: { workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        const { workspaceId, plotId } = data;
        const response = await api.get<IPlot>(`/Workspace/${workspaceId}/Histogram/${plotId}/Data`);
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_addProductionPlot = async (data: { workspaceId: string, title: IProductionPlot['title'], xTagAttributeId: IProductionPlot['xTagAttributeId'], yTagAttributeId: IProductionPlot['yTagAttributeId'], y2TagAttributeId?: IProductionPlot['y2TagAttributeId'] }, thunkAPI: unknown) => {
    try {
        const { 
            workspaceId, 
            title,
            xTagAttributeId,
            yTagAttributeId,
            y2TagAttributeId 
        } = data;
        const response = await api.post<IPlot>(`/Workspace/${workspaceId}/ProductionPlot`, {
            title,
            xTagAttributeId,
            yTagAttributeId,
            y2TagAttributeId,
        });
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_getProductionPlotData = async (data: { workspaceId: string, plotId: string }, thunkAPI: unknown) => {
    try {
        const { workspaceId, plotId } = data;
        const response = await api.get<IPlot>(`/Workspace/${workspaceId}/ProductionPlot/${plotId}/Data`);
        return response.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}

export const R_updatePlotOrder = async (data: { workspaceId: string; plotOrder: { plotId: string; order: number }[] }, thunkAPI: unknown) => {
  try {
    const { workspaceId, plotOrder } = data
    const response = await api.put(`/Workspace/${workspaceId}/UpdatePlotOrder`, plotOrder)
    return response.data
  } catch (e: unknown) {
    const validationMessage = handleRequestErrors(e)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any; dispatch: Dispatch<unknown> }
    thunkRejectError.dispatch(addNotification({ type: "error", message: validationMessage }))
    return thunkRejectError.rejectWithValue(validationMessage)
  }
}

