import React, { ReactElement } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import './popUpDropDown.scss';
import { Link } from 'react-router-dom';
import { IFilterByRoleType } from 'store/user/types';

interface popUpProps{
    icon: string,
    name: string,
    links?: propsLinks[] | IFilterByRoleType[],
    topComponent?: ReactElement
    
}

interface propsLinks {
    name:string,
    icon: string,
    url:string,
    roles?: string[]
    callback?: () => void
}

const PopUpDropDown = (props:popUpProps) => {
    const anchor = React.useRef<HTMLButtonElement | null>(null);
    const content = React.useRef<HTMLDivElement | null>(null);
    const [show, setShow] = React.useState(false);
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onBlur = (event: any) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShow(false);
          }
    };

    return (
        <div className='pop-up-dropdown-wrapper'>
            <button
                className='anchor k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                onClick={()=>setShow(!show)}
                ref={anchor}
            >
                <img src={props.icon} alt={props.name} />
            </button>
            <Popup
                onOpen={()=>content.current?.focus()}
                anchor={anchor.current}
                collision={{
                    horizontal: 'fit',
                    vertical: 'flip',
                }}
                show={show}
                popupClass={'popup-content'}
            >
                <div 
                    ref={content}
                    className='pop-up-content-wrapper' 
                    tabIndex={0}
                    onBlur={onBlur}
                >
                    {props.topComponent}
                    <ul>
                        {props.links?.map(i=>{
                            return(
                                <li key={i.name} className='listItem' onClick={i.callback}>
                                   <Link to={i.url}>{i.name}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </Popup>
        </div>
    )
}

export default PopUpDropDown