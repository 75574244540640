import { T_CcsOptionsDropdownItemFormat, T_CcsPrimaryTag } from "helpers/types/ccs"

export const HIGHLIGHT_COLOR = '#00800047';
export const RED_HIGHLIGHT_COLOR = '#ff000040'

// *******************  DASHBOARD **********************

export const PROJECTS_PATHS = {
    oilGas: 'oil-gas',
    eor: 'eor',
    ccs: 'ccs',
    h2: 'h2',
    geothermal: 'geothermal'
} as const

export const PROJECTS_NAVBAR_TEMPLATE = [
    {
        title: 'CCS',
        path: PROJECTS_PATHS.ccs,
        disabled: false
    },
    {
        title: 'Oil-Gas',
        path: PROJECTS_PATHS.oilGas,
        disabled: true
    },
    {
        title: 'EOR',
        path: PROJECTS_PATHS.eor,
        disabled: true
    },
    {
        title: 'H2',
        path: PROJECTS_PATHS.h2,
        disabled: true
    },
    {
        title: 'Geothermal',
        path: PROJECTS_PATHS.geothermal,
        disabled: true
    },
]

export const PROJECTS_CCS_PATHS = {
    dashboard: 'dashboard',
    storageResource: 'storage-resource',
    injectivity: 'injectivity',
    storageRisk: 'storage-risk',
    booking: 'booking',
}

export const PROJECTS_CCS_MAIN_NAV_TEMPLATE = [
    {
        title: 'Dashboard',
        path: PROJECTS_CCS_PATHS.dashboard,
    },
    {
        title: 'Storage Resource',
        path: PROJECTS_CCS_PATHS.storageResource,
    },
    {
        title: 'Injectivity',
        path: PROJECTS_CCS_PATHS.injectivity,
    },
    {
        title: 'Storage Risk',
        path: PROJECTS_CCS_PATHS.storageRisk,
    },
]

export const PROJECTS_CCS_BOOKINGS_NAV_TEMPLATE = {
    title: 'SRMS Booking',
    path: PROJECTS_CCS_PATHS.booking,
}

export const CCS_PROJECT_ACTION_NAMES = {
    add: 'add',
    edit: 'edit',
}

export const CCS_INSTANCE_TYPES = {
    sources: 'sources',
    transports: 'transports',
    storages: 'storages',
} as const

export const CCS_PRIMARY_TAGS: { [key: string]: T_CcsPrimaryTag & { fieldId: string } } = {
  country: {
    fieldId: "eabdd240-b6d3-47c4-a715-912c3c23c38f",
    id: "Country",
    name: "Country",
  },
  fieldName: {
    fieldId: "fc55de05-8074-4687-8b46-04644b777b81",
    id: "Name",
    name: "Name",
  },
  pipelineStartCountry: {
    fieldId: "44af11e0-5bfd-4862-ba47-bb5b4ad1ba1e",
    id: "Pipeline_StartCountry",
    name: "Pipeline Start Country",
  },
  reservoirType: {
    fieldId: "86fe92a4-b712-4b6b-8fb9-3534db03bb0d",
    id: "FluidType",
    name: "Reservoir Type",
  },
  storageResource: {
    fieldId: "3a3b7a3a-d9af-4f82-9f89-b51f0cf84584",
    id: "TotalStorageResource",
    name: "Total Storage Resource \n (MT)",
    calculable: true,
    unchangeable: true,
  },
  injectionRate: {
    fieldId: "abc16778-bff6-4bac-a01f-468df859f21b",
    id: "InjectionRate",
    name: "Injection Rate \n (Mtpa)",
    calculable: true,
    unchangeable: true,
  },
  storageRiskIndex: {
    fieldId: "StorageRiskIndex",
    id: "StorageQualityIndex",
    name: "Storage Quality Index",
    calculable: true,
    unchangeable: true,
  },
  operator: {
    fieldId: "a80eb499-13f7-4ae7-94d7-52d46c869234",
    id: "Operator",
    name: "Operator",
  },
  siteName: {
    fieldId: "4ede2417-a23a-4d80-b63a-7080dfd030e0",
    id: "SiteName",
    name: "Name",
  },
  name: {
    fieldId: "2D695153-5E0C-EF11-9F8A-0022481B5FA5",
    id: "Name",
    name: "Name",
  },
  entryType: {
    fieldId: "bab62885-08f0-4879-9491-9bd016bb267b",
    id: "EntryType",
    name: "Entry Type",
  },
  powerPlantFuel: {
    fieldId: "2339a2b6-08ee-4814-8655-59c765151707",
    id: "Powerplant_Fuel_Type",
    name: "Power Plant Fuel",
  },
  co2Output: {
    fieldId: "3A3B7A3A-D9AF-4F82-9F89-B51F0CF84584",
    id: "CO2_Output",
    name: "CO2 Output \n (MT)",
  },
  captureVolume: {
    fieldId: "CaptureVolume",
    id: "CaptureVolume",
    name: "Capture Volume \n (MT)",
    calculable: true,
  },
  projectLength: {
    fieldId: "ProjectLength",
    id: "ProjectLength",
    name: "Project Length \n (Years)",
    calculable: true,
  },
  storageRequired: {
    fieldId: "StorageRequired",
    id: "StorageRequired",
    name: "Storage Required \n (MT)",
    calculable: true,
    unchangeable: true,
  },
  fluidType: {
    fieldId: "86fe92a4-b712-4b6b-8fb9-3534db03bb0d",
    id: "FluidType",
    name: "Fluid Type",
  },
  status: {
    fieldId: "7a1ef58b-fde4-4181-93d7-9be234b6592a",
    id: "Infrastructure_Status",
    name: "Status",
  },
  diameter: {
    fieldId: "06f655f2-9355-40eb-8e17-6a897db7bdba",
    id: "Pipeline_Diameter",
    name: "Diameter \n (Inch)",
  },
}

export const CCS_INSTANCES = {
    [CCS_INSTANCE_TYPES.sources]: {
        title: 'Potential CO2 Sources',
        endpoint: 'Source',
        tags: [
            CCS_PRIMARY_TAGS.name,
            CCS_PRIMARY_TAGS.entryType,
            CCS_PRIMARY_TAGS.powerPlantFuel,
            CCS_PRIMARY_TAGS.co2Output,
            CCS_PRIMARY_TAGS.captureVolume,
            CCS_PRIMARY_TAGS.projectLength,
            CCS_PRIMARY_TAGS.storageRequired,
            CCS_PRIMARY_TAGS.operator,
        ],
        popup: {
            title: 'Add CO2 Source',
            endpoint: 'Sources',
            tagIds: [
                CCS_PRIMARY_TAGS.country.fieldId,
                CCS_PRIMARY_TAGS.name.fieldId,
            ]
        },
    },
    [CCS_INSTANCE_TYPES.storages]: {
        title: 'Potential CO2 Storage Sites',
        endpoint: 'Storage',
        tags: [
            CCS_PRIMARY_TAGS.fieldName,
            CCS_PRIMARY_TAGS.reservoirType,
            CCS_PRIMARY_TAGS.storageResource,
            CCS_PRIMARY_TAGS.injectionRate,
            CCS_PRIMARY_TAGS.storageRiskIndex,
            CCS_PRIMARY_TAGS.operator,
        ],
        popup: {
            title: 'Add CO2 Storage',
            endpoint: 'Storages',
            tagIds: [
                CCS_PRIMARY_TAGS.country.fieldId,
                CCS_PRIMARY_TAGS.fieldName.fieldId,
            ]
        },
    },
    [CCS_INSTANCE_TYPES.transports]: {
        title: 'Potential CO2 Transports',
        endpoint: 'Transport',
        tags: [
            CCS_PRIMARY_TAGS.fieldName,
            CCS_PRIMARY_TAGS.fluidType,
            CCS_PRIMARY_TAGS.diameter,
            CCS_PRIMARY_TAGS.status,
            CCS_PRIMARY_TAGS.operator,
        ],
        popup: {
            title: 'Add CO2 Transport',
            endpoint: 'Transports',
            tagIds: [
                CCS_PRIMARY_TAGS.pipelineStartCountry.fieldId,
                CCS_PRIMARY_TAGS.fieldName.fieldId,
            ]
        },
    },
}

export const CCS_FIELD_INPUT_TYPES = {
    string: 1,
    numeric: 2
}

export const CCS_ADD_ALL_INSTANCES_OPTION: T_CcsOptionsDropdownItemFormat = {
    id: 'selectAllId',
    text: 'Select all'
}

// ********************************************************

// *******************  RISK STORAGE **********************

const sealLithologyOptions = ['Sandstone', 'Siltstone', 'Mudstone', 'Shale', 'Chert', 'Marl', 'Carbonate', 'Carbonate (Limestone)', 'Carbonate (Wackestone)', 'Carbonate (Mudstone)', 'Carbonate (Dolostone)', 'Carbonate (Chalky Limestone)', 'Salt', 'Anhydrite', 'Evaporite', 'Igneous', 'Tar Mat', 'Tuff', 'Coal']
const trapTypeOptions = ['Structural', 'Structural - Stratigraphic', 'Stratigraphic', 'Fluidic / Hydrodynamic', 'Fault Dominated (Faulted)', 'Fold Dominated (Folded)', 'Diapiric Traps (Salt Tectonics, Mud Diapirs)', 'Unconformity (Erosional)', 'Depositional', 'Diagenetic', 'Fluidic', 'Hydrodynamic', 'Normal', 'Reverse', 'Strike Slip', 'Faulted Folds  (Faulted Anticlines, Faulted Domes)', 'Fault Related Folds', 'Fault Free Folds (Simple Anticline, Dome,  4-way Closure)', 'Diapiric Folds (Salt/Mud Tectonic Folds)', 'Piercement (Salt Wall, Flank Traps)', 'Sub-Salt', 'Subunconformity', 'Supraunconformity', 'Lateral Changes', 'Buried Depositional Relief (inc. non-tectonically arched traps)', 'Dolomisation / Dissolution', 'Fracturing', 'Cementation', 'Tar Seals', 'Post Depositional Updip', 'Post Depositional Porosity/Permeability Enhancement', 'Basin Centre Gas', 'Coal Bed Methane', 'Gas Hydrate', 'Basement Involved Normal Fault', 'Listric Normal Fault', 'Tilted/Rotated Fault Block', 'Horst block/Graben', 'Fault Bend', 'Reverse - Fault Bend', 'Ductile Deformation', 'Wrench Faults (Flower Structures)', 'Ambiguous (Tectonically Sealed)', 'Normal Faulted Folds', 'Reverse Faulted Folds', 'Fault Bend, Duplex,  Complex Multiple Thrusted Anticlines', 'Fault Propagation', 'Fault Drag', 'Rollover Anticline', 'Compressional Anticlines (Lift Off / Decollement, Chevron, Kink Band)', 'Compactional Anticlines (inc. Drape  Anticlines, Differential Compaction', 'Complex Uplift, Arching', 'Suprasalt (Anticlines, Domes, 4-way Closure,  Salt Cored Folds)', 'Ridges', 'Salt Wall Folds (Turtle Structure Anticlines, Salt Wall Synclines)', 'Faulted Diapiric fold ', 'Bucket Welds, Salt Feeders', 'Truncation (Assoc. Regional Tilt)', 'Assoc. Incision', 'Assoc. Buried Relief', 'Onlap', 'Incised River Valleys, Canyons, Channels (Erosional Trough Fill)', 'Facies Change', 'Homocline, Monocline, Clinoform', 'Pinch Out', 'Reef, Bioherm', 'Fan, Lobe', 'Bar', 'Steppe, Steppe-related Bioherm']
const dDepositionalEnviromentOptions = ['Alluvial', 'Aeolian', 'Fluvial', 'Lacustrine', 'Shallow Marine', 'Shallow Marine (Delta)', 'Shallow Marine (Estuarine)', 'Shallow Marine (Shoreface)', 'Marine Slope', 'Deep Marine', 'Deep Marine (Turbidite)', 'Deep Marine (Submarine Fan)', 'Igneous Basement', 'Volcaniclastic', 'Metamorphic', 'Intrusion', 'Glaciofluvial', 'Glaciolacustrine', 'Glaciomarine', 'Lava Flow', 'Clastic Lagoon', 'Playa Lake', 'Sabkha', 'Intertidal', 'Carbonate Lagoon', 'Fore-Reef', 'Barrier Reef', 'Patch Reef', 'Carbonate Platform', 'Lacustrine (Turbidite)']
const reservoirLithologyOptions = ['Sandstone', 'Siltstone', 'Mudstone', 'Shale', 'Conglomerate', 'Breccia', 'Carbonate', 'Carbonate (Limestone)', 'Carbonate (Dolostone)', 'Carbonate (Chalky Limestone)', 'Igneous', 'Metamorphic', 'Coal', 'Tuff', 'Chert', 'Salt', 'Diatomite',]

export const CCS_STORAGE_RISK_INSTANCE_TYPES = {
    storages: 'storages',
} as const

export const CCS_STORAGE_RISK_PRIMARY_TAGS: { [key: string]: T_CcsPrimaryTag } = {
    inProject: {
        id: 'eabdd240-b6d3-47c4-a715-912c3c23c38f',
        name: 'inProject',
    },
    field: {
        id: 'Name',
        name: 'Name',
    },
    discoveryDate: {
        id: 'DiscoveryDate',
        name: 'Discovery Date',
        isDate: true
    },
    totalWells: {
        id: 'WellsTotalDrilled',
        name: 'Total Wells',
        isNumeric: true
    },
    faults: {
        id: 'Faults',
        name: 'Faults',
        isNumeric: true
    },
    sealLightology: {
        id: 'SealLithology',
        name: 'Seal Lithology',
        isSelect: true,
        selectOptions: sealLithologyOptions
    },
    sealThickness: {
        id: 'SealThickness',
        name: 'Seal Thickness \n (ft)',
        isNumeric: true
    },
    sealName: {
        id: 'Seal',
        name: 'Seal Formation',
    },
    traps: {
        id: 'TrapType',
        name: 'Trap Type',
        isSelect: true,
        selectOptions: trapTypeOptions
    },
    depositionalEnvironment: {
        id: 'DepositionalEnvironmentPrimary',
        name: 'Depositional Environment',
        isSelect: true,
        selectOptions: dDepositionalEnviromentOptions
    },
    porosity: {
        id: 'Porosity',
        name: 'Porosity \n (frac)',
        isNumeric: true
    },
    permeability: {
        id: 'Permeability',
        name: 'Permeability \n (mD)',
        isNumeric: true
    },
    lithology: {
        id: 'Lithology',
        name: 'Reservoir Lithology',
        isSelect: true,
        selectOptions: reservoirLithologyOptions
    },
    salinity: {
        id: 'Salinity',
        name: 'Salinity \n (ppm)',
        isNumeric: true
    },
    thickness: {
        id: 'NetPay',
        name: 'Net Pay \n (ft)',
        isNumeric: true
    },
    initialPressure: {
        id: 'InitialPressure',
        name: 'Initial Pressure \n (psi)',
        isNumeric: true
    },
    depth: {
        id: 'TopStructDepth',
        name: 'Depth - Top Structure \n (ft)',
        isNumeric: true,
        hasMinValue: true
    },
    storageQualityIndex: {
        id: 'StorageQualityIndex',
        name: 'Storage Quality Index',
        calculable: true,
        unchangeable: true
    },
}


export const CCS_RISK_STORAGE_INSTANCES_TABLE = {
    [CCS_STORAGE_RISK_INSTANCE_TYPES.storages]: {
        title: 'Potential CO2 Storage Sites',
        endpoint: 'Storage',
        tags: [
            CCS_STORAGE_RISK_PRIMARY_TAGS.field,
            CCS_STORAGE_RISK_PRIMARY_TAGS.discoveryDate,
            CCS_STORAGE_RISK_PRIMARY_TAGS.totalWells,
            CCS_STORAGE_RISK_PRIMARY_TAGS.faults,
            CCS_STORAGE_RISK_PRIMARY_TAGS.sealLightology,
            CCS_STORAGE_RISK_PRIMARY_TAGS.sealThickness,
            CCS_STORAGE_RISK_PRIMARY_TAGS.sealName,
            CCS_STORAGE_RISK_PRIMARY_TAGS.traps,
            CCS_STORAGE_RISK_PRIMARY_TAGS.depositionalEnvironment,
            CCS_STORAGE_RISK_PRIMARY_TAGS.porosity,
            CCS_STORAGE_RISK_PRIMARY_TAGS.permeability,
            CCS_STORAGE_RISK_PRIMARY_TAGS.lithology,
            CCS_STORAGE_RISK_PRIMARY_TAGS.salinity,
            CCS_STORAGE_RISK_PRIMARY_TAGS.thickness,
            CCS_STORAGE_RISK_PRIMARY_TAGS.initialPressure,
            CCS_STORAGE_RISK_PRIMARY_TAGS.depth,
            CCS_STORAGE_RISK_PRIMARY_TAGS.storageQualityIndex,
        ],
    },

}

export const radarChartKeys = {
    'DiscoveryDateRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.discoveryDate.name,
    'WellsTotalDrilledRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.totalWells.name,
    'FaultsRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.faults.name,
    'SealLithologyRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.sealLightology.name,
    'SealThicknessRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.sealThickness.name,
    'SealRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.sealName.name,
    'TrapTypeRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.traps.name,
    'DepositionalEnvironmentPrimaryRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.depositionalEnvironment.name,
    'PorosityRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.porosity.name,
    'PermeabilityRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.permeability.name,
    'LithologyRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.lithology.name,
    'SalinityRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.salinity.name,
    'NetPayRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.thickness.name,
    'InitialPressureRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.initialPressure.name,
    'TopStructDepthRisk': CCS_STORAGE_RISK_PRIMARY_TAGS.depth.name
}

// ********************************************************

// *******************  Storage Resources **********************

export const CCS_STORAGE_RESOURCES_TYPES = {
    gasFields: 'gasFields',
    oilFields: 'oilFields',
    aquiferFields: 'aquiferFields'
} as const


export const CCS_STORAGE_RESOURCES_PRIMARY_TAGS: { [key: string]: T_CcsPrimaryTag } = {
    fieldName: {
        id: 'Name',
        name: 'Name',
        isNumeric: false
    },
    fluidType: {
        id: 'FluidType',
        name: 'Fluid Type',
        isNumeric: false
    },
    depthTopStructure: {
        id: 'TopStructDepth',
        name: 'Top Structure Depth \n (ft)',
        isNumeric: true,
        hasMinValue: true
    },
    FGIIP: {
        id: 'FreeGasInitiallyInPlace',
        name: 'FGIIP \n (Bcf)',
        isNumeric: true
    },
    EURG: {
        id: 'UltimateGas',
        name: 'EURg \n (Bcf)',
        isNumeric: true

    },
    gasExpansionFactor: {
        id: 'GasExpansionFactor',
        name: 'Bg \n (scf/rcf)',
        isNumeric: true

    },
    GasReinjectionEfficiency: {
        id: 'GasReinjectionEfficiency',
        name: 'Injection Efficiency',
        isNumeric: true,
        unchangeable: true,

    },
    OilReinjectionEfficiency: {
        id: 'OilReinjectionEfficiency',
        name: 'Injection Efficiency',
        isNumeric: true,
        unchangeable: true,

    },
    AquiferReinjectionEfficiency: {
        id: 'AquiferReinjectionEfficiency',
        name: 'Injection Efficiency',
        isNumeric: true,
        hasDefaultValue: true,

    },
    suggestedGasExpansionFactor: {
        id: 'RecommendedGasExpansionFactor',
        name: 'Suggested Eg \n (scf/rcf)',
        isNumeric: true,
        unchangeable: true,
        calculable: true

    },
    CO2Density: {
        id: 'CO2Density',
        name: 'CO2 Density \n (kg/m3)',
        isNumeric: true,
        unchangeable: true,
        calculable: true,

    },
    gasTotalStorageResource: {
        id: 'GasTotalStorageResource',
        name: 'Total Storage Resource \n (MT)',
        isNumeric: true,
        unchangeable: true,
        calculable: true,
    },
    oilTotalStorageResource: {
        id: 'OilTotalStorageResource',
        name: 'Total Storage Resource \n (MT)',
        isNumeric: true,
        unchangeable: true,
        calculable: true,
    },
    GOR: {
        id: 'GasOilRatio',
        name: 'GOR \n (scf/stb)',
        isNumeric: true
    },
    STOIIP: {
        id: 'StockTankOilInitiallyInPlace',
        name: 'STOIIP \n (MMstb)',
        isNumeric: true,
    },
    EURO: {
        id: 'UltimateOil',
        name: 'EURo \n (MMstb)',
        isNumeric: true,

    },
    FVFO: {
        id: 'FormationVolumeFactor',
        name: 'FVFo \n (rb/stb)',
        isNumeric: true,
    },
    recommendedFVFo: {
        id: 'RecommendedOilFormationVolumeFactor',
        name: 'Recommended FVFo \n (rb/stb)',
        isNumeric: true,
        unchangeable: true
    },
    area: {
        id: 'Area',
        name: 'Area',
        isNumeric: true,
    },
    netPay: {
        id: 'NetPay',
        name: 'Net Pay \n (ft)',
        isNumeric: true
    },
    porosity: {
        id: 'Porosity',
        name: 'Porosity',
        isNumeric: true
    },
    efficiency: {
        id: 'ReinjectionEfficiency',
        name: 'Efficiency \n (E)',
        isNumeric: true,
        calculable: true,
        unchangeable: true,
    },
    aquiferTotalStorageResource: {
        id: 'AquiferTotalStorageResource',
        name: 'Total Storage Resource \n (MT)',
        calculable: true,
        unchangeable: true,
    },
    totalStorageResource: {
        id: 'TotalStorageResource',
        name: 'Totoal Storage Resource',
        calculable: true,
        unchangeable: true
    }

}

export const CCS_STORAGE_RESOURCES_INSTANCES_TABLE = {
    title: 'Potential CO2 Sources',
    [CCS_STORAGE_RESOURCES_TYPES.gasFields]: {
        title: 'Depleted Field: Gas',
        endpoint: 'Storage',
        type: 'Gas',
        tags: [
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.fieldName,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.fluidType,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.depthTopStructure,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.FGIIP,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.EURG,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.gasExpansionFactor,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.GasReinjectionEfficiency,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.CO2Density,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.gasTotalStorageResource,
        ]
    },
    [CCS_STORAGE_RESOURCES_TYPES.oilFields]: {
        title: 'Depleted Field: Oil',
        endpoint: 'Storage',
        type: 'Oil',
        tags: [
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.fieldName,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.fluidType,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.depthTopStructure,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.GOR,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.STOIIP,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.EURO,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.FVFO,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.OilReinjectionEfficiency,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.CO2Density,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.oilTotalStorageResource,
        ]
    },
    [CCS_STORAGE_RESOURCES_TYPES.aquiferFields]: {
        title: 'Aquifer',
        endpoint: 'Storage',
        type: 'Aquifer',
        tags: [
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.fieldName,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.fluidType,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.depthTopStructure,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.area,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.netPay,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.porosity,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.CO2Density,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.AquiferReinjectionEfficiency,
            CCS_STORAGE_RESOURCES_PRIMARY_TAGS.aquiferTotalStorageResource,
        ]
    }
}

// ********************************************************


// *******************srmsBooking**************************

export const CCS_BOOKING_PRIMARY_TAGS: { [key: string]: T_CcsPrimaryTag } = {
    project: {
        id: 'title',
        name: 'Project',
        unchangeable: true
    },
    storageSite: {
        id: 'storageSite',
        name: 'Storage Site',
        unchangeable: true
    },
    resource: {
        id: 'resource',
        name: 'Resource',
        unchangeable: true
    },
    storageRequired: {
        id: 'storageRequired',
        name: 'Storage Required',
        unchangeable: true
    },
    sourceOfCO2: {
        id: 'sourceOfCO2',
        name: 'Source Of CO2',
        unchangeable: true
    },
    transportationMethods: {
        id: 'transportationMethods',
        name: 'Transport',
        unchangeable: true
    },
    capacity_1P: {
        id: 'capacity_1P',
        name: '1P',
        isNumeric: true
    },
    capacity_2P: {
        id: 'capacity_2P',
        name: '2P',
        isNumeric: true
    },
    capacity_3P: {
        id: 'capacity_3P',
        name: '3P',
        isNumeric: true
    },
    contingent_1C: {
        id: 'contingent_1C',
        name: '1C',
        isNumeric: true
    },
    contingent_2C: {
        id: 'contingent_2C',
        name: '2C',
        isNumeric: true
    },
    contingent_3C: {
        id: 'contingent_3C',
        name: '3C',
        isNumeric: true
    },
    prospective_1U: {
        id: 'prospective_1U',
        name: '1U',
        isNumeric: true
    },
    prospective_2U: {
        id: 'prospective_2U',
        name: '2U',
        isNumeric: true
    },
    prospective_3U: {
        id: 'prospective_3U',
        name: '3U',
        isNumeric: true
    },
}


export const CCS_BOOKING_INSTANCES_TABLE = {
    title: 'Projects',
    endpoint: 'booking',
    tags: [
        CCS_BOOKING_PRIMARY_TAGS.project,
        CCS_BOOKING_PRIMARY_TAGS.storageSite,
        CCS_BOOKING_PRIMARY_TAGS.resource,
        CCS_BOOKING_PRIMARY_TAGS.storageRequired,
        CCS_BOOKING_PRIMARY_TAGS.sourceOfCO2,
        CCS_BOOKING_PRIMARY_TAGS.transportationMethods,
        CCS_BOOKING_PRIMARY_TAGS.capacity_1P,
        CCS_BOOKING_PRIMARY_TAGS.capacity_2P,
        CCS_BOOKING_PRIMARY_TAGS.capacity_3P,
        CCS_BOOKING_PRIMARY_TAGS.contingent_1C,
        CCS_BOOKING_PRIMARY_TAGS.contingent_2C,
        CCS_BOOKING_PRIMARY_TAGS.contingent_3C,
        CCS_BOOKING_PRIMARY_TAGS.prospective_1U,
        CCS_BOOKING_PRIMARY_TAGS.prospective_2U,
        CCS_BOOKING_PRIMARY_TAGS.prospective_3U,
    ],
}


// ********************************************************

// *******************  Injectivity **********************

export const CCS_INJECTIVITY_TYPES = {
    storages: 'storages',
    ranking: 'ranking',
    estimation: 'estimation'
} as const


export const CCS_INJECTIVITY_PRIMARY_TAGS: { [key: string]: T_CcsPrimaryTag } = {
    fieldName: {
        id: 'Name',
        name: 'Name',
        isNumeric: false,
    },
    totalStorageResource: {
        id: 'TotalStorageResource',
        name: 'Total Storage Resource \n (MT)',
        unchangeable: true,
        isNumeric: true,
    },
    depthTopStructure: {
        id: 'TopStructDepth',
        name: 'Top Structure Depth \n (ft)',
        isNumeric: true,
        hasMinValue: true,
    },
    permeability: {
        id: 'Permeability',
        name: 'Permeability \n (mD)',
        isNumeric: true
    },
    correlationFactor: {
        id: 'CorrelationFactor',
        name: 'Correlation Factor',
        isNumeric: true,
        hasDefaultValue: true,
    },
    netPay: {
        id: 'NetPay',
        name: 'Reservoir - Net Pay \n (ft)',
        isNumeric: true,
    },
    injectivityIndex: {
        id: 'InjectivityIndex',
        name: 'Injectivity Index \n (tpa/kpsi/well)',
        unchangeable: true,
        isNumeric: true,
    },
    noOfWells: {
        name: 'No. of CO2 Injection Wells',
        id: 'NumberOfWells',
        isNumeric: true,
        hasDefaultValue: true,
    },
    fractionNetPayPerforated: {
        name: 'Fraction Net Pay Perforated \n (%)',
        id: 'FractionNetPayPerforated',
        isNumeric: true,
        hasDefaultValue: true,
    },
    downtime: {
        name: 'Downtime \n (%)',
        id: 'Downtime',
        isNumeric: true,
        hasDefaultValue: true
    },
    pressureHead: {
        id: 'PressureHead',
        name: "Pressure Head \n (psi)",
        isNumeric: true,
        hasDefaultValue: true
    },
    injectionRate: {
        id: 'InjectionRate',
        name: 'Injection Rate \n (Mtpa)',
        unchangeable: true
    }

}

export const CCS_INJECTIVITY_INSTANCES_TABLE = {
    title: 'Potential CO2 Sources',
    [CCS_INJECTIVITY_TYPES.ranking]: {
        title: 'Injectivity Ranking',
        endpoint: 'Storage',
        tags: [
            CCS_INJECTIVITY_PRIMARY_TAGS.fieldName,
            CCS_INJECTIVITY_PRIMARY_TAGS.totalStorageResource,
            CCS_INJECTIVITY_PRIMARY_TAGS.depthTopStructure,
            CCS_INJECTIVITY_PRIMARY_TAGS.permeability,
            CCS_INJECTIVITY_PRIMARY_TAGS.netPay,
            CCS_INJECTIVITY_PRIMARY_TAGS.correlationFactor,
            CCS_INJECTIVITY_PRIMARY_TAGS.injectivityIndex,
        ]
    },
    [CCS_INJECTIVITY_TYPES.estimation]: {
        title: 'Project Rate Estimation',
        endpoint: 'Storage',
        tags: [
            CCS_INJECTIVITY_PRIMARY_TAGS.fieldName,
            CCS_INJECTIVITY_PRIMARY_TAGS.noOfWells,
            CCS_INJECTIVITY_PRIMARY_TAGS.fractionNetPayPerforated,
            CCS_INJECTIVITY_PRIMARY_TAGS.downtime,
            CCS_INJECTIVITY_PRIMARY_TAGS.pressureHead,
            CCS_INJECTIVITY_PRIMARY_TAGS.injectionRate,
        ]
    },
}