import { IMapViewPointsResponse } from './../../api/mapView/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFullMapViewThunk, getMapViewFilteredPointsThunk, getMapViewPolygonsThunk } from './thunk';
import { IMapViewState } from './types';

const initialState: IMapViewState = {
    isLoading: false,
    isFullMapLoading: false,
    isFetched: false,
    isMapViewFullDataFetched: false,
    error: null,
    mapView: [],
    mapViewPoints: [[]],
    mapViewFullData: {
        sources: [],
        transports: [],
        storages: [],
        ccsProjects: [],
        userDataLegends: [],
    },
    mapViewData: {
        categories: [],
        sources: [],
        transports: [],
        storages: [],
        ccsProjects: [],
        userDataLegends: [],
    },
    mapViewPolygons: {
        sources: [],
        transports: [],
        storages: [],
        ccsProjects: [],
        userDataLegends: [],
    },
    selectedPoint: undefined,   
};

export const mapViewSlice = createSlice({
    name: 'mapView',
    initialState,
    reducers: {
        setSelectedPoint: (state, action) => {
            state.selectedPoint = action.payload;
        },
        changeIsFetchedStatus: (state, action) => {
            state.isFetched = action.payload
        },
        resetIsMapViewFullDataFetched: (state, action) => {
            state.isMapViewFullDataFetched = action.payload
        },
        changeMapLoadingStatus: (state, action: PayloadAction<{ status: boolean}>) => {
            state.isLoading = action.payload.status
          },
          
    },
    extraReducers: {
        [getMapViewFilteredPointsThunk.pending.type]: (state) => {
            state.isLoading = true;
            state.error = null;
        },

        [getMapViewFilteredPointsThunk.fulfilled.type]: (state, action: PayloadAction<{response: IMapViewPointsResponse, mapViewPoints: IMapViewState['mapViewPoints'], mapViewData: IMapViewState['mapViewData']  }>) => {
            const { mapViewData } = action.payload;
            state.mapViewData = mapViewData;
            state.isLoading = false;
            state.isFetched = true
            state.error = null;
        },

        [getMapViewFilteredPointsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isFetched = true
        },

        [getMapViewPolygonsThunk.pending.type]: (state) => {
            state.isLoading = true;
        },

        [getMapViewPolygonsThunk.fulfilled.type]: (state, action: PayloadAction<{response: IMapViewPointsResponse, mapViewPoints: IMapViewState['mapViewPoints'], mapViewData: IMapViewState['mapViewData']  }>) => {
            const { mapViewData } = action.payload;
            state.mapViewPolygons = mapViewData
            state.isLoading = false;
        },

        [getMapViewPolygonsThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isFullMapLoading = false;
            state.error = action.payload;
        },

        [getFullMapViewThunk.pending.type]: (state) => {
            state.isFullMapLoading = true;
        },

        [getFullMapViewThunk.fulfilled.type]: (state, action: PayloadAction<{response: IMapViewPointsResponse, mapViewPoints: IMapViewState['mapViewPoints'], mapViewData: IMapViewState['mapViewData']  }>) => {
            const { mapViewData } = action.payload;
            state.mapViewFullData = mapViewData
            state.isLoading = false;
            state.isFullMapLoading = false;
            state.isMapViewFullDataFetched = true
        },


        [getFullMapViewThunk.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export default mapViewSlice.reducer;

