import { Dispatch } from "react";
import { api } from "api/root";
import { handleRequestErrors } from "helpers/functions/commons";
import { addNotification } from "store/notifications/slice";
import { addFieldIds, groupBenchmarkTypes } from "./processors";
import { IBenchmarkPlotOptions } from "store/sidebar/types";


export const R_getBenchmarkTypes = async (_: unknown, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Lookup/Benchmarks`);
        const grouped = groupBenchmarkTypes(response.data, ['fluidType', 'predictedParameter'])
        return grouped;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const R_getBenchmarkType = async (data: { id?: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Lookup/Benchmark/${data?.id ?? ''}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}


export const R_addBenchmark = async (data: { workspaceId: string, title: string, type: string | number }, thunkAPI: unknown) => {
    try {
        const response = await api.post(`/Workspace/${data.workspaceId}/BenchmarkPlot`, {
            title: data.title,
            benchmarkId: data.type
        });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const R_changeBenchmark = async (data: { workspaceId: string, plotId: string, type: string | number, benchmarkPlotOptions: IBenchmarkPlotOptions['benchmarkPlotOptions'] }, thunkAPI: unknown) => {
    try {
        const response = await api.put(`/Workspace/${data.workspaceId}/BenchmarkPlot/${data.plotId}`, {
            benchmarkId: data.type,
            benchmarkPlotOptions: data.benchmarkPlotOptions
        });
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const R_getBenchmarkData = async (data: { workspaceId: string, id: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/BenchmarkPlot/${data.id}/full`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const R_getBenchmarkOptions = async (data: { workspaceId: string, id: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/BenchmarkPlot/${data.id}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}


export const R_getBenchmarkDatasets = async (data: { workspaceId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/BenchmarkPlot/Datasets`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}

export const R_getBenchmarkIndentifierFields = async (data: { workspaceId: string }, thunkAPI: unknown) => {
    try {
        const response = await api.get(`/Workspace/${data.workspaceId}/BenchmarkPlot/IndentifierFields`);
        const res = addFieldIds(response.data)
        return res;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}


export const R_addBenchmarkInput = async (data: { workspaceId: string, id: string, body: {plotInputs: {datasetId: string | null, identifierFieldIds?: string[] | null}[]} }, thunkAPI: unknown) => {
    try {
        const response = await api.post(`/Workspace/${data.workspaceId}/BenchmarkPlot/${data.id}/Input`, data.body);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}


export const R_deleteBenchmarkInput = async (data: { workspaceId: string, id: string, }, thunkAPI: unknown) => {
    try {
        const response = await api.delete(`/Workspace/${data.workspaceId}/BenchmarkPlotInput/${data.id}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}


export const R_checkBenchmarkInput = async (data: { workspaceId: string, id: string, }, thunkAPI: unknown) => {
    try {
        const response = await api.patch(`/Workspace/${data.workspaceId}/BenchmarkPlotInput/${data.id}`);
        return response.data;
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError?.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError?.rejectWithValue(validationMessage) || { errorMsg: validationMessage };
    }
}


export const R_editBenchmarkRow = async (data: {workspaceId:string,plotId: string,  id: string, identifierFieldId: string, tags: { tagAttributeId: string, value: string}[]}, thunkAPI: unknown) => {
    try {
    const res = await api.patch(`/Workspace/${data.workspaceId}/BenchmarkPlot/${data.plotId}/Dataset/${data.id}/IdentifierField/${data.identifierFieldId}`, {Tags: data.tags})
    // const res = await api.patch(`/Dataset/${data.id}/IdentifierField/${data.identifierFieldId}`, {Tags: data.tags})
    return res.data
    } catch (e: unknown) {
        const validationMessage = handleRequestErrors(e)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const thunkRejectError = thunkAPI as { rejectWithValue: (message: any) => any, dispatch: Dispatch<unknown> }
        thunkRejectError.dispatch(addNotification({ type: 'error', message: validationMessage }));
        return thunkRejectError.rejectWithValue(validationMessage);
    }
}