import { useAppSelector } from 'hooks/redux';
import React from 'react';
import './footer.scss'

export default function Footer() {
    const { filteredData } = useAppSelector(state => state.footerSlice);
    return (
        <footer className='footer'>
            <div className='footerContent'>
                <div title='The number of records in the Subsurface dataset based on the current filters' >
                    <span className='title'>S</span>
                    <span className='value'>{filteredData.storageDataCount}</span>
                </div>
                <div title='The number of records in the CCS Projects dataset based on the current filters' >
                    <span className='title'>CCS</span>
                    <span className='value'>{filteredData.ccsProjectDataCount}</span>
                </div>
                <div title='The number of records in the CO2 Emitters dataset based on the current filters' >
                    <span className='title'>E</span>
                    <span className='value'>{filteredData.sourceDataCount}</span>
                </div>
                <div title='The number of records in the Infrastructure dataset based on the current filters' >
                    <span className='title'>I</span>
                    <span className='value'>{filteredData.transportDataCount}</span>
                </div>
                <div title='The number of records in the Production dataset based on the current filters' >
                    <span className='title'>P</span>
                    <span className='value'>{filteredData.productionDataCount}</span>
                </div>
                <div title='The number of records in the User dataset based on the current filters' >
                    <span className='title'>U</span>
                    <span className='value'>{filteredData.userDataCount}</span>
                </div>
            </div>
        </footer>
    )
}