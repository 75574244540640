import { IUserActions } from './types';
import { api } from '../root';



export const R_GetUserActions = async () => {
    const response = await api.get<IUserActions>('/Workspace/UserActions');
    return response.data;
}

export const R_SaveUserActions = async (userActions: Partial<IUserActions>) => {
    const response = await api.put<IUserActions>('/Workspace/UserActions', userActions);
    return response.data;
} 
