import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { signInThunk, enable2FAThunk, verify2FAThunk, signInMFAThunk, sendEmailCodeThunk, sendSMSThunk } from './thunk';
import { SignInState, Enable2FAState } from './types';



const initialState: SignInState = {
  credentials: {
    email: '',
    accessToken: '',
    refreshToken: '',
    phoneNumber: '',
    password: '',
    rememberMe: false,
  },
  user: false,
  role: '',
  isLoading: false,
  error: null,
  authenticatorUri: '',
  isEmailVerified: false,
  sharedKey: '',
  phoneNumber: ''
};

export const signInSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: {
    [signInThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [signInThunk.fulfilled.type]: (state, action) => {
      state.credentials = action.payload
      state.isLoading = false;
      state.user = true;
    },

    [signInThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.user = false;
    },

    [enable2FAThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [enable2FAThunk.fulfilled.type]: (state, action: PayloadAction<Enable2FAState>) => {
      state.isLoading = false;
      state.authenticatorUri = action.payload.authenticatorUri
      state.sharedKey = action.payload.sharedKey
      state.user = true;
    },

    [enable2FAThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [verify2FAThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [verify2FAThunk.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.user = true;
    },

    [verify2FAThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [signInMFAThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [signInMFAThunk.fulfilled.type]: (state, action: PayloadAction<{isEmailVerified: boolean}>) => {
      state.isEmailVerified = action.payload.isEmailVerified
      state.isLoading = false;

    },

    [signInMFAThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },


    [sendEmailCodeThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [sendEmailCodeThunk.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },

    [sendEmailCodeThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [sendSMSThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [sendSMSThunk.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.error = null;
    },

    [sendSMSThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default signInSlice.reducer;

