import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientState } from './types';
import { createCompanyThunk, getAllCompaniesThunk, updateCompanyThunk, deleteCompanyThunk } from './thunk'


const initialState: ClientState = {
  companies: [],
  isLoading: false,
  error: null,
  totalRecords: 0
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {

  },
  extraReducers: {
    [createCompanyThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [createCompanyThunk.fulfilled.type]: (state, action) => {
      action.meta.arg.id = action.payload;
      state.companies = [action.meta.arg, ...state.companies];
      state.isLoading = false;

    },

    [createCompanyThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getAllCompaniesThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [getAllCompaniesThunk.fulfilled.type]: (state, action) => {
      state.companies = action.payload.collection
      state.totalRecords = action.payload.totalRecords
      state.isLoading = false;

    },

    [getAllCompaniesThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [updateCompanyThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [updateCompanyThunk.fulfilled.type]: (state, action) => {
      const idx = state.companies.findIndex(i => i.id === action.meta.arg.id);
      if (idx !== -1) {
        state.companies[idx] = action.meta.arg;
      }
      state.isLoading = false;

    },

    [updateCompanyThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [deleteCompanyThunk.pending.type]: (state) => {
      state.isLoading = true;
    },

    [deleteCompanyThunk.fulfilled.type]: (state, action) => {
      const newArr = state.companies.filter(i => i.id !== action.meta.arg);
			state.isLoading = false;
			state.companies = newArr
      state.isLoading = false;
    },

    [deleteCompanyThunk.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
})

export default companySlice.reducer