import React, { useRef } from 'react'
import { ITreeElementProps } from 'helpers/types/tree'
import { useDispatch } from 'react-redux'
import { Checkbox } from '@progress/kendo-react-inputs';
import { workspaceSlice } from 'store/workspace/slice';
import { sideBarTreeSlice } from 'store/sidebar/slice';

export const FilterCheckbox: React.FC<ITreeElementProps> = ({ props }) => {
    const dispatch = useDispatch()
    const timeoutId = useRef<unknown>()
    const checkItem = (e: boolean) => {
        // if (props.item.action === 'isEnabled') {
        //     dispatch(workspaceSlice.actions.enableDisableFilters())
        //     return
        // }
        // if (props.item.action === 'applyToUserdata')
        //     dispatch(workspaceSlice.actions.switchApplyToUserData())


        if (props.item.parent === 'plotOptions')
            dispatch(sideBarTreeSlice.actions.setPlotOptionsChanged(true));
        dispatch(workspaceSlice.actions.checkItem({ props, value: e }))
    }


    const handleCheck = (e: { value: boolean }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: 
        if (timeoutId.current) clearTimeout(timeoutId.current)
        timeoutId.current = setTimeout(() => {
            checkItem(e.value)
        }, 600)
    }
        
    return (
        <div className={props.item.noData ? 'checkboxBlock noData' : 'checkboxBlock'}>
            <span className={props.item.type}>{props.item.name}</span>
            <Checkbox onChange={handleCheck} checked={props.item.noData ? false : props.item.checked} />
        </div>
    )
}